// import firestore from '@react-native-firebase/firestore';
// import storage from '@react-native-firebase/storage';
// import auth from '@react-native-firebase/auth';
import firebase from 'firebase';
import { Collections } from '../constant/firebase';
import { UserStore } from '../store/userStore';
import { CommonStore } from '../store/commonStore';
import * as User from './User';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import EncryptedStorage from 'react-native-encrypted-storage';
import * as geofire from 'geofire-common';
import moment from 'moment';
import { MERCHANT_VOUCHER_STATUS, VERIFALIA_STATUS } from '../constant/common';
// import firebase.messaging() from '@react-native-firebase/firebase.messaging()';

var routeFrom = null;
var navFrom = null;

export function getRouteFrom() {
    return routeFrom;
};

export function setRouteFrom(param) {
    routeFrom = param;
};

export function getNavFrom() {
    return navFrom;
};

export function setNavFrom(param) {
    navFrom = param;
};

export const validateEmail = async (verifalia, email) => {
    if (verifalia) {
        try {
            const validation = await verifalia
                .emailValidations
                .submit(email, true);

            console.log(validation.entries);

            return validation.entries[0].classification;
        }
        catch (ex) {
            return VERIFALIA_STATUS.API_NOT_AVAILABLE;
        }
    }
};

export const calculateDeliveryFees = (outlet, lat, lng) => {
    var radlat1 = (Math.PI * outlet.lat) / 180;
    var radlat2 = (Math.PI * lat) / 180;
    var theta = outlet.lng - lng;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
        dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;

    if (dist) {
        dist = dist * 1.609344;
        dist = dist.toFixed(1);
    }

    var deliveryFees = dist * 2;

    /////////////////////////
    // lalamove adjustment

    deliveryFees = deliveryFees * 2 / 3;

    /////////////////////////

    return deliveryFees.toFixed(2);
};

export const listenToUserChanges = async (firebaseUid) => {
    // const firebaseUid = await AsyncStorage.getItem('firebaseUid');
    // const merchantId = await AsyncStorage.getItem('merchantId');
    // const role = await AsyncStorage.getItem('role');

    // console.log(firebaseUid);
    // console.log(merchantId);
    // console.log(role);

    // Get user details
    firebase.firestore()
        .collection(Collections.User)
        .where('firebaseUid', '==', firebaseUid)
        .onSnapshot(snapshot => {
            console.log(`${Collections.User} changed!`);

            if (!snapshot.empty) {
                const record = snapshot.docs[0].data();

                UserStore.update(s => {
                    s.avatar = record.avatar;
                    s.dob = record.dob;
                    s.email = record.email;
                    s.gender = record.gender;
                    s.name = record.name;
                    s.number = record.number;
                    s.outletId = record.outledId;
                    s.race = record.race;
                    s.state = record.state;
                    s.uniqueName = record.uniqueName;
                    s.updatedAt = record.updatedAt;

                    s.userGroups = record.userGroups || [];
                });
            }
        });

    // EncryptedStorage.removeItem('paymentsDict');

    // causing bugs in ios, disabled first
    // var paymentsDictRaw = await EncryptedStorage.getItem('paymentsDict');
    // var paymentsDict = {};
    // var tempDefaultUserPaymentPointer = null;

    // if (paymentsDictRaw !== null) {
    //     paymentsDict = JSON.parse(paymentsDictRaw);

    //     var defaultUserPaymentPointer = await EncryptedStorage.getItem('defaultUserPaymentPointer');

    //     if (defaultUserPaymentPointer === null) {
    //         // skip
    //     }
    //     else {
    //         if (paymentsDict[defaultUserPaymentPointer]) {
    //             tempDefaultUserPaymentPointer = defaultUserPaymentPointer;
    //         }
    //     }
    // }

    // const tempPaymentPointers = Object.entries(paymentsDict).map(([key, value]) => key);

    // UserStore.update(s => {
    //     s.userPaymentPointers = tempPaymentPointers;
    //     s.selectedUserPaymentPointer = tempDefaultUserPaymentPointer;
    // });

    firebase.firestore()
        .collection(Collections.UserAddress)
        .where('userId', '==', firebaseUid)
        .onSnapshot(async snapshot => {
            console.log(`${Collections.UserAddress} changed!`);

            if (!snapshot.empty) {
                var tempUserAddresses = [];
                var tempSelectedUserAddress = null;

                var defaultUserAddressId = await AsyncStorage.getItem('defaultUserAddressId');

                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    tempUserAddresses.push(record);

                    if (defaultUserAddressId !== null && defaultUserAddressId === record.uniqueId) {
                        tempSelectedUserAddress = record;
                    }
                }
                if (defaultUserAddressId === null) {
                    tempSelectedUserAddress = tempUserAddresses[0];
                }

                UserStore.update(s => {
                    s.userAddresses = tempUserAddresses;
                    s.selectedUserAddress = tempSelectedUserAddress;
                });
            }
        });



    firebase.firestore()
        .collection(Collections.UserReservation)
        .where('userId', '==', firebaseUid)
        .onSnapshot(snapshot => {
            console.log(`${Collections.UserReservation} changed!`);

            var tempUserReservations = [];

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    tempUserReservations.push(record);
                }
            }

            CommonStore.update(s => {
                s.userReservations = tempUserReservations;
            });
        });

    firebase.firestore()
        .collection(Collections.UserQueue)
        .where('userId', '==', firebaseUid)
        .onSnapshot(snapshot => {
            console.log(`${Collections.UserQueue} changed!`);

            var tempUserQueues = [];

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    tempUserQueues.push(record);
                }
            }

            CommonStore.update(s => {
                s.userQueues = tempUserQueues;
            });
        });

    firebase.firestore()
        .collection(Collections.UserRing)
        .where('userId', '==', firebaseUid)
        .onSnapshot(snapshot => {
            console.log(`${Collections.UserRing} changed!`);

            if (!snapshot.empty) {
                var tempUserRings = [];

                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    tempUserRings.push(record);
                }

                CommonStore.update(s => {
                    s.userRings = tempUserRings;
                });
            }
        });

    firebase.firestore()
        .collection(Collections.UserOrder)
        .where('userId', '==', firebaseUid)
        // .orderBy('createdAt', 'desc')
        // .limit(30)
        .onSnapshot(async snapshot => {
            console.log(`${Collections.UserOrder} changed!`);

            var tempUserOrders = [];

            // if (!snapshot.empty) {
            if (snapshot && !snapshot.empty) {

                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    // if (outletsDict[record.outletId] === undefined) {
                    //     const outletSnapshot = await firebase.firestore().collection(Collections.Outlet)
                    //         .where('uniqueId', '==', record.outletId)
                    //         .limit(1)
                    //         .get();

                    //     if (!outletSnapshot.empty) {
                    //         const outlet = outletSnapshot.docs[0].data();

                    //         outletsDict[outlet.uniqueId] = outlet;

                    //         if (merchantsDict[outlet.merchantId] === undefined) {
                    //             const merchantSnapshot = await firebase.firestore().collection(Collections.Merchant)
                    //                 .where('uniqueId', '==', outlet.merchantId)
                    //                 .limit(1)
                    //                 .get();

                    //             if (!merchantSnapshot.empty) {
                    //                 const merchant = merchantSnapshot.docs[0].data();

                    //                 merchantsDict[merchant.uniqueId] = merchant;
                    //             }
                    //         }
                    //     }
                    // }

                    tempUserOrders.push(record);
                }

                tempUserOrders.sort((a, b) => moment(b.createdAt) - moment(a.createdAt))
            }

            CommonStore.update(s => {
                s.userOrders = tempUserOrders;
            });
        });

    // not listen to userVoucherRedemption, to save firestore reads
    firebase.firestore()
        .collection(Collections.MerchantVoucher)
        // .where('startAt', '<=', moment().valueOf())
        // .where('endAt', '>', moment().valueOf())
        .where('status', '==', MERCHANT_VOUCHER_STATUS.ACTIVE)
        .onSnapshot(async snapshot => {
            console.log(`${Collections.MerchantVoucher} changed!`);

            if (!snapshot.empty) {
                var merchantVouchersDict = [];
                var voucherIdValidList = [];

                const userVoucherRedemptionSnapshot = await firebase.firestore().collection(Collections.UserVoucherRedemption)
                    .where('userId', '==', firebaseUid)
                    .limit(1)
                    .get();

                if (!userVoucherRedemptionSnapshot.empty) {
                    const userVoucherRedemption = userVoucherRedemptionSnapshot.docs[0].data();

                    var voucherIdRedemptionList = userVoucherRedemption.redemptions;

                    for (var i = 0; i < snapshot.size; i++) {
                        const record = snapshot.docs[i].data();

                        console.log('moment(record.startDate).isSameOrBefore(moment())');
                        console.log(moment(record.startDate).isSameOrBefore(moment()));
                        console.log('moment(record.endDate).isAfter(moment())');
                        console.log(moment(record.endDate).isAfter(moment()));

                        if (voucherIdRedemptionList.includes(record.uniqueId) ||
                            moment(record.startDate).isAfter(moment()) ||
                            moment(record.endDate).isSameOrBefore(moment())) {
                            // means redeemed by user before, skip for now
                        }
                        else {
                            voucherIdValidList.push(record.uniqueId);

                            merchantVouchersDict[record.uniqueId] = record;
                        }
                    }

                    console.log('voucherIdValidList');
                    console.log(voucherIdValidList);
                    console.log('voucherIdRedemptionList');
                    console.log(voucherIdRedemptionList);
                    console.log('merchantVouchersDict');
                    console.log(merchantVouchersDict);

                    UserStore.update(s => {
                        s.voucherIdValidList = voucherIdValidList;
                        s.voucherIdRedemptionList = voucherIdRedemptionList;
                    });

                    CommonStore.update(s => {
                        s.merchantVouchersDict = merchantVouchersDict;
                    });
                }
            }
        });

    firebase.firestore()
        .collection(Collections.UserFavoriteOutlet)
        // .where('startAt', '<=', moment().valueOf())
        // .where('endAt', '>', moment().valueOf())
        .where('userId', '==', firebaseUid)
        .limit(1)
        .onSnapshot(async snapshot => {
            console.log(`${Collections.UserFavoriteOutlet} changed!`);

            var userFavoriteOutletDict = {};

            if (!snapshot.empty) {
                const record = snapshot.docs[0].data();

                userFavoriteOutletDict = record.outletDict;
            }

            CommonStore.update(s => {
                s.userFavoriteOutletDict = userFavoriteOutletDict;
            });
        });

    firebase.firestore()
        .collection(Collections.UserLoyalty)
        // .where('startAt', '<=', moment().valueOf())
        // .where('endAt', '>', moment().valueOf())
        .where('userId', '==', firebaseUid)
        .limit(1)
        .onSnapshot(async snapshot => {
            console.log(`${Collections.UserLoyalty} changed!`);

            var userLoyalty = {
                balance: 0,
            };

            if (!snapshot.empty) {
                const record = snapshot.docs[0].data();

                userLoyalty = record;
            }

            CommonStore.update(s => {
                s.userLoyalty = userLoyalty;
            });
        });

    firebase.firestore()
        .collection(Collections.BeerDocket)
        // .where('merchantId', '==', merchantId)
        .onSnapshot(snapshot => {
            console.log(`${Collections.BeerDocket} changed!`);

            var beerDockets = [];

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    beerDockets.push(record);
                }
            }

            CommonStore.update(s => {
                s.beerDockets = beerDockets;
            });
        });

    firebase.firestore()
        .collection(Collections.UserBeerDocket)
        .where('userId', '==', firebaseUid)
        .onSnapshot(snapshot => {
            console.log(`${Collections.UserBeerDocket} changed!`);

            var beerDocketsRedemptions = [];
            var beerDocketsRedemptionsBDDict = {};

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    beerDocketsRedemptions.push(record);
                    beerDocketsRedemptionsBDDict[record.beerDocketId] = record;
                }
            }

            CommonStore.update(s => {
                s.beerDocketsRedemptions = beerDocketsRedemptions;
                s.beerDocketsRedemptionsBDDict = beerDocketsRedemptionsBDDict;
            });
        });

    firebase.firestore()
        .collection(Collections.UserPointsTransaction)
        .where('userId', '==', firebaseUid)
        .onSnapshot(snapshot => {
            console.log(`${Collections.UserPointsTransaction} changed!`);

            var userPointsTransactions = [];
            var userPointsBalance = 0;

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    userPointsTransactions.push(record);

                    userPointsBalance += record.amount;
                }
            }

            UserStore.update(s => {
                s.userPointsTransactions = userPointsTransactions;
                s.userPointsBalance = userPointsBalance;
            });
        });
};

export const listenToLocationChanges = async (latParam, lngParam) => {
    // Get outlets

    // Find cities within 5km
    const center = [latParam, lngParam];
    const radiusInM = 100 * 1000; // 100km for testing

    // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
    // a separate query for each pair. There can be up to 9 pairs of bounds
    // depending on overlap, but in most cases there are 4.
    const bounds = geofire.geohashQueryBounds(center, radiusInM);
    const promises = [];
    for (const b of bounds) {
        const q = firebase.firestore().collection(Collections.Outlet)
            .orderBy('geoHash')
            .startAt(b[0])
            .endAt(b[1]);

        promises.push(q.get());
    }

    // Collect all the query results together into a single list
    Promise.all(promises).then((snapshots) => {
        const matchingDocs = [];

        for (const snap of snapshots) {
            for (const doc of snap.docs) {
                const lat = doc.get('lat');
                const lng = doc.get('lng');

                // We have to filter out a few false positives due to GeoHash
                // accuracy, but most will match
                const distanceinKM = geofire.distanceBetween([lat, lng], center);
                const distanceInM = distanceinKM * 1000;
                if (distanceInM <= radiusInM) {
                    matchingDocs.push({
                        ...doc.data(),
                        // deliveryFees: calculateDeliveryFees(doc.data()),
                        distanceInKM: distanceinKM.toFixed(2),
                    });
                }
            }
        }

        return matchingDocs;
    }).then(async (outlets) => {
        var nearbyOutlets = [];
        var outletsOpeningDict = {};
        var merchantsDict = {};
        var outletsMinMaxDict = {};

        CommonStore.update(s => {
            outletsOpeningDict = {
                ...s.outletsOpeningDict,
            };

            merchantsDict = {
                ...s.merchantsDict,
            };

            outletsMinMaxDict = {
                ...s.outletsMinMaxDict,
            };
        });

        for (var i = 0; i < outlets.length; i++) {
            const outlet = outlets[i];

            nearbyOutlets.push(outlet);

            if (outletsOpeningDict[outlet.uniqueId] === undefined) {
                const outletOpeningSnapshot = await firebase.firestore().collection(Collections.OutletOpening)
                    .where('outletId', '==', outlet.uniqueId)
                    .limit(1)
                    .get();

                if (!outletOpeningSnapshot.empty) {
                    const outletOpening = outletOpeningSnapshot.docs[0].data();

                    outletsOpeningDict[outletOpening.outletId] = outletOpening;
                }
            }

            if (merchantsDict[outlet.merchantId] === undefined) {
                const merchantSnapshot = await firebase.firestore().collection(Collections.Merchant)
                    .where('uniqueId', '==', outlet.merchantId)
                    .limit(1)
                    .get();

                if (!merchantSnapshot.empty) {
                    const merchant = merchantSnapshot.docs[0].data();

                    merchantsDict[merchant.uniqueId] = merchant;
                }
            }

            if (outletsMinMaxDict[outlet.uniqueId] === undefined) {
                outletsMinMaxDict[outlet.uniqueId] = {
                    min: null,
                    max: null,
                };

                const outletMinSnapshot = await firebase.firestore().collection(Collections.OutletItem)
                    .where('outletId', '==', outlet.uniqueId)
                    .orderBy('price', 'asc')
                    .limit(1)
                    .get();

                const outletMaxSnapshot = await firebase.firestore().collection(Collections.OutletItem)
                    .where('outletId', '==', outlet.uniqueId)
                    .orderBy('price', 'desc')
                    .limit(1)
                    .get();

                if (!outletMinSnapshot.empty) {
                    const outletMin = outletMinSnapshot.docs[0].data();

                    outletsMinMaxDict[outlet.uniqueId].min = outletMin;
                }

                if (!outletMaxSnapshot.empty) {
                    const outletMax = outletMaxSnapshot.docs[0].data();

                    outletsMinMaxDict[outlet.uniqueId].max = outletMax;
                }
            }
        }

        CommonStore.update(s => {
            s.nearbyOutlets = nearbyOutlets;

            s.outletsOpeningDict = {
                ...s.outletsOpeningDict,
                ...outletsOpeningDict,
            };

            s.searchOutlets = nearbyOutlets;

            s.merchantsDict = {
                ...s.merchantsDict,
                ...merchantsDict,
            };

            s.outletsMinMaxDict = {
                ...s.outletsMinMaxDict,
                ...outletsMinMaxDict,
            };
        });
    });
};

export const listenToSelectedOutletChanges = async (outlet, email) => {
    // experimental: change to snapshot based

    var selectedOutletItems = [];
    var selectedOutletItemCategories = [];
    var selectedOutletItemCategory = {};

    var selectedOutletPromotions = [];

    CommonStore.update(s => {
        s.isSwitchingOutlets = true;
    });

    var totalOperations = 4;

    firebase.firestore()
        .collection(Collections.OutletItem)
        .where('outletId', '==', outlet.uniqueId)
        .onSnapshot(snapshot => {
            console.log(`${Collections.OutletItem} changed!`);

            var selectedOutletItems = [];
            var selectedOutletItemsSkuDict = {};

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    selectedOutletItems.push(record);

                    selectedOutletItemsSkuDict[record.sku] = record;
                }
            }

            CommonStore.update(s => {
                s.selectedOutletItems = selectedOutletItems;
                s.selectedOutletItemsSkuDict = selectedOutletItemsSkuDict;
            });

            totalOperations--;
            if (totalOperations <= 0) {
                CommonStore.update(s => {
                    s.isSwitchingOutlets = false;
                });
            }
        });

    firebase.firestore()
        .collection(Collections.OutletItemCategory)
        .where('outletId', '==', outlet.uniqueId)
        .onSnapshot(snapshot => {
            console.log(`${Collections.OutletItemCategory} changed!`);

            var selectedOutletItemCategories = [];
            var selectedOutletItemCategory = {};

            var selectedOutletItemCategoriesDict = {};

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    selectedOutletItemCategories.push(record);

                    selectedOutletItemCategoriesDict[record.uniqueId] = record;
                }

                selectedOutletItemCategory = selectedOutletItemCategories[0];
            }

            CommonStore.update(s => {
                s.selectedOutletItemCategories = selectedOutletItemCategories;
                s.selectedOutletItemCategory = selectedOutletItemCategory;

                s.selectedOutletItemCategoriesDict = selectedOutletItemCategoriesDict;
            });

            totalOperations--;
            if (totalOperations <= 0) {
                CommonStore.update(s => {
                    s.isSwitchingOutlets = false;
                });
            }
        });

    firebase.firestore()
        .collection(Collections.Promotion)
        .where('outletIdList', 'array-contains', outlet.uniqueId)
        .where('isActive', '==', true)
        .where('deletedAt', '==', null)
        .onSnapshot(snapshot => {
            console.log(`${Collections.Promotion} changed!`);

            var selectedOutletPromotions = [];

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    selectedOutletPromotions.push(record);
                }
            }

            CommonStore.update(s => {
                s.selectedOutletPromotions = selectedOutletPromotions;
            });

            totalOperations--;
            if (totalOperations <= 0) {
                CommonStore.update(s => {
                    s.isSwitchingOutlets = false;
                });
            }
        });

    // firebase.firestore()
    //     .collection(Collections.PreorderPackage)
    //     .where('outletIdList', 'array-contains', outlet.uniqueId)
    //     // .where('isActive', '==', true)
    //     .where('deletedAt', '==', null)
    //     .onSnapshot(snapshot => {
    //         console.log(`${Collections.PreorderPackage} changed!`);

    //         var selectedOutletPreorderPackages = [];

    //         if (!snapshot.empty) {
    //             for (var i = 0; i < snapshot.size; i++) {
    //                 const record = snapshot.docs[i].data();

    //                 selectedOutletPreorderPackages.push(record);
    //             }
    //         }

    //         CommonStore.update(s => {
    //             s.selectedOutletPreorderPackages = selectedOutletPreorderPackages;
    //         });

    //         totalOperations--;
    //         if (totalOperations <= 0) {
    //             CommonStore.update(s => {
    //                 s.isSwitchingOutlets = false;
    //             });
    //         }
    //     });

    firebase.firestore()
        .collection(Collections.PointsRedeemPackage)
        .where('outletIdList', 'array-contains', outlet.uniqueId)
        // .where('isActive', '==', true)
        .where('deletedAt', '==', null)
        .onSnapshot(snapshot => {
            console.log(`${Collections.PointsRedeemPackage} changed!`);

            var selectedOutletPointsRedeemPackages = [];

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    selectedOutletPointsRedeemPackages.push(record);
                }
            }

            CommonStore.update(s => {
                s.selectedOutletPointsRedeemPackages = selectedOutletPointsRedeemPackages;
            });

            totalOperations--;
            if (totalOperations <= 0) {
                CommonStore.update(s => {
                    s.isSwitchingOutlets = false;
                });
            }
        });

    // firebase.firestore()
    //     .collection(Collections.CRMUserTag)
    //     .where('merchantId', '==', outlet.merchantId)
    //     // .where('isActive', '==', true)
    //     .where('deletedAt', '==', null)
    //     .onSnapshot(snapshot => {
    //         console.log(`${Collections.CRMUserTag} changed!`);

    //         var selectedOutletCRMTagsDict = {};

    //         if (!snapshot.empty) {
    //             for (var i = 0; i < snapshot.size; i++) {
    //                 const record = snapshot.docs[i].data();

    //                 selectedOutletCRMTagsDict[record.uniqueId] = record;
    //             }
    //         }

    //         CommonStore.update(s => {
    //             s.selectedOutletCRMTagsDict = selectedOutletCRMTagsDict;
    //         });

    //         totalOperations--;
    //         if (totalOperations <= 0) {
    //             CommonStore.update(s => {
    //                 s.isSwitchingOutlets = false;
    //             });
    //         }
    //     });

    // firebase.firestore()
    //     .collection(Collections.CRMUser)
    //     .where('merchantId', '==', outlet.merchantId)
    //     .where('email', '==', email)
    //     .where('deletedAt', '==', null)
    //     .limit(1)
    //     .onSnapshot(snapshot => {
    //         console.log(`${Collections.CRMUser} changed!`);

    //         var selectedOutletCRMUser = {};

    //         if (!snapshot.empty) {
    //             selectedOutletCRMUser = snapshot.docs[0].data();
    //         }

    //         CommonStore.update(s => {
    //             s.selectedOutletCRMUser = selectedOutletCRMUser;
    //         });

    //         totalOperations--;
    //         if (totalOperations <= 0) {
    //             CommonStore.update(s => {
    //                 s.isSwitchingOutlets = false;
    //             });
    //         }
    //     });

    // firebase.firestore()
    //     .collection(Collections.OutletReviewSummary)
    //     .where('merchantId', '==', outlet.merchantId)
    //     .where('outletId', '==', outlet.uniqueId)
    //     .where('deletedAt', '==', null)
    //     .limit(1)
    //     .onSnapshot(snapshot => {
    //         console.log(`${Collections.OutletReviewSummary} changed!`);

    //         var selectedOutletReviewSummary = {};

    //         if (!snapshot.empty) {
    //             selectedOutletReviewSummary = snapshot.docs[0].data();
    //         }
    //         else {
    //             selectedOutletReviewSummary = {
    //                 ratingsList: [],
    //                 ratingsCount: 0,
    //                 ratingsAverage: 0,
    //                 commentsCount: 0,
    //             };
    //         }

    //         CommonStore.update(s => {
    //             s.selectedOutletReviewSummary = selectedOutletReviewSummary;
    //         });

    //         totalOperations--;
    //         if (totalOperations <= 0) {
    //             CommonStore.update(s => {
    //                 s.isSwitchingOutlets = false;
    //             });
    //         }
    //     });
};

export const listenToSelectedOutletItemChanges = async (item) => {
    var outletsItemAddOnDict = {};
    var outletsItemAddOnChoiceDict = {};

    CommonStore.update(s => {
        // outletsItemAddOnDict = {
        //     ...s.outletsItemAddOnDict,
        // };

        // outletsItemAddOnChoiceDict = {
        //     ...s.outletsItemAddOnChoiceDict,
        // };

        s.isLoading = true;
    });

    if (outletsItemAddOnDict[item.uniqueId] === undefined) {
        console.log('item');
        console.log(item);

        const outletItemAddOnSnapshot = await firebase.firestore().collection(Collections.OutletItemAddOn)
            .where('outletItemId', '==', item.uniqueId)
            .get();

        if (!outletItemAddOnSnapshot.empty) {
            var tempOutletItemAddOnList = [];

            for (var i = 0; i < outletItemAddOnSnapshot.size; i++) {
                tempOutletItemAddOnList.push(outletItemAddOnSnapshot.docs[i].data());

                outletsItemAddOnDict[item.uniqueId] = tempOutletItemAddOnList;
            }
        }
    }

    if (outletsItemAddOnDict[item.uniqueId] !== undefined) {
        const tempOutletItemAddOnList = outletsItemAddOnDict[item.uniqueId];

        for (var i = 0; i < tempOutletItemAddOnList.length; i++) {
            if (outletsItemAddOnChoiceDict[tempOutletItemAddOnList[i].uniqueId] === undefined) {
                console.log('tempOutletItemAddOnList[i].uniqueId');
                console.log(tempOutletItemAddOnList[i].uniqueId);

                const outletItemAddOnChoiceSnapshot = await firebase.firestore().collection(Collections.OutletItemAddOnChoice)
                    .where('outletItemAddOnId', '==', tempOutletItemAddOnList[i].uniqueId)
                    .get();

                if (!outletItemAddOnChoiceSnapshot.empty)
                    var tempOutletItemAddOnChoiceList = [];

                for (var j = 0; j < outletItemAddOnChoiceSnapshot.size; j++) {
                    tempOutletItemAddOnChoiceList.push(outletItemAddOnChoiceSnapshot.docs[j].data());

                    outletsItemAddOnChoiceDict[tempOutletItemAddOnList[i].uniqueId] = tempOutletItemAddOnChoiceList;
                }
            }
        }
    }

    CommonStore.update(s => {
        // s.outletsItemAddOnDict = {
        //     ...s.outletsItemAddOnDict,
        //     ...outletsItemAddOnDict,
        // };

        // s.outletsItemAddOnChoiceDict = {
        //     ...s.outletsItemAddOnChoiceDict,
        //     ...outletsItemAddOnChoiceDict,
        // };

        s.outletsItemAddOnDict = outletsItemAddOnDict;
        s.outletsItemAddOnChoiceDict = outletsItemAddOnChoiceDict;

        s.isLoading = false;
    });
};

export const listenToCommonChanges = async () => {
    firebase.firestore()
        .collection(Collections.Tag)
        .onSnapshot(snapshot => {
            console.log(`${Collections.Tag} changed!`);

            if (snapshot && !snapshot.empty) {
                var tempTags = [];
                var tempSelectedOutletTag = null;

                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    tempTags.push(record);
                }

                tempSelectedOutletTag = tempTags[0];

                CommonStore.update(s => {
                    s.tags = tempTags;
                    s.selectedOutletTag = tempSelectedOutletTag;
                });
            }
        });
};

export const listenToSelectedOutletTagChanges = async (tag) => {
    firebase.firestore()
        .collection(Collections.OutletTag)
        .where('tagId', '==', tag.uniqueId)
        .onSnapshot(async snapshot => {
            console.log(`${Collections.OutletTag} changed!`);

            var tempTagOutlets = [];
            var outletsOpeningDict = {};
            var merchantsDict = {};
            var outletsMinMaxDict = {};

            // todo: disabled first, need check on this error: `Cannot perform 'get' on a proxy that has been revoked`
            // CommonStore.update(s => {
            //     outletsOpeningDict = {
            //         ...s.outletsOpeningDict,
            //     };

            //     merchantsDict = {
            //         ...s.merchantsDict,
            //     };

            //     outletsMinMaxDict = {
            //         ...s.outletsMinMaxDict,
            //     };
            // });

            if (!snapshot.empty) {
                var tempOutletTags = [];
                var outlets = [];

                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    tempOutletTags.push(record);

                    const outletSnapshot = await firebase.firestore().collection(Collections.Outlet)
                        .where('uniqueId', '==', record.outletId)
                        .limit(1)
                        .get();

                    if (!outletSnapshot.empty) {
                        const outlet = outletSnapshot.docs[0].data();

                        outlets.push(outlet);
                    }
                }

                CommonStore.update(s => {
                    s.tagToOutletTagDict[tag.uniqueId] = tempOutletTags;
                });

                ////////////////////////////////////////////////

                // var tagOutlets = [];
                // var outletsOpeningDict = {};
                // var merchantsDict = {};
                // var outletsMinMaxDict = {};

                // CommonStore.update(s => {
                //     outletsOpeningDict = {
                //         ...s.outletsOpeningDict,
                //     };

                //     merchantsDict = {
                //         ...s.merchantsDict,
                //     };

                //     outletsMinMaxDict = {
                //         ...s.outletsMinMaxDict,
                //     };
                // });

                for (var i = 0; i < outlets.length; i++) {
                    const outlet = outlets[i];

                    tempTagOutlets.push(outlet);

                    if (outletsOpeningDict[outlet.uniqueId] === undefined) {
                        const outletOpeningSnapshot = await firebase.firestore().collection(Collections.OutletOpening)
                            .where('outletId', '==', outlet.uniqueId)
                            .limit(1)
                            .get();

                        if (!outletOpeningSnapshot.empty) {
                            const outletOpening = outletOpeningSnapshot.docs[0].data();

                            outletsOpeningDict[outletOpening.outletId] = outletOpening;
                        }
                    }

                    if (merchantsDict[outlet.merchantId] === undefined) {
                        const merchantSnapshot = await firebase.firestore().collection(Collections.Merchant)
                            .where('uniqueId', '==', outlet.merchantId)
                            .limit(1)
                            .get();

                        if (!merchantSnapshot.empty) {
                            const merchant = merchantSnapshot.docs[0].data();

                            merchantsDict[merchant.uniqueId] = merchant;
                        }
                    }

                    if (outletsMinMaxDict[outlet.uniqueId] === undefined) {
                        outletsMinMaxDict[outlet.uniqueId] = {
                            min: null,
                            max: null,
                        };

                        const outletMinSnapshot = await firebase.firestore().collection(Collections.OutletItem)
                            .where('outletId', '==', outlet.uniqueId)
                            .orderBy('price', 'asc')
                            .limit(1)
                            .get();

                        const outletMaxSnapshot = await firebase.firestore().collection(Collections.OutletItem)
                            .where('outletId', '==', outlet.uniqueId)
                            .orderBy('price', 'desc')
                            .limit(1)
                            .get();

                        if (!outletMinSnapshot.empty) {
                            const outletMin = outletMinSnapshot.docs[0].data();

                            outletsMinMaxDict[outlet.uniqueId].min = outletMin;
                        }

                        if (!outletMaxSnapshot.empty) {
                            const outletMax = outletMaxSnapshot.docs[0].data();

                            outletsMinMaxDict[outlet.uniqueId].max = outletMax;
                        }
                    }
                }
            }

            CommonStore.update(s => {
                s.tagOutlets = [
                    ...tempTagOutlets,
                ];

                s.outletsOpeningDict = {
                    ...s.outletsOpeningDict,
                    ...outletsOpeningDict,
                };

                // s.searchOutlets = tagOutlets;

                s.merchantsDict = {
                    ...s.merchantsDict,
                    ...merchantsDict,
                };

                s.outletsMinMaxDict = {
                    ...s.outletsMinMaxDict,
                    ...outletsMinMaxDict,
                };
            });
        });
};

export const listenToSearchOutletMerchantIdChanges = async (searchOutletMerchantId) => {
    // Get outlets

    firebase.firestore()
        .collection(Collections.Outlet)
        // .orderBy('name')
        // .startAt([searchOutletText])
        // .endAt([searchOutletText + '\uf8ff'])
        // .where('name', '>=', searchOutletText)
        // .where('name', '<', searchOutletText + 'z')
        .where('merchantId', '==', searchOutletMerchantId)
        .onSnapshot(async snapshot => {
            console.log(`${Collections.Outlet} changed!`);
            console.log(snapshot);

            if (!snapshot.empty) {
                var outlets = [];

                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    outlets.push(record);
                }

                var searchOutlets = [];
                var outletsOpeningDict = {};
                var merchantsDict = {};
                var outletsMinMaxDict = {};

                // CommonStore.update(s => {
                //     outletsOpeningDict = {
                //         ...s.outletsOpeningDict,
                //     };

                //     merchantsDict = {
                //         ...s.merchantsDict,
                //     };

                //     outletsMinMaxDict = {
                //         ...s.outletsMinMaxDict,
                //     };
                // });

                for (var i = 0; i < outlets.length; i++) {
                    const outlet = outlets[i];

                    searchOutlets.push(outlet);

                    if (outletsOpeningDict[outlet.uniqueId] === undefined) {
                        const outletOpeningSnapshot = await firebase.firestore().collection(Collections.OutletOpening)
                            .where('outletId', '==', outlet.uniqueId)
                            .limit(1)
                            .get();

                        if (!outletOpeningSnapshot.empty) {
                            const outletOpening = outletOpeningSnapshot.docs[0].data();

                            outletsOpeningDict[outletOpening.outletId] = outletOpening;
                        }
                    }

                    if (merchantsDict[outlet.merchantId] === undefined) {
                        const merchantSnapshot = await firebase.firestore().collection(Collections.Merchant)
                            .where('uniqueId', '==', outlet.merchantId)
                            .limit(1)
                            .get();

                        if (!merchantSnapshot.empty) {
                            const merchant = merchantSnapshot.docs[0].data();

                            merchantsDict[merchant.uniqueId] = merchant;
                        }
                    }

                    if (outletsMinMaxDict[outlet.uniqueId] === undefined) {
                        outletsMinMaxDict[outlet.uniqueId] = {
                            min: null,
                            max: null,
                        };

                        const outletMinSnapshot = await firebase.firestore().collection(Collections.OutletItem)
                            .where('outletId', '==', outlet.uniqueId)
                            .orderBy('price', 'asc')
                            .limit(1)
                            .get();

                        const outletMaxSnapshot = await firebase.firestore().collection(Collections.OutletItem)
                            .where('outletId', '==', outlet.uniqueId)
                            .orderBy('price', 'desc')
                            .limit(1)
                            .get();

                        if (!outletMinSnapshot.empty) {
                            const outletMin = outletMinSnapshot.docs[0].data();

                            outletsMinMaxDict[outlet.uniqueId].min = outletMin;
                        }

                        if (!outletMaxSnapshot.empty) {
                            const outletMax = outletMaxSnapshot.docs[0].data();

                            outletsMinMaxDict[outlet.uniqueId].max = outletMax;
                        }
                    }
                }

                console.log('searchOutlets');
                console.log(searchOutlets);

                CommonStore.update(s => {
                    s.searchOutlets = searchOutlets;

                    s.outletsOpeningDict = {
                        ...s.outletsOpeningDict,
                        ...outletsOpeningDict,
                    };

                    s.merchantsDict = {
                        ...s.merchantsDict,
                        ...merchantsDict,
                    };

                    s.outletsMinMaxDict = {
                        ...s.outletsMinMaxDict,
                        ...outletsMinMaxDict,
                    };

                    s.isLoadingSearchOutlet = false;
                });
            }
        });
};

export const listenToSearchOutletTextChanges = async (searchOutletText) => {
    // Get outlets

    firebase.firestore()
        .collection(Collections.Outlet)
        // .orderBy('name')
        // .startAt([searchOutletText])
        // .endAt([searchOutletText + '\uf8ff'])
        .where('name', '>=', searchOutletText)
        .where('name', '<', searchOutletText + 'z')
        .onSnapshot(async snapshot => {
            console.log(`${Collections.Outlet} changed!`);

            if (!snapshot.empty) {
                var outlets = [];

                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    outlets.push(record);
                }

                var searchOutlets = [];
                var outletsOpeningDict = {};
                var merchantsDict = {};
                var outletsMinMaxDict = {};

                // CommonStore.update(s => {
                //     outletsOpeningDict = {
                //         ...s.outletsOpeningDict,
                //     };

                //     merchantsDict = {
                //         ...s.merchantsDict,
                //     };

                //     outletsMinMaxDict = {
                //         ...s.outletsMinMaxDict,
                //     };
                // });

                for (var i = 0; i < outlets.length; i++) {
                    const outlet = outlets[i];

                    searchOutlets.push(outlet);

                    if (outletsOpeningDict[outlet.uniqueId] === undefined) {
                        const outletOpeningSnapshot = await firebase.firestore().collection(Collections.OutletOpening)
                            .where('outletId', '==', outlet.uniqueId)
                            .limit(1)
                            .get();

                        if (!outletOpeningSnapshot.empty) {
                            const outletOpening = outletOpeningSnapshot.docs[0].data();

                            outletsOpeningDict[outletOpening.outletId] = outletOpening;
                        }
                    }

                    if (merchantsDict[outlet.merchantId] === undefined) {
                        const merchantSnapshot = await firebase.firestore().collection(Collections.Merchant)
                            .where('uniqueId', '==', outlet.merchantId)
                            .limit(1)
                            .get();

                        if (!merchantSnapshot.empty) {
                            const merchant = merchantSnapshot.docs[0].data();

                            merchantsDict[merchant.uniqueId] = merchant;
                        }
                    }

                    if (outletsMinMaxDict[outlet.uniqueId] === undefined) {
                        outletsMinMaxDict[outlet.uniqueId] = {
                            min: null,
                            max: null,
                        };

                        const outletMinSnapshot = await firebase.firestore().collection(Collections.OutletItem)
                            .where('outletId', '==', outlet.uniqueId)
                            .orderBy('price', 'asc')
                            .limit(1)
                            .get();

                        const outletMaxSnapshot = await firebase.firestore().collection(Collections.OutletItem)
                            .where('outletId', '==', outlet.uniqueId)
                            .orderBy('price', 'desc')
                            .limit(1)
                            .get();

                        if (!outletMinSnapshot.empty) {
                            const outletMin = outletMinSnapshot.docs[0].data();

                            outletsMinMaxDict[outlet.uniqueId].min = outletMin;
                        }

                        if (!outletMaxSnapshot.empty) {
                            const outletMax = outletMaxSnapshot.docs[0].data();

                            outletsMinMaxDict[outlet.uniqueId].max = outletMax;
                        }
                    }
                }

                console.log('searchOutlets');
                console.log(searchOutlets);

                CommonStore.update(s => {
                    s.searchOutlets = searchOutlets;

                    s.outletsOpeningDict = {
                        ...s.outletsOpeningDict,
                        ...outletsOpeningDict,
                    };

                    s.merchantsDict = {
                        ...s.merchantsDict,
                        ...merchantsDict,
                    };

                    s.outletsMinMaxDict = {
                        ...s.outletsMinMaxDict,
                        ...outletsMinMaxDict,
                    };
                });
            }
        });
};

export const getImageFromFirebaseStorage = async (imageUrl, callback) => {
    try {
        const url = await firebase.storage()
            .ref(imageUrl)
            .getDownloadURL()
            .then(url => {
                console.log(url);

                // return url;
                callback && callback(url);
            });

        // const url = await storage()
        //     .ref(imageUrl)
        //     .getDownloadURL();

        // return url;
    }
    catch (ex) {
        console.error(ex);

        return '';
    }
};

export const listenToSelectedOutletTableIdChanges = async (userId, selectedOutletTableId, selectedOutletId) => {
    // Get outlets

    firebase.firestore()
        .collection(Collections.UserCart)
        .where('tableId', '==', selectedOutletTableId)
        .where('outletId', '==', selectedOutletId)
        .where('userId', '==', userId)
        .limit(1)
        .onSnapshot(async snapshot => {
            console.log(`${Collections.UserCart} changed!`);
            console.log(snapshot);

            var userCart = {};
            var cartItems = [];

            if (!snapshot.empty) {
                const record = snapshot.docs[0].data();

                userCart = record;

                cartItems = record.cartItems;
            }

            if (userCart && userCart.userId) {
                await AsyncStorage.setItem(`${userId}.cartItems`, JSON.stringify(cartItems));
                await AsyncStorage.setItem(`${userId}.cartOutletId`, selectedOutletId);
            }
            else {
                await AsyncStorage.removeItem(`${userId}.cartItems`);
                await AsyncStorage.removeItem(`${userId}.cartOutletId`);
            }

            CommonStore.update(s => {
                s.userCart = userCart;
                s.cartItems = cartItems;
            });
        });
};

// export const requestNotificationsPermission = async () => {
//     const authStatus = await firebase.messaging()().requestPermission();
//     const enabled =
//         authStatus === firebase.messaging().AuthorizationStatus.AUTHORIZED ||
//         authStatus === firebase.messaging().AuthorizationStatus.PROVISIONAL;

//     if (enabled) {
//         console.log('Authorization status:', authStatus);
//     }
// };

export const getOutletById = async (outletId) => {
    const outletSnapshot = await firebase.firestore()
        .collection(Collections.Outlet)
        .where('uniqueId', '==', outletId)
        .limit(1)
        .get();

    var outlet = null;
    if (!outletSnapshot.empty) {
        outlet = outletSnapshot.docs[0].data();
    }

    return outlet;
};

export const isMobile = () => {
    // credit to Timothy Huang for this regex test:
    // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
    }
    else {
        return false
    };
 };
