import { Store } from 'pullstate';

export const UserStore = new Store({
    avatar: '',
    dob: null,
    email: '',
    gender: '',
    name: '',
    number: '',
    outletId: '',    
    race: '',  
    state: '',
    uniqueName: '',
    updatedAt: null,

    // merchantId: '',
    role: '',
    refreshToken: '',
    firebaseUid: '',

    userAddresses: [],
    selectedUserAddress: null,

    userPaymentPointers: [],
    selectedUserPaymentPointer: null,

    voucherIdValidList: [],
    selectedVoucherId: null,

    voucherIdRedemptionList: [],

    ///////////////////////////

    userGroups: [],

    userPointsTransactions: [],
    userPointsBalance: 0,

    token: '',
    userId: '',

    userInfoName: '',
    userInfoPhone: '',
});   