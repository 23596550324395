import { Store } from 'pullstate';

export const CommonStore = new Store({
    debugText: 'Debug Text',

    isAuthenticating: true,
    isLoading: false,
    isPlacingOrder: false,

    alertObj: null,

    // lat: 3.1042,
    // lng: 101.6128,
    lat: 3.1320349, // number
    lng: 101.6136999, // number

    nearbyOutlets: [], // [ outlet, ... ]

    outletsOpeningDict: {}, // { outlet_id -> outlet_opening, ... }

    searchOutlets: [], // [ outlet, ... ]

    merchantsDict: {}, // { merchant_id -> merchant, ... }

    outletsMinMaxDict: {}, // { outlet_id -> { min: outlet_item, max: outlet_item }, ... }

    tagOutlets: [],

    outletsTaxDict: {},

    outletsDict: {},

    /////////////////////////////////////////////////
    // User selected outlet

    selectedOutletWaiterName: '',
    selectedOutletWaiterId: '',

    selectedOutletTableId: '',
    selectedOutletWaiterId: '',
    selectedOutletTablePax: 0,
    selectedOutletTableCode: '',
    userCart: {},

    // selectedOutletId: null,  // outlet
    selectedOutlet: null,  // outlet
    selectedOutletItems: [], // [ outlet_item, ... ]
    selectedOutletItemCategories: [], // [ outlet_item_category, ... ]
    selectedOutletPromotions: [],
    selectedOutletPreorderPackages: [],
    selectedOutletPointsRedeemPackages: [],

    selectedOutletCRMTagsDict: {},
    selectedOutletCRMUser: {},

    selectedOutletReviewSummary: {
        ratingsList: [],
        ratingsCount: 0,
        ratingsAverage: 0,
        commentsCount: 0,
    },

    selectedOutletItemsSkuDict: {},

    selectedOutletItemCategory: {}, // outlet_item_category
    selectedOutletItem: {}, // outlet_item

    selectedOutletItemCategoriesDict: {},

    outletsItemAddOnDict: {}, // { outlet_item_id -> [ outlet_item_addon, ... ], ... }
    outletsItemAddOnChoiceDict: {}, // { outlet_item_addon_id -> [ outlet_item_addon_choice, ... ], ... }

    selectedOutletItemAddOn: {}, // { outlet_item_addon_id -> { outlet_item_addon_choice_id = true/false, ... }, ... }
    selectedOutletItemAddOnChoice: {}, // { outlet_item_addon_choice_id -> true/false, ... }

    /////////////////////////////////////////////////
    // Cart    

    cartOutletId: null,

    onUpdatingCartItem: null,

    cartItems: [], // [ { itemId: outlet_item_id, choices: { outlet_item_addon_choice_id: true/false, ... } }, ... ]
    cartItemChoices: {}, // { outlet_item_id -> { outlet_item_addon_choice_id = true, ... }, ... }

    orderType: 'DELIVERY',

    cartOutletItemsDict: {},
    cartOutletItemAddOnDict: {},
    cartOutletItemAddOnChoiceDict: {},

    cartItemsProcessed: [],

    selectedAddressType: 'OTHER',

    /////////////////////////////////////////////////

    tags: [],
    selectedOutletTag: {},

    tagToOutletTagDict: {}, // { tag_id -> [ outlet_tag, ... ], ... }

    searchOutletText: '',
    isLoadingSearchOutlet: false,

    searchOutletMerchantId: '',

    /////////////////////////////////////////////////

    userReservations: [],
    userQueues: [],
    userRings: [],
    userOrders: [],

    selectedUserReservation: {},
    selectedUserQueue: {},
    selectedUserRing: {},
    selectedUserOrder: {},

    selectedPreorderPackage: {},
    selectedPreorderPackageCartItems: [],
    selectedPreorderPackageCartItemsProcessed: [],

    /////////////////////////////////////////////////

    merchantVouchersDict: {},
    selectedVoucher: {},

    // record scanned qr here
    scannedQrData: null,

    isSwitchingOutlets: false,

    selectedQROutletId: '',

    currQRScanningType: '',

    timestamp: 0,

    userFavoriteOutletDict: {},

    userLoyalty: {},

    beerDockets: [],
    beerDocketsRedemptions: [],
    beerDocketsRedemptionsBDDict: {},
    userBeerDockets: [],
    selectedUserBeerDocket: {},

    scannedQRBeerDocketResult: null,
    bdRedeemQuantity: 0,

    bdCartItems: [],

    currPage: '',

    availablePromotions: [],
    selectedOutletPromotion: {},

    overrideItemPriceSkuDict: {},
    amountOffItemSkuDict: {},
    percentageOffItemSkuDict: {},
    buy1Free1ItemSkuDict: {},
    overrideCategoryPriceNameDict: {},
    amountOffCategoryNameDict: {},
    percentageOffCategoryNameDict: {},
    buy1Free1CategoryNameDict: {},

    availablePointsRedeemPackages: [],
    pointsRedeemItemSkuDict: {},
    pointsRedeemCategoryNameDict: {},

    allOutletsItemSkuDict: {},

    paymentDetails: null,
});
