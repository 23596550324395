import { Dimensions } from "react-native"
import Colors from './Colors';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;
const button = {
    backgroundColor: Colors.primaryColor,
    padding:20,
    borderRadius:10,
    alignItems: 'center',
    marginVertical : 20
}

const rnPickerSelectStyle = {
    inputAndroidContainer: {
        height: 35,
        justifyContent: 'center',
        backgroundColor: '#fafafa',
        borderRadius: 4,

        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.22,
        shadowRadius: 3.22,
        elevation: 1,
    },
    inputAndroid: {
        backgroundColor: '#fafafa',
        color: 'black',
        fontFamily: 'NunitoSans-Regular',
        fontSize: 14,
    },
    inputIOS: {
        backgroundColor: '#fafafa',
        color: 'black',
        fontFamily: 'NunitoSans-Regular',
        fontSize: 14,

        paddingLeft: 12,
    },
    viewContainer: {
        backgroundColor: '#fafafa',
        borderRadius: 4,
        height: 35,

        justifyContent: 'center',

        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.22,
        shadowRadius: 3.22,
        elevation: 1,
    }
};

const Styles = {
    width,
    height,
    button,
    rnPickerSelectStyle,
};

export default Styles
