
import React, { useState, useEffect } from 'react';
import {
    View,
    Image,
    ActivityIndicator,
    Text,
} from 'react-native';
import Colors from '../../constant/Colors';
import { getImageFromFirebaseStorage } from '../../util/commonFuncs';

const AsyncImage = props => {
    const {
        source,
        style,
    } = props;

    const [url, setUrl] = useState('');

    useEffect(() => {
        if (source && source.uri) {
            if (source.uri.startsWith('http')) {
                setUrl(source.uri);
            }
            else {
                getImageFromFirebaseStorage(source.uri, (parsedUrl) => {
                    setUrl(parsedUrl);
                });

                // const urlTemp = retrieveImageFromFirebase(source);

                // setUrl(urlTemp);
            }
        }
    }, [source]);

    const retrieveImageFromFirebase = async (source) => {
        const urlTemp = await getImageFromFirebaseStorage(source.uri);

        return urlTemp;
    };

    return (
        <>
            {url !== '' ?
                <Image
                    style={style}
                    source={{
                        uri: url
                    }}
                />
                : 
                <View style={[{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }, style]}>
                    <ActivityIndicator color={Colors.primaryColor} size={"large"} />
                </View>
            }
        </>
    );
};

export default AsyncImage;