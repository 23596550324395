import React, { useState, Component, useEffect } from 'react';
import { StyleSheet, ScrollView, Image, Text, View, TextInput, TouchableOpacity, Alert, Modal, KeyboardAvoidingView, Dimensions, ActivityIndicator, useWindowDimensions } from 'react-native';
import firebase from 'firebase';
import AsyncStorage from '@react-native-async-storage/async-storage';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import AntDesign from "react-native-vector-icons/AntDesign";
import SimpleLineIcons from "react-native-vector-icons/SimpleLineIcons";
import Colors from '../constant/Colors';
import Styles from '../constant/Styles';
import API from '../constant/API';
import { CommonStore } from '../store/commonStore';
import { UserStore } from '../store/userStore';
import ApiClient from '../util/ApiClient';
import AwesomeAlert from 'react-native-awesome-alerts';
import { useLinkTo, useRoute } from "@react-navigation/native";

import imgLogo from '../asset/image/logo.png';
import { DataStore } from '../store/dataStore';
import { Collections } from '../constant/firebase';
import { ORDER_TYPE, USER_ORDER_STATUS } from '../constant/common';
import { prefix } from '../constant/env';
import { isMobile } from '../util/commonFuncs';

const NewOrderGenericScreen = props => {
    const {
        route,
    } = props;

    console.log('route');
    console.log(route);

    // this.goToLoginState = this.goToLoginState.bind(this);

    const linkTo = useLinkTo();
    const windowDimensions = useWindowDimensions();

    const [showGenericQRModal, setShowGenericQRModal] = useState(false);
    const [tableMaxPax, setTableMaxPax] = useState(0);
    const [tableCode, setTableCode] = useState('');
    const [seatingPax, setSeatingPax] = useState(0);

    const [currQRJson, setCurrQRJson] = useState({});
    const [currOutlet, setCurrOutlet] = useState({});

    const [showAlertLogin, setShowAlertLogin] = useState(false);

    const [showPaxModal, setShowPaxModal] = useState(false);

    const email = UserStore.useState(s => s.email);
    const name = UserStore.useState(s => s.name);
    const googleId = UserStore.useState(s => s.googleId);
    const imageUrl = UserStore.useState(s => s.imageUrl);
    const tokenId = UserStore.useState(s => s.tokenId);
    const firebaseUid = UserStore.useState(s => s.firebaseUid);

    const isAuthenticating = CommonStore.useState(s => s.isAuthenticating);

    useEffect(() => {
        if (linkTo) {
            DataStore.update(s => {
                s.linkToFunc = linkTo;
            });
        }

        if (route.params === undefined ||
            route.params.outletId === undefined ||
            route.params.tableId === undefined ||
            route.params.tableCode === undefined ||
            // route.params.tablePax === undefined ||
            // route.params.waiterId === undefined ||
            route.params.outletId.length !== 36 ||
            route.params.tableId.length !== 36) {
            console.log('web scan 1');
            linkTo && linkTo(`${prefix}/scan`);
        }
        else {
            // means all got, can login this user to check all info valid or not

            firebase.auth().signInAnonymously()
                .then((result) => {
                    const firebaseUid = result.user.uid;

                    ApiClient.GET(API.getTokenKWeb + firebaseUid).then(async (result) => {
                        console.log('getTokenKWeb');
                        console.log(result);

                        if (result && result.token) {
                            await AsyncStorage.setItem('accessToken', result.token);
                            await AsyncStorage.setItem('refreshToken', result.refreshToken);

                            UserStore.update(s => {
                                s.firebaseUid = result.userId;
                                s.userId = result.userId;
                                s.role = result.role;
                                s.refreshToken = result.refreshToken;
                                s.token = result.token;
                            });

                            const outletSnapshot = await firebase.firestore().collection(Collections.Outlet)
                                .where('uniqueId', '==', route.params.outletId)
                                .limit(1)
                                .get();

                            var outlet = {};
                            if (!outletSnapshot.empty) {
                                outlet = outletSnapshot.docs[0].data();
                            }

                            if (outlet) {
                                // show pax modal before proceed

                                const outletTableSnapshot = await firebase.firestore().collection(Collections.OutletTable)
                                    .where('uniqueId', '==', route.params.tableId)
                                    .limit(1)
                                    .get();

                                var outletTable = {};

                                if (!outletTableSnapshot.empty) {
                                    outletTable = outletTableSnapshot.docs[0].data();

                                    // need check if got active order on this table first

                                    var isEmptyTable = true;

                                    console.log('on retrieving userOrder');

                                    const userOrderSnapshot = await firebase.firestore().collection(Collections.UserOrder)
                                        .where('tableId', '==', route.params.tableId)
                                        .where('deletedAt', '==', null)
                                        .where('orderStatus', 'in', [
                                            USER_ORDER_STATUS.ORDER_RECEIVED,
                                            USER_ORDER_STATUS.ORDER_AUTHORIZED,
                                            USER_ORDER_STATUS.ORDER_PREPARING,
                                            USER_ORDER_STATUS.ORDER_PREPARED,
                                            USER_ORDER_STATUS.ORDER_DELIVERED,
                                        ])
                                        .limit(1)
                                        .get();

                                    console.log('userOrderSnapshot.docs');
                                    console.log(userOrderSnapshot.docs);

                                    if (userOrderSnapshot.empty) {
                                        isEmptyTable = true;
                                    }
                                    else {
                                        isEmptyTable = false;
                                    }

                                    if (isEmptyTable) {
                                        setTableCode(outletTable.code);
                                        setTableMaxPax(outletTable.capacity);

                                        setCurrQRJson(route.params);
                                        setCurrOutlet(outlet);

                                        setShowGenericQRModal(true);

                                        // await updateUserCart(json, outlet);
                                    }
                                    else {
                                        console.log('web scan 2');
                                        linkTo && linkTo(`${prefix}/scan`);
                                    }
                                }
                            }
                            else {
                                console.log('web scan 3');
                                linkTo && linkTo(`${prefix}/scan`);
                            }
                        }
                        else {
                            CommonStore.update(s => {
                                s.alertObj = {
                                    title: 'Error',
                                    message: 'Unauthorized access',
                                };

                                // s.isAuthenticating = false;
                            });

                            console.log('web scan 4');
                            linkTo && linkTo(`${prefix}/scan`);
                        }
                    });
                });
        }
    }, [linkTo, route]);

    useEffect(() => {
        if (linkTo) {
            DataStore.update(s => {
                s.linkToFunc = linkTo;
            });
        }        
    }, [linkTo]);

    useEffect(() => {
        CommonStore.update(s => {
            s.routeName = route.name;
        });
    }, [route]);

    useEffect(() => {
        const parent = props.navigation.dangerouslyGetParent();
        parent.setOptions({
            tabBarVisible: false,
        });
        return () =>
            parent.setOptions({
                tabBarVisible: true,
            });
    }, []);

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User

                console.log('auth changed!');
                console.log(user);

                var uid = user.uid;
                // ...                
            } else {
                // User is signed out
                // ...
            }
        });
    }, []);

    // function here

    const updateUserCart = async (json, outlet, firebaseUid) => {
        const body = {
            userId: firebaseUid,
            // outletId: outlet.uniqueId,
            outletId: json.outletId,
            tableId: json.tableId,
            tableCode: json.tableCode,
            tablePax: json.tablePax ? json.tablePax : seatingPax,
            cartItems: [],

            waiterId: json.waiterId ? json.waiterId : '',
        };

        ApiClient.POST(API.updateUserCart, body).then((result) => {
            if (result && result.status === 'success') {
                CommonStore.update(s => {
                    s.selectedOutlet = outlet;

                    s.scannedQrData = json;

                    s.orderType = ORDER_TYPE.DINEIN;

                    s.selectedOutletTableId = json.tableId;
                    s.selectedOutletWaiterId = json.waiterId;
                    s.selectedOutletTablePax = json.tablePax;
                    s.selectedOutletTableCode = json.tableCode;
                }, () => {
                    setShowGenericQRModal(false);

                    if (outlet && outlet.uniqueId) {
                        // navigation.navigate('OutletMenu', { 
                        //     outletData: outlet, 
                        //     orderType: 0, 
                        //     test: 1 
                        // });

                        linkTo && linkTo(`${prefix}/outlet/menu`);
                    }

                    // if (scanner && scanner.current) {
                    //     scanner.current.reactivate();
                    // }
                });
            }
        });
    };

    const proceedOrderGeneric = async () => {
        const body = {
            tableId: currQRJson.tableId,
            pax: seatingPax,
            outletId: currOutlet.uniqueId,
        };

        ApiClient.POST(API.updateOutletTablePaxByUser, body).then(async (result) => {
            if (result && result.status === 'success') {
                console.log('ok');

                // updateUserCart(currQRJson, currOutlet);

                await AsyncStorage.setItem('latestOutletId', currOutlet.uniqueId);

                await updateUserCart(route.params, currOutlet, firebaseUid);
            }
        });
    };

    // function end    

    console.log('TEST LOGIN');

    return (
        <View style={{
            width: isMobile() ? windowDimensions.width : windowDimensions.width,
            height: windowDimensions.height,
        }}>

            <View style={{
                justifyContent: 'center',
                alignItems: 'center',

                width: isMobile() ? windowDimensions.width : windowDimensions.width,
                height: windowDimensions.height,
            }}>
                <Modal
                    style={{ flex: 1 }}
                    visible={showGenericQRModal}
                    transparent={true}>
                    <View style={styles.modalContainer}>
                        <View style={{
                            width: Styles.width * 0.8,
                            height: Styles.height * 0.5,
                            backgroundColor: Colors.whiteColor,
                            borderRadius: Styles.width * 0.07,
                            padding: Styles.width * 0.07,
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <TouchableOpacity
                                style={styles.closeButton}
                                onPress={() => {
                                    setShowGenericQRModal(false);
                                }}>
                                <AntDesign name="closecircle" size={25} color={Colors.fieldtTxtColor} />
                                {/* <SimpleLineIcons name="close" size={25}
                                    color={Colors.fieldtTxtColor}
                                /> */}
                            </TouchableOpacity>
                            <View style={styles.modalTitle}>
                                <Text style={styles.modalTitleText}>Pax number</Text>
                                <View style={[styles.tableSlotDisplay, {
                                    // width: Dimensions.get('window').width * 0.25,
                                    ...((tableCode && tableCode.length) && {
                                        width: 27 * ((tableCode.length)),
                                    }),
                                    minWidth: 100,
                                    height: Styles.width * 0.25,

                                }]}>
                                    <Text style={[styles.modalTitleText, { fontSize: Styles.width * 0.09, }]}>
                                        {tableCode}
                                    </Text>
                                </View>
                            </View>
                            <View style={[styles.modalBody, { width: "100%", alignItems: 'center', }]}>
                                <View style={{ flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                    <Text style={[styles.modalBodyText, { flex: 1, fontFamily: 'NunitoSans-Bold' }]}>Table {tableCode}</Text>
                                    <View style={{ justifyContent: 'space-between', flexDirection: 'row', flex: 1.0, borderWidth: StyleSheet.hairlineWidth, borderColor: Colors.fieldtTxtColor }}>
                                        <TouchableOpacity
                                            style={{ backgroundColor: Colors.primaryColor, width: Styles.width * 0.065, height: Dimensions.get('window').width * 0.065, alignItems: 'center', justifyContent: 'center' }}
                                            onPress={() => {
                                                if (seatingPax >= 1) {
                                                    setSeatingPax(seatingPax - 1);
                                                }
                                            }}>
                                            <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: 10, color: Colors.whiteColor }}>-</Text>
                                        </TouchableOpacity>
                                        <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                                            <Text style={{ fontFamily: 'NunitoSans-Bold', color: Colors.primaryColor }}>{seatingPax}</Text>
                                        </View>
                                        <TouchableOpacity
                                            style={{ backgroundColor: Colors.primaryColor, width: Styles.width * 0.065, height: Dimensions.get('window').width * 0.065, alignItems: 'center', justifyContent: 'center' }}
                                            onPress={() => {
                                                if (seatingPax < (tableMaxPax)) {
                                                    setSeatingPax(seatingPax + 1);
                                                }
                                            }}>
                                            <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: 10, color: Colors.whiteColor }}>+</Text>
                                        </TouchableOpacity>
                                    </View>
                                    <View style={{ flex: 1, alignItems: 'center' }}>
                                        <Text style={{ fontFamily: 'NunitoSans-SemiBold' }}>Capacity {tableMaxPax}</Text>
                                    </View>
                                </View>
                            </View>

                            <View style={{
                                alignItems: 'center',
                                flexDirection: 'row',
                                // justifyContent: 'space-between',
                                justifyContent: 'center',
                                width: '100%',
                            }}>
                                {/* <TouchableOpacity
                style={styles.modalSaveButton}
                onPress={() => {
                  setInputTablePax(selectedOutletTable.capacity);
                  setUpdateTableModal(true);
                }}>
                <Text style={[styles.modalDescText, { color: Colors.primaryColor }]}>Edit</Text>
              </TouchableOpacity> */}

                                <TouchableOpacity
                                    style={styles.modalSaveButton}
                                    onPress={() => { proceedOrderGeneric() }}>
                                    <Text style={[styles.modalDescText, { color: Colors.primaryColor }]}>Order</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </Modal>

                <Image style={{
                    width: 300,
                    height: 67,
                    alignSelf: 'center',
                    marginBottom: 50.
                }} resizeMode="contain" source={imgLogo} />

                {
                    isAuthenticating
                        ?
                        <View style={{
                            flexDirection: 'column',
                            // alignItems: 'center',
                            // backgroundColor: 'red'
                        }}>
                            <ActivityIndicator color={Colors.primaryColor} size={'large'} />

                            <Text style={{
                                fontFamily: 'NunitoSans-Bold',
                                color: Colors.darkBgColor,
                                fontSize: 16,
                                textAlign: 'center',
                                marginTop: 15,
                            }}>
                                Processing
                            </Text>
                        </View>
                        :
                        <></>
                }
            </View>
        </View >
    );
};

const styles = StyleSheet.create({
    container: {
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
    },
    headerLogo: {
        width: 112,
        height: 25
    },
    logo: {
        width: 300,
        height: 67,
        alignSelf: 'center',
        marginTop: 10,
    },
    logoTxt: {
        color: Colors.descriptionColor,
        fontSize: 20,
        letterSpacing: 7,
        marginTop: 10,
        alignSelf: 'center',
        marginBottom: 40,
        fontFamily: 'NunitoSans-Regular',
    },
    loginTxt: {
        color: Colors.mainTxtColor,
        // fontWeight: "500",
        fontSize: 26,
        fontFamily: 'NunitoSans-Bold',
    },
    description: {
        color: Colors.descriptionColor,
        paddingVertical: 10,
        fontFamily: 'NunitoSans-Regular',
        fontSize: 16,
        marginBottom: 5,
        marginTop: -5,
    },
    textInput: {
        height: 50,
        paddingHorizontal: 20,
        backgroundColor: Colors.fieldtBgColor,
        borderRadius: 8,
        marginTop: 20,
        fontFamily: 'NunitoSans-Regular',
        fontSize: 16,
    },
    checkBox: {
        borderWidth: StyleSheet.hairlineWidth,
        borderColor: Colors.descriptionColor,

        width: 30,
        height: 10,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',

        // marginRight: 5,
    },
    floatbtn: {
        zIndex: 1,
        position: 'absolute',
        bottom: 30,
        right: 30,
        width: 60,
        height: 60,
        borderRadius: 60 / 2,
        backgroundColor: Colors.primaryColor,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 3.22,
        elevation: 3
    },
    loginImg: {
        width: undefined,
        height: '100%',
        resizeMode: 'cover'
    },
    resetContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        marginTop: 0,
        alignSelf: 'center'
    },

    switchContainer: {
        position: 'absolute',
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },

    centerText: {
        padding: 10,
        fontSize: 18,
        color: Colors.descriptionColor,
        textAlign: 'center',
        fontFamily: 'NunitoSans-Regular',
    },
    scanText: {
        fontSize: 20,
        color: '#000000',
        textAlign: 'center',
        backgroundColor: '#008000'
    },

    modalContainer: {
        flex: 1,
        backgroundColor: Colors.modalBgColor,
        alignItems: 'center',
        justifyContent: 'center'
    },
    modalView: {
        height: Styles.width * 1,
        width: Styles.width * 0.8,
        backgroundColor: Colors.whiteColor,
        borderRadius: Styles.width * 0.07,
        padding: Styles.width * 0.07,
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    closeButton: {
        position: 'absolute',
        right: Styles.width * 0.04,
        top: Styles.width * 0.04
    },
    modalTitle: {
        alignItems: 'center',
    },
    modalBody: {
        flex: 0.8,
        alignItems: 'center',
        justifyContent: 'center'
    },
    modalTitleText: {
        fontFamily: 'NunitoSans-Bold',
        marginBottom: 10,
        fontSize: 22
    },
    modalDescText: {
        fontFamily: 'NunitoSans-SemiBold',
        fontSize: 16,
        color: Colors.fieldtTxtColor
    },
    modalBodyText: {
        flex: 1,
        fontFamily: 'NunitoSans-SemiBold',
        fontSize: 16,
        width: "20%"

    },
    modalSaveButton: {

        width: Styles.width * 0.3,
        backgroundColor: Colors.fieldtBgColor,
        height: 45,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,

        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 3.22,
        elevation: 1,
    },
    tableSlotDisplay: {
        width: Styles.width * 0.2,
        height: Styles.width * 0.2,
        margin: 6,
        borderRadius: Styles.width * 0.02,
        padding: Styles.width * 0.01,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: Colors.fieldtTxtColor,
        alignItems: 'center',
        justifyContent: 'center',

    },
})

export default NewOrderGenericScreen;