export const WEEK = {
    0: 'sunday',
    1: 'monday',
    2: 'tuesday',
    3: 'wednesday',
    4: 'thursday',
    5: 'friday',
    6: 'saturday',
};

export const USER_ORDER_STATUS = {
    ORDER_RECEIVED: 'ORDER_RECEIVED',

    ORDER_AUTHORIZED: 'ORDER_AUTHORIZED',

    ORDER_PREPARING: 'ORDER_PREPARING',
    ORDER_PREPARED: 'ORDER_PREPARED',
    ORDER_DELIVERED: 'ORDER_DELIVERED',
    ORDER_COMPLETED: 'ORDER_COMPLETED',

    ORDER_CANCELLED_BY_MERCHANT: 'ORDER_CANCELLED_BY_MERCHANT',
    ORDER_CANCELLED_BY_USER: 'ORDER_CANCELLED_BY_USER',

    ORDER_SENDER_REJECTED: 'ORDER_SENDER_REJECTED',
    ORDER_SENDER_CANCELED: 'ORDER_SENDER_CANCELED',
    ORDER_SENDER_EXPIRED: 'ORDER_SENDER_EXPIRED',
};

export const ORDER_TYPE = {
    DINEIN: 'DINEIN',
    DELIVERY: 'DELIVERY',
    PICKUP: 'PICKUP',
    BEER_DOCKET: 'BEER_DOCKET',
};

export const ORDER_TYPE_PARSED = {
    DINEIN: 'Dine In',
    DELIVERY: 'Delivery',
    PICKUP: 'Takeaway',
    BEER_DOCKET: 'Beer Docket',
};

export const ADDRESS_TYPE = {
    HOME: 'HOME',
    WORK: 'WORK',
    OTHER: 'OTHER',
};

export const USER_RESERVATION_STATUS = {
    PENDING: 'PENDING',
    ACCEPTED: 'ACCEPTED',
    CANCELED: 'CANCELED',
    SEATED: 'SEATED',
};

export const USER_QUEUE_STATUS = {
    PENDING: 'PENDING',
    ACCEPTED: 'ACCEPTED',
    CANCELED: 'CANCELED',
    SEATED: 'SEATED',
};

export const USER_RING_STATUS = {
    PENDING: 'PENDING',
    ACCEPTED: 'ACCEPTED',
    CANCELED: 'CANCELED',
    ATTENDED: 'ATTENDED',
};

export const COURIER_CODE = {
    LALAMOVE: 'LALAMOVE',
    MRSPEEDY: 'MRSPEEDY',
    TELEPORT: 'TELEPORT',
};

export const SENDER_DROPDOWN_LIST = [
    {
        label: 'Lalamove',
        value: COURIER_CODE.LALAMOVE,
        img: require('../asset/image/courier-lalamove.png'),
    },
    {
        label: 'MrSpeedy',
        value: COURIER_CODE.MRSPEEDY,
        img: require('../asset/image/courier-mrspeedy.png'),
    },
    {
        label: 'Teleport',
        value: COURIER_CODE.TELEPORT,
        img: require('../asset/image/courier-teleport.png'),
    },
];

export const COURIER_INFO_DICT = {
    [COURIER_CODE.LALAMOVE]: {
        name: 'Lalamove',
        img: require('../asset/image/courier-lalamove.png'),
    },
    [COURIER_CODE.MRSPEEDY]: {
        name: 'MrSpeedy',
        img: require('../asset/image/courier-mrspeedy.png'),
    },
    [COURIER_CODE.TELEPORT]: {
        name: 'Teleport',
        img: require('../asset/image/courier-teleport.png'),
    },
}

export const CREDIT_CARD_TYPE = {
    VISA: 'Visa',
    MASTER_CARD: 'MasterCard',
};

export const MERCHANT_VOUCHER_TYPE = {
    CASH_VOUCHER: 'CASH_VOUCHER',
};

export const MERCHANT_VOUCHER_STATUS = {
    ACTIVE: 'ACTIVE',
    DISABLED: 'DISABLED',
    EXPIRED: 'EXPIRED',
};

export const LALAMOVE_STATUS = {
    ASSIGNING_DRIVER: 'ASSIGNING_DRIVER',
    ON_GOING: 'ON_GOING',
    PICKED_UP: 'PICKED_UP',
    COMPLETED: 'COMPLETED',
    REJECTED: 'REJECTED',
    CANCELED: 'CANCELED',
    EXPIRED: 'EXPIRED',
};

export const MRSPEEDY_STATUS = {
    new: 'new',
    available: 'available',
    active: 'active',
    completed: 'completed',
    reactivated: 'reactivated',
    draft: 'draft',
    canceled: 'canceled',
    delayed: 'delayed',
};

export const TELEPORT_STATUS = {
    new: 'new',
    available: 'available',
    active: 'active',
    completed: 'completed',
    reactivated: 'reactivated',
    draft: 'draft',
    canceled: 'canceled',
    delayed: 'delayed',
};

export const VERIFALIA_STATUS = {
    DELIVERABLE: 'DELIVERABLE',

    API_NOT_AVAILABLE: 'API_NOT_AVAILABLE',
};

export const USER_ORDER_PAYMENT_OPTIONS = {
    ONLINE: 'ONLINE',
    OFFLINE: 'OFFLINE',
    ALL: 'ALL',
};

export const QR_SCANNING_TYPE = {
    DINEIN: 'DINEIN',
    BEER_DOCKET: 'BEER_DOCKET',
};

export const NOTIFICATIONS_TYPE = {
    USER_ORDER: 'USER_ORDER',
    USER_RING: 'USER_RING',
    PROMOTION_NOTIFICATION_MANUAL: 'PROMOTION_NOTIFICATION_MANUAL',
    PROMOTION_NOTIFICATION_AUTO: 'PROMOTION_NOTIFICATION_AUTO',
    USER_ORDER_COURIER_ACTION: 'USER_ORDER_COURIER_ACTION',
};


export const NOTIFICATIONS_ID = {
    USER_ORDER: '1',
    USER_RING: '2',
    PROMOTION_NOTIFICATION_MANUAL: '3',
    PROMOTION_NOTIFICATION_AUTO: '4',
    USER_ORDER_COURIER_ACTION: '5',
};

const NOTIFICATIONS_CHANNELS_VERSIONS = 'v1.01'; // v3

export const NOTIFICATIONS_CHANNEL = {
    USER_ORDER: `com.mykoodoo.merchant.${NOTIFICATIONS_ID.USER_ORDER}.${NOTIFICATIONS_CHANNELS_VERSIONS}`,
    USER_RING: `com.mykoodoo.merchant.${NOTIFICATIONS_ID.USER_RING}.${NOTIFICATIONS_CHANNELS_VERSIONS}`,
    PROMOTION_NOTIFICATION_MANUAL: `com.mykoodoo.merchant.${NOTIFICATIONS_ID.PROMOTION_NOTIFICATION_MANUAL}.${NOTIFICATIONS_CHANNELS_VERSIONS}`,
    PROMOTION_NOTIFICATION_AUTO: `com.mykoodoo.merchant.${NOTIFICATIONS_ID.PROMOTION_NOTIFICATION_AUTO}.${NOTIFICATIONS_CHANNELS_VERSIONS}`,
    USER_ORDER_COURIER_ACTION: `com.mykoodoo.merchant.${NOTIFICATIONS_ID.USER_ORDER_COURIER_ACTION}.${NOTIFICATIONS_CHANNELS_VERSIONS}`,
};

export const NOTIFICATIONS_CHANNEL_LIST = [
    {
        channelId: NOTIFICATIONS_CHANNEL.USER_ORDER,
        channelName: 'User Order',
        id: NOTIFICATIONS_ID.USER_ORDER,
    },
    {
        channelId: NOTIFICATIONS_CHANNEL.USER_RING,
        channelName: 'User Ring',
        id: NOTIFICATIONS_ID.USER_RING,
    },
    {
        channelId: NOTIFICATIONS_CHANNEL.PROMOTION_NOTIFICATION_MANUAL,
        channelName: 'Promotion Notification Manual',
        id: NOTIFICATIONS_ID.PROMOTION_NOTIFICATION_MANUAL,
    },
    {
        channelId: NOTIFICATIONS_CHANNEL.PROMOTION_NOTIFICATION_AUTO,
        channelName: 'Promotion Notification Auto',
        id: NOTIFICATIONS_ID.PROMOTION_NOTIFICATION_AUTO,
    },
    {
        channelId: NOTIFICATIONS_CHANNEL.USER_ORDER_COURIER_ACTION,
        channelName: 'User Order Courier Action',
        id: NOTIFICATIONS_ID.USER_ORDER_COURIER_ACTION,
    },
];

export const MPS_CHANNEL = {
    fpx_amb: 'fpx_amb',
    fpx_bimb: 'fpx_bimb',
    fpx_cimbclicks: 'fpx_cimbclicks',
    fpx_hlb: 'fpx_hlb',
    fpx_mb2u: 'fpx_mb2u',
    fpx_pbb: 'fpx_pbb',
    fpx_rhb: 'fpx_rhb',
    FPX_OCBC: 'FPX_OCBC',
    FPX_SCB: 'FPX_SCB',
    FPX_ABB: 'FPX_ABB',
    FPX_BKRM: 'FPX_BKRM',
    FPX_BMMB: 'FPX_BMMB',
    FPX_KFH: 'FPX_KFH',
    FPX_BSN: 'FPX_BSN',
    FPX_ABMB: 'FPX_ABMB',
    FPX_UOB: 'FPX_UOB',
    credit: 'credit',
};

export const MPS_CHANNEL_PARSED = {
    fpx_amb: 'FPX Am Bank (Am Online)',
    fpx_bimb: 'FPX Bank Islam',
    fpx_cimbclicks: 'FPX CIMB Bank(CIMB Clicks)',
    fpx_hlb: 'FPX Hong Leong Bank(HLB Connect)',
    fpx_mb2u: 'FPX Maybank(Maybank2u)',
    fpx_pbb: 'FPX PublicBank (PBB Online)',
    fpx_rhb: 'FPX RHB Bank(RHB Now)',
    FPX_OCBC: 'FPX OCBC Bank',
    FPX_SCB: 'FPX Standard Chartered Bank',
    FPX_ABB: 'FPX Affin Bank Berhad',
    FPX_BKRM: 'FPX Bank Kerjasama Rakyat Malaysia Berhad',
    FPX_BMMB: 'FPX Bank Muamalat',
    FPX_KFH: 'FPX Kuwait Finance House',
    FPX_BSN: 'FPX Bank Simpanan Nasional',
    FPX_ABMB: 'FPX Alliance Bank Malaysia Berhad',
    FPX_UOB: 'FPX United Overseas Bank',
    credit: 'Credit Card/ Debit Card',
};

export const MPS_CHANNEL_LIST = [
    {
        label: MPS_CHANNEL_PARSED.fpx_amb,
        value: MPS_CHANNEL.fpx_amb,
    },
    {
        label: MPS_CHANNEL_PARSED.fpx_bimb,
        value: MPS_CHANNEL.fpx_bimb,
    },
    {
        label: MPS_CHANNEL_PARSED.fpx_cimbclicks,
        value: MPS_CHANNEL.fpx_cimbclicks,
    },
    {
        label: MPS_CHANNEL_PARSED.fpx_hlb,
        value: MPS_CHANNEL.fpx_hlb,
    },
    {
        label: MPS_CHANNEL_PARSED.fpx_mb2u,
        value: MPS_CHANNEL.fpx_mb2u,
    },
    {
        label: MPS_CHANNEL_PARSED.fpx_pbb,
        value: MPS_CHANNEL.fpx_pbb,
    },
    {
        label: MPS_CHANNEL_PARSED.fpx_rhb,
        value: MPS_CHANNEL.fpx_rhb,
    },
    {
        label: MPS_CHANNEL_PARSED.FPX_OCBC,
        value: MPS_CHANNEL.FPX_OCBC,
    },
    {
        label: MPS_CHANNEL_PARSED.FPX_SCB,
        value: MPS_CHANNEL.FPX_SCB,
    },
    {
        label: MPS_CHANNEL_PARSED.FPX_ABB,
        value: MPS_CHANNEL.FPX_ABB,
    },
    {
        label: MPS_CHANNEL_PARSED.FPX_BKRM,
        value: MPS_CHANNEL.FPX_BKRM,
    },
    {
        label: MPS_CHANNEL_PARSED.FPX_BMMB,
        value: MPS_CHANNEL.FPX_BMMB,
    },
    {
        label: MPS_CHANNEL_PARSED.FPX_KFH,
        value: MPS_CHANNEL.FPX_KFH,
    },
    {
        label: MPS_CHANNEL_PARSED.FPX_BSN,
        value: MPS_CHANNEL.FPX_BSN,
    },
    {
        label: MPS_CHANNEL_PARSED.FPX_ABMB,
        value: MPS_CHANNEL.FPX_ABMB,
    },
    {
        label: MPS_CHANNEL_PARSED.FPX_UOB,
        value: MPS_CHANNEL.FPX_UOB,
    },
    {
        label: MPS_CHANNEL_PARSED.credit,
        value: MPS_CHANNEL.credit,
    },
];
