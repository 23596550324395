var logined = false;
var userData = null;
var name = "Keith";
var userId = null;
var refreshToken = null;
var refreshMainScreen = null;
var refreshCurrentAction = null;

export function islogined() {
    return logined;
}

export function setlogin(param) {
    logined = param;
}

export function getRefreshCurrentAction() {
    return refreshCurrentAction();
}

export function setRefreshCurrentAction(param) {
    refreshCurrentAction = param;
}

export function setRefreshMainScreen(func) {
    refreshMainScreen = func;
}

export function getRefreshMainScreen() {
    return refreshMainScreen();
}

export function setName(param) {
    name = param;
}

export function getName() {
    return name;
}

export function setUserData(param) {
    userData = param;
}

export function getUserData() {
    return userData;
}


export function getUserId(){
    return userId
}

export function setUserId(param){
    userId = param;
}
