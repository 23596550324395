import React, { Component, useEffect, useState, useRef } from 'react';
import {
  View,
  Text,
  // useWindowDimensions,
  StyleSheet,
  Dimensions
} from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator, useHeaderHeight } from '@react-navigation/stack';
import NewOrderScreen from '../screen/AuthScreen';
import NewOrderGenericScreen from '../screen/NewOrderGenericScreen';
import TakeawayScreen from '../screen/TakeawayScreen';
import ScanScreen from '../screen/ErrorScreen';
import ErrorScreen from '../screen/ErrorScreen.takeaway';
import PaymentSuccessScreen from '../screen/PaymentSuccessScreen';
import OutletMenuScreen from '../screen/OutletMenuScreen';
import MenuItemDetailsScreen from '../screen/MenuItemDetailsScreen';
import CartScreen from '../screen/CartScreen';
import { prefix } from '../constant/env';
import HomeScreen from '../screen/HomeScreen';

const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();

const config = {
  // screens: {
  //   MainScreen: {
  //     path: 'qr/:outletId/:tableId/:tableCode/:waiterId?',
  //   },
  // },
  screens: {
    // NewOrderStack: {
    //   path: "/web/:outletId/:tableId/:tableCode/:tablePax/:waiterId",
    //   initialRouteName: "neworder",
    //   screens: {
    //     Login: {
    //       path: 'new-order',
    //     },
    //   },
    // },

    HomeStack: {
      path: `${prefix}`,
      screens: {
        'KooDoo Nearby': {
          path: '/',
        },
        Error: {
          path: '/error',
        },
      },
    },

    // NewOrderStack: {
    //   path: `${prefix}`,
    //   // initialRouteName: "NewOrder",
    //   screens: {
    //     NewOrder: {
    //       path: 'new-order/:outletId?/:tableId?/:tableCode?/:tablePax?/:waiterId?',
    //     },
    //     NewOrderGeneric: {
    //       path: 'new-order-generic/:outletId?/:tableId?/:tableCode?',
    //     },
    //     Takeaway: {
    //       path: 'takeaway',
    //     },
    //     Scan: {
    //       path: 'scan',
    //     },
    //     Error: {
    //       path: 'error',
    //     },
    //     PaymentSuccess: {
    //       path: 'payment/:amount?/:appCode?/:channel?/:checksum?/:mpSecuredVerfified?/:msgType?/:orderId?/:pInstruction?/:statusCode?/:txnId?',
    //     },
    //   },
    // },
    // OrderStack: {
    //   path: `${prefix}/outlet`,
    //   initialRouteName: "OutletMenu",
    //   screens: {
    //     OutletMenu: {
    //       path: 'menu',
    //     },
    //     MenuItemDetailsScreen: {
    //       path: 'menu/item',
    //     },
    //     CartScreen: {
    //       path: 'cart',
    //     },
    //   },
    // },
  },
};

const linking = {
  prefixes: [
    /* your linking prefixes */
  ],
  config: config,
};

// const NewOrderStack = ({ navigation }) => {
//   navigation.setOptions({ tabBarVisible: false });

//   return (
//     <Stack.Navigator
//       screenOptions={{
//         headerShown: false,
//       }}
//     >
//       <Stack.Screen
//         name='NewOrder'
//         component={NewOrderScreen}
//       />

//       <Stack.Screen
//         name='NewOrderGeneric'
//         component={NewOrderGenericScreen}
//       />

//       <Stack.Screen
//         name='Takeaway'
//         component={TakeawayScreen}
//       />

//       <Stack.Screen
//         name='PaymentSuccess'
//         component={PaymentSuccessScreen}
//       />

//       <Stack.Screen
//         name='Scan'
//         component={ScanScreen}
//       />

//       <Stack.Screen
//         name='Error'
//         component={ErrorScreen}
//       />
//     </Stack.Navigator>
//   );
// }

// const OrderStack = ({ navigation }) => {
//   navigation.setOptions({ tabBarVisible: false });

//   return (
//     <Stack.Navigator
//       screenOptions={{
//         // headerShown: false,
//       }}
//     >
//       <Stack.Screen
//         name='OutletMenu'
//         component={OutletMenuScreen}
//       />
//       <Stack.Screen
//         name='MenuItemDetailsScreen'
//         component={MenuItemDetailsScreen}
//       />
//       <Stack.Screen
//         name='CartScreen'
//         component={CartScreen}
//       />
//     </Stack.Navigator>
//   );
// }

const HomeStack = ({ navigation }) => {
  navigation.setOptions({ tabBarVisible: false });

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name='KooDoo Nearby'
        component={HomeScreen}
      />

      <Stack.Screen
        name='Error'
        component={ErrorScreen}
      />
    </Stack.Navigator>
  );
}

const AppNavigator = () => {
  return (
    <NavigationContainer
      // ref={navigationRef}
      linking={linking} fallback={<Text>Loading...</Text>}>
      <Tab.Navigator tabBar={() => null}>
        <Tab.Screen
          name='HomeStack'
          component={HomeStack}
          screenOptions={({ route }) => ({
            tabBarVisible: null,
          })}
        />

        {/* <Tab.Screen
          name='NewOrderStack'
          component={NewOrderStack}
          screenOptions={({ route }) => ({
            tabBarVisible: null,
          })}
        />

        <Tab.Screen
          name='OrderStack'
          component={OrderStack}
          screenOptions={({ route }) => ({
            tabBarVisible: null,
          })}
        /> */}
      </Tab.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  menuItemsCard: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
  },
  circleContainer: {
    width: 50,
    height: 50,
    borderRadius: 25,
    padding: 10,
  },
});

export default AppNavigator;