const primaryColor = '#4E9F7D'
const secondaryColor = '#E8CE8B'
const darkBgColor = '#335E4A'
const highlightColor = '#E5F1EC'
const mainTxtColor = '#27353C'
const descriptionColor = '#7B8E92'
const fieldtTxtColor = '#ACACAC'
const fieldtBgColor = '#F7F7F7'
const whiteColor = '#FFFFFF'
const blackColor = '#000000'
const tabRed = '#ED2024'
const tabGrey = '#7A8E92'
const tabYellow = '#FFD15B'
const fontDark = '#27353C'
const lightPrimary = '#DCECE5'
const modalBgColor = 'rgba(0,0,0,0.5)'

const Colors = {
    primaryColor,
    secondaryColor,
    darkBgColor,
    highlightColor,
    mainTxtColor,
    descriptionColor,
    fieldtTxtColor,
    fieldtBgColor,
    whiteColor,
    blackColor,
    tabRed,
    tabGrey,
    tabYellow,
    fontDark,
    lightPrimary,
    modalBgColor,

}

export default Colors
