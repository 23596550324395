var Collections = {};

Collections.User = 'User';
Collections.UserCart = 'UserCart';
Collections.Outlet = 'Outlet';
Collections.Merchant = 'Merchant';
Collections.UserOutlet = 'UserOutlet';
Collections.QRDynamic = 'QRDynamic';
Collections.UserLegacy = 'UserLegacy';
Collections.Notification = 'Notification';
Collections.Activity = 'Activity';
Collections.GiftCard = 'GiftCard';
Collections.UserSegment = 'UserSegment';
Collections.GiftCardSegment = 'GiftCardSegment';
Collections.AppliedGiftCard = 'AppliedGiftCard';
Collections.UserGiftCard = 'UserGiftCard';
Collections.UserReferral = 'UserReferral';
Collections.Category = 'Category';
Collections.Item = 'Item';
Collections.ItemAddOn = 'ItemAddOn';
Collections.ItemAddOnChoice = 'ItemAddOnChoice';
Collections.SimpleComps = 'SimpleComps';
Collections.Supplier = 'Supplier';
Collections.SupplyProduct = 'SupplyProduct';
Collections.Tax = 'Tax';
Collections.Table = 'Table';
Collections.Order = 'Order';
Collections.OutletRing = 'OutletRing';
Collections.ItemPromo = 'ItemPromo';
Collections.OrderItem = 'OrderItem';
Collections.BirthdayRewardClaimed = 'BirthdayRewardClaimed';
Collections.BirthdayReward = 'BirthdayReward';
Collections.UserReward = 'UserReward';
Collections.MerchantLoyalty = 'MerchantLoyalty';
Collections.Reward = 'Reward';
Collections.RewardDiscount = 'RewardDiscount';
Collections.RewardDiscountCategory = 'RewardDiscountCategory';
Collections.RewardDiscountBill = 'RewardDiscountBill';
Collections.RewardDiscountItem = 'RewardDiscountItem';
Collections.RewardCash = 'RewardCash';
Collections.RewardItem = 'RewardItem';
Collections.MerchantReview = 'MerchantReview';
Collections.OutletReview = 'OutletReview';
Collections.OutletReviewSummary = 'OutletReviewSummary';
Collections.OutletOpening = 'OutletOpening';

Collections.OutletItem = 'OutletItem';
Collections.OutletItemCategory = 'OutletItemCategory';
Collections.OutletItemAddOn = 'OutletItemAddOn';
Collections.OutletItemAddOnChoice = 'OutletItemAddOnChoice';
Collections.OutletItemSPLink = 'OutletItemSPLink';
Collections.OutletSupplyProduct = 'OutletSupplyProduct';
Collections.OutletTax = 'OutletTax';

Collections.UserAddress = 'UserAddress';
Collections.UserOrder = 'UserOrder';

Collections.OutletTable = 'OutletTable';
Collections.OutletSection = 'OutletSection';

Collections.Tag = 'Tag';
Collections.OutletTag = 'OutletTag';

Collections.UserReservation = 'UserReservation';
Collections.UserQueue = 'UserQueue';
Collections.UserRing = 'UserRing';

Collections.Segment = 'Segment';
Collections.MerchantVoucher = 'MerchantVoucher';
Collections.UserVoucherRedemption = 'UserVoucherRedemption';

Collections.OutletShift = 'OutletShift';

Collections.UserFavoriteOutlet = 'UserFavoriteOutlet';

Collections.UserLoyalty = 'UserLoyalty';

Collections.BeerDocketCategory = 'BeerDocketCategory';
Collections.BeerDocket = 'BeerDocket';
Collections.UserBeerDocket = 'UserBeerDocket';

Collections.Promotion = 'Promotion';

Collections.CRMUser = 'CRMUser';
Collections.CRMUserTag = 'CRMUserTag';
Collections.CRMMerchant = 'CRMMerchant';

Collections.PreorderPackage = 'PreorderPackage';

Collections.PointsRedeemPackage = 'PointsRedeemPackage';

Collections.UserPointsTransaction = 'UserPointsTransaction';

export {
    Collections,
};
