const getToken = 'token?ctoken=FIREBASE:'
const getAllOutletTags = 'getAllOutletTags'
const getOutletByTags = 'getOutletByTags?tagId='
const outletSearch = 'outletSearch/'
const outlet2 = 'outlet2/'
const merchantMenu = 'merchantMenu/'
const getItemAddOnChoice = 'getItemAddOnChoice/?itemId='
const getTakeAway = 'getTakeAway?lat2='
const orderHistory2 = 'orderHistory2'
const order2 = 'order2/'
const createOrder = 'order2'
const allOrder = 'getAllOrder?userId='
const outletSearchByName = 'outletSearch?text='
const user = 'user'
const initialRegister = 'initialRegister'
const getAllUserQueue = 'getAllUserQueue/'
const getReservationList = 'reservations/getReservationList/'
const requestReservation = 'reservations/requestReservation'
const getCurrentOrder = 'getCurrentOrder/'
const queue = 'queue'
const cancel = 'cancel'
const cancelReservation = 'reservations/cancelReservation/'
const getPopular = 'getPopular'
const getCategory = 'findOutletCategory?outletId='
const activeMenu = "merchantActiveMenu?outletId="
const redeemParking = 'redeemParking'
const extendParking = 'extendParking'
const createUserParking = 'createUserParking'
const getUserParking = 'getUserParking?userId='
const getIdParking = 'getIdParking?parkingId='
const getUserFireOrder = 'getUserFireOrder?userId='
const serveFireOrder = 'serveFireOrder'
const getUserCreditCard = "getUserCreditCard?userId="
const createUserAddress = 'createUserAddress'
const getUserAddress = 'getUserAddress?userId='
const getTakeAwayFilter = "getTakeAwayFilter?lat2="
const getFavoriteOutlet = "getFavoriteOutlet?userId="
const favoriteOutlet = "favoriteOutlet"
const removeFavoriteOutlet = "removeFavoriteOutlet"
const getCreditHistory = 'getCreditHistory?userId='
const createUserCreditCard= "createUserCreditCard"
const getUserPoints = "getUserPoints?userId="
const getUserReferral = "getUserReferral?userId="
const createUserReferral = "createUserReferral"
const userRingOutlet = 'userRingOutlet'
const myQueue = "myQueue?userId="
const getUserNotificationSetting = "getUserNotificationSetting?userId="
const editUserNotificationSetting = "editUserNotificationSetting"
const orderDetail = 'getCurrentOrder'
const getUserVoucher = 'getUserVoucher?userId='
const userResetPassword = 'userResetPassword'
const userFeedback = 'feedback'
const updateUserAvatar = 'updateUserAvatar'
const feedback = 'feedback'
const editUserAddress = 'editUserAddress'
const createOrderDelivery = 'createOrderDelivery'
const getOrderDelivery = 'getOrderDelivery?orderId='
const updateOrderDelivery = 'updateOrderDelivery'
const lalamoveQuotation = 'lalamoveQuotation'
const lalamoveQuotationScheduled = 'lalamoveQuotationScheduled'
const lalamovePlaceOrder = 'lalamovePlaceOrder'
const lalamoveGetOrderStatus = 'lalamoveGetOrderStatus?customerOrderId='
const lalamoveGetDriverInfo = 'lalamoveGetDriverInfo'
const lalamoveGetDriverLocation = 'lalamoveGetDriverLocation'
const lalamoveCancelOrder = 'lalamoveCancelOrder'

const mrSpeedyCalculateOrder = 'mrSpeedyCalculateOrder';

const syncUserGiftCard = 'syncUserGiftCard';

const userAddPhoneNumber = 'userAddPhoneNumber';
const userGetPhoneNumber = 'userGetPhoneNumber?userId=';

const removeUserCreditCard = 'removeUserCreditCard';
const userRemovePhoneNumber = 'userRemovePhoneNumber';

const getAllUserSegments = 'getAllUserSegments?userId=';

const createOrderPickUp = 'createOrderPickUp';
const getOrderPickUp = 'getOrderPickUp?orderId=';
const updateOrderPickUp = 'updateOrderPickUp';

const createUserOrder = 'createUserOrder';
const createUserReservation = 'createUserReservation';
const createUserQueue = 'createUserQueue';
const createUserRing = 'createUserRing';

const createUserOrderBeerDocket = 'createUserOrderBeerDocket';

const addCustomer = "customerInTable";

const updateUserCart = 'updateUserCart';
const deleteUserCart = 'deleteUserCart';

const updateOutletTableUserId = 'updateOutletTableUserId';

const updateTokenFcm = 'updateTokenFcm';

const addUserFavoriteOutlet = 'addUserFavoriteOutlet';
const removeUserFavoriteOutlet = 'removeUserFavoriteOutlet';

const updateUserBeerDocketQuantity = 'updateUserBeerDocketQuantity';

const getNextOrderId = 'getNextOrderId';

const waitForSender = 'waitForSender';
const updateUserOrderCourierByUser = 'updateUserOrderCourierByUser';
const cancelUserOrderByUser = 'cancelUserOrderByUser';
const completeUserOrderByUser = 'completeUserOrderByUser';

const submitOrderReviewByUser = 'submitOrderReviewByUser';

const teleportOrderEstimatePricing = 'teleportOrderEstimatePricing';

const getTokenKWeb = 'getTokenKWeb?firebaseUid=';

const updateOutletTablePaxByUser = 'updateOutletTablePaxByUser';

const API = {
    getToken,
    getAllOutletTags,
    getOutletByTags,
    outletSearch,
    outlet2,
    merchantMenu,
    getItemAddOnChoice,
    getTakeAway,
    getAllUserQueue,
    orderHistory2,
    order2,
    allOrder,
    outletSearchByName,
    user,
    initialRegister,
    getReservationList,
    getCurrentOrder,
    requestReservation,
    queue,
    cancel,
    cancelReservation,
    getPopular,
    createOrder,
    getCategory,
    activeMenu,
    extendParking,
    redeemParking,
    getUserParking,
    createUserParking,
    getIdParking,
    getUserFireOrder,
    serveFireOrder,
    getUserCreditCard,
    createUserCreditCard,
    createUserAddress,
    getUserAddress,
    getTakeAwayFilter,
    getFavoriteOutlet,
    favoriteOutlet,
    removeFavoriteOutlet,
    getCreditHistory,
    getUserCreditCard,
    getUserPoints,
    getUserReferral,
    userRingOutlet,
    createUserReferral,
    myQueue,
    getUserNotificationSetting,
    editUserNotificationSetting,
    orderDetail,
    getUserVoucher,
    userResetPassword,
    userFeedback,
    updateUserAvatar,
    feedback,
    editUserAddress,
    createOrderDelivery,
    getOrderDelivery,
    updateOrderDelivery,
    lalamoveQuotation,
    lalamoveQuotationScheduled,
    lalamovePlaceOrder,
    lalamoveGetOrderStatus,
    lalamoveGetDriverInfo,
    lalamoveGetDriverLocation, 
    lalamoveCancelOrder,

    mrSpeedyCalculateOrder,

    syncUserGiftCard,

    userAddPhoneNumber,
    userGetPhoneNumber,

    removeUserCreditCard,
    userRemovePhoneNumber,

    getAllUserSegments,

    createOrderPickUp,
    getOrderPickUp,
    updateOrderPickUp,

    createUserOrder,
    createUserReservation,
    createUserQueue,
    createUserRing,

    createUserOrderBeerDocket,

    addCustomer,

    updateUserCart,
    deleteUserCart,

    updateOutletTableUserId,

    updateTokenFcm,

    addUserFavoriteOutlet,
    removeUserFavoriteOutlet,

    updateUserBeerDocketQuantity,

    getNextOrderId,

    waitForSender,
    updateUserOrderCourierByUser,
    cancelUserOrderByUser,
    completeUserOrderByUser,

    submitOrderReviewByUser,

    teleportOrderEstimatePricing,

    getTokenKWeb,

    updateOutletTablePaxByUser,
};

export default API;
