import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, Dimensions } from "react-native";
import firebase from 'firebase/app';
import AppNavigator from "./navigation/AppNavigator";
import FontAwesomeTTF from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import SimpleLineIconsTTF from 'react-native-vector-icons/Fonts/SimpleLineIcons.ttf';
import EntypoTTF from 'react-native-vector-icons/Fonts/Entypo.ttf';
import IoniconsTTF from 'react-native-vector-icons/Fonts/Ionicons.ttf';
import FeatherTTF from 'react-native-vector-icons/Fonts/Feather.ttf';
import AntDesignTTF from 'react-native-vector-icons/Fonts/AntDesign.ttf';
import FontistoTTF from 'react-native-vector-icons/Fonts/Fontisto.ttf';
import NunitoSansBoldTTF from './assets/fonts/NunitoSans-Bold.ttf';
import NunitoSansRegularTTF from './assets/fonts/NunitoSans-Regular.ttf';
import NunitoSansSemiBoldTTF from './assets/fonts/NunitoSans-SemiBold.ttf';
import { CommonStore } from "../src/store/commonStore";
import AwesomeAlert from 'react-native-awesome-alerts';
import {
  listenToUserChanges,
  listenToLocationChanges, listenToSelectedOutletChanges, listenToSelectedOutletItemChanges, listenToCommonChanges, listenToSelectedOutletTagChanges, listenToSearchOutletTextChanges, listenToSearchOutletMerchantIdChanges, listenToSelectedOutletTableIdChanges, requestNotificationsPermission, getOutletById, isMobile
} from '../src/util/commonFuncs';
import Ionicons from 'react-native-vector-icons/Ionicons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Draggable from 'react-native-draggable';
import Colors from '../src/constant/Colors';
import { useNavigation } from '@react-navigation/native';
import { DataStore } from '../src/store/dataStore';
import { UserStore } from '../src/store/userStore';
// import messaging from '@react-native-firebase/messaging';
import { Collections } from '../src/constant/firebase';
import ApiClient from '../src/util/ApiClient';
import API from '../src/constant/API';
import { parseMessages } from '../src/util/notifications';
import { NotificationStore } from '../src/store/notificationStore';
import { PROMOTION_TYPE, PROMOTION_TYPE_VARIATION } from '../src/constant/promotions';
import { prefix } from "./constant/env";
import Styles from "./constant/Styles";

// Look at public/index.html!

const firebaseConfig = require('./config/firebase-web.json');

firebase.initializeApp(firebaseConfig);

const App = () => {
  const [cartIcon, setCartIcon] = useState(false);

  const alertObj = CommonStore.useState(s => s.alertObj);

  const firebaseUid = UserStore.useState(s => s.firebaseUid);

  const lat = CommonStore.useState(s => s.lat);
  const lng = CommonStore.useState(s => s.lng);

  const selectedOutlet = CommonStore.useState(s => s.selectedOutlet);
  const selectedOutletItem = CommonStore.useState(s => s.selectedOutletItem);

  const cartItems = CommonStore.useState(s => s.cartItems);

  const selectedOutletTableId = CommonStore.useState(s => s.selectedOutletTableId);

  const linkToFunc = DataStore.useState(s => s.linkToFunc);

  const currPage = CommonStore.useState(s => s.currPage);

  const userGroups = UserStore.useState(s => s.userGroups);
  const email = UserStore.useState(s => s.email);

  const selectedOutletPromotions = CommonStore.useState(s => s.selectedOutletPromotions);
  const availablePromotions = CommonStore.useState(s => s.availablePromotions);

  const selectedOutletPointsRedeemPackages = CommonStore.useState(s => s.selectedOutletPointsRedeemPackages);
  const availablePointsRedeemPackages = CommonStore.useState(s => s.availablePointsRedeemPackages);

  const selectedOutletCRMTagsDict = CommonStore.useState(s => s.selectedOutletCRMTagsDict);
  const selectedOutletCRMUser = CommonStore.useState(s => s.selectedOutletCRMUser);

  const loadAsyncStorage = async () => {
    // await AsyncStorage.removeItem(`${firebaseUid}.cartItems`);

    const cartItemsRaw = await AsyncStorage.getItem(`${firebaseUid}.cartItems`);
    const cartOutletId = await AsyncStorage.getItem(`${firebaseUid}.cartOutletId`);

    if (cartItemsRaw) {
      DataStore.update(s => {
        s.cartItems = [
          // ...s.cartItems,
          ...JSON.parse(cartItemsRaw),
        ];
      });
    }

    if (cartOutletId) {
      CommonStore.update(s => {
        s.cartOutletId = cartOutletId;
      });
    }
  };

  useEffect(() => {
    CommonStore.subscribe(
      (s) => s,
      async (commonStore) => {
        await AsyncStorage.setItem('@commonStore', JSON.stringify(commonStore));
      },
    );

    DataStore.subscribe(
      (s) => s,
      async (dataStore) => {
        await AsyncStorage.setItem('@dataStore', JSON.stringify(dataStore));
      },
    );

    UserStore.subscribe(
      (s) => s,
      async (userStore) => {
        await AsyncStorage.setItem('@userStore', JSON.stringify(userStore));
      },
    );

    console.log('isMobile');
    console.log(isMobile());
  }, []);

  useEffect(() => {
    if (firebaseUid !== '') {
      listenToUserChanges(firebaseUid);

      // loadAsyncStorage();
    }
  }, [firebaseUid]);

  useEffect(() => {
    if (lat !== null && lng !== null) {
      listenToLocationChanges(lat, lng);
    }
  }, [lat, lng]);

  useEffect(() => {
    if (selectedOutlet !== null
      // && email
    ) {
      listenToSelectedOutletChanges(selectedOutlet, email);
    }
  }, [selectedOutlet, email]);

  useEffect(() => {
    if (selectedOutletItem !== null &&
      selectedOutletItem !== undefined &&
      selectedOutletItem.uniqueId) {
      listenToSelectedOutletItemChanges(selectedOutletItem);
    }
  }, [selectedOutletItem]);

  useEffect(() => {
    listenToCommonChanges();

    // requestNotificationsPermission();

    // messaging().onMessage(async msg => {
    //   console.log('message from foreground!');
    //   console.log(msg);

    //   parseMessages(msg);
    // });
  }, []);

  // useEffect(() => {
  //   if (selectedOutletTag !== null &&
  //     selectedOutletTag !== undefined &&
  //     selectedOutletTag.uniqueId) {
  //     listenToSelectedOutletTagChanges(selectedOutletTag);
  //   }
  // }, [selectedOutletTag]);

  // useEffect(() => {
  //   if (searchOutletText && searchOutletText.length > 0) {
  //     listenToSearchOutletTextChanges(searchOutletText);
  //   }
  // }, [searchOutletText]);

  // useEffect(() => {
  //   if (searchOutletMerchantId && searchOutletMerchantId.length > 0) {
  //     listenToSearchOutletMerchantIdChanges(searchOutletMerchantId);
  //   }
  // }, [searchOutletMerchantId]);

  useEffect(() => {
    if (selectedOutletTableId && selectedOutletTableId.length > 0 && selectedOutlet && selectedOutlet.uniqueId && firebaseUid.length > 0) {
      listenToSelectedOutletTableIdChanges(firebaseUid, selectedOutletTableId, selectedOutlet.uniqueId);
    }
  }, [firebaseUid, selectedOutletTableId, selectedOutlet]);

  // useEffect(() => {
  //   // combine merchant's beer docket with user's redeemed beer docket record, to merge user's action changes (redeemed mug, extended days, etc)

  //   var userBeerDocketsTemp = [];
  //   var selectedUserBeerDocketTemp = {};

  //   for (var i = 0; i < beerDockets.length; i++) {
  //     var record = {
  //       ...beerDockets[i],
  //       beerDocketId: beerDockets[i].uniqueId,
  //     };

  //     if (beerDocketsRedemptionsBDDict[record.beerDocketId]) {
  //       record = {
  //         ...record,
  //         ...beerDocketsRedemptionsBDDict[record.uniqueId], // extend the docket default data with user's own data
  //         userBeerDocketId: beerDocketsRedemptionsBDDict[record.uniqueId].uniqueId,
  //       };
  //     }

  //     userBeerDocketsTemp.push(record);

  //     if (record.beerDocketId === selectedUserBeerDocket.beerDocketId) {
  //       selectedUserBeerDocketTemp = record;
  //     }
  //   };

  //   console.log('changed userBeerDockets!');
  //   console.log(userBeerDocketsTemp);

  //   CommonStore.update(s => {
  //     s.userBeerDockets = userBeerDocketsTemp;

  //     if (selectedUserBeerDocket && selectedUserBeerDocket.beerDocketId) {
  //       s.selectedUserBeerDocket = selectedUserBeerDocketTemp;
  //     }
  //   });
  // }, [beerDockets, beerDocketsRedemptionsBDDict]);

  useEffect(() => {
    var availablePromotionsTemp = [];
    var availablePointsRedeemPackagesTemp = [];

    for (var i = 0; i < selectedOutletPromotions.length; i++) {
      var isValid = false;

      if (userGroups.includes(selectedOutletPromotions[i].targetUserGroup)) {
        isValid = true;
      }

      if (selectedOutletCRMTagsDict[selectedOutletPromotions[i].targetUserGroup]) {
        const currCrmUserTag = selectedOutletCRMTagsDict[selectedOutletPromotions[i].targetUserGroup];

        if (currCrmUserTag.emailList.includes(email)) {
          // means got

          isValid = true;
        }
      }

      if (isValid) {
        availablePromotionsTemp.push(selectedOutletPromotions[i]);
      }
    }

    for (var i = 0; i < selectedOutletPointsRedeemPackages.length; i++) {
      var isValid = false;

      if (userGroups.includes(selectedOutletPointsRedeemPackages[i].targetUserGroup)) {
        isValid = true;
      }

      if (selectedOutletCRMTagsDict[selectedOutletPointsRedeemPackages[i].targetUserGroup]) {
        const currCrmUserTag = selectedOutletCRMTagsDict[selectedOutletPointsRedeemPackages[i].targetUserGroup];

        if (currCrmUserTag.emailList.includes(email)) {
          // means got

          isValid = true;
        }
      }

      //////////////////////////////////

      if (selectedOutletCRMUser && selectedOutletCRMUser.pointsRedeemPackageDisableDict) {
        if (selectedOutletCRMUser.pointsRedeemPackageDisableDict[selectedOutletPointsRedeemPackages[i].uniqueId]) {
          isValid = false;
        }
      }

      //////////////////////////////////

      if (isValid) {
        availablePointsRedeemPackagesTemp.push(selectedOutletPointsRedeemPackages[i]);
      }
    }

    CommonStore.update(s => {
      s.availablePromotions = availablePromotionsTemp;
      s.availablePointsRedeemPackages = availablePointsRedeemPackagesTemp;
    });
  }, [
    selectedOutletPromotions,
    selectedOutletPointsRedeemPackages,
    userGroups,
    email,

    selectedOutletCRMTagsDict,
    selectedOutletCRMUser,
  ]);

  useEffect(() => {
    var overrideItemPriceSkuDictTemp = {};
    var amountOffItemSkuDictTemp = {};
    var percentageOffItemSkuDictTemp = {};

    var overrideCategoryPriceNameDictTemp = {};
    var amountOffCategoryNameDictTemp = {};
    var percentageOffCategoryNameDictTemp = {};

    for (var i = 0; i < availablePromotions.length; i++) {
      if (availablePromotions[i].promotionType === PROMOTION_TYPE.OVERRIDE_EXISTING_PRICE) {
        for (var j = 0; j < availablePromotions[i].criteriaList.length; j++) {
          const criteria = availablePromotions[i].criteriaList[j];

          if (criteria.variation === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS) {
            for (var k = 0; k < criteria.variationItemsSku.length; k++) {
              overrideItemPriceSkuDictTemp[criteria.variationItemsSku[k]] = criteria.priceBeforeTax;
            }
          }
          else if (criteria.variation === PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY) {
            for (var k = 0; k < criteria.variationItemsSku.length; k++) {
              overrideCategoryPriceNameDictTemp[criteria.variationItemsSku[k]] = criteria.priceBeforeTax;
            }
          }
        }
      }
      else if (availablePromotions[i].promotionType === PROMOTION_TYPE.TAKE_AMOUNT_OFF) {
        for (var j = 0; j < availablePromotions[i].criteriaList.length; j++) {
          const criteria = availablePromotions[i].criteriaList[j];

          if (criteria.variation === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS) {
            for (var k = 0; k < criteria.variationItemsSku.length; k++) {
              amountOffItemSkuDictTemp[criteria.variationItemsSku[k]] = {
                amountOff: criteria.amountOff,
                maxQuantity: criteria.maxQuantity,
                minQuantity: criteria.minQuantity,

                quantityMin: criteria.quantityMin,
                quantityMax: criteria.quantityMax,
                priceMin: criteria.priceMin,
                priceMax: criteria.priceMax,
              };
            }
          }
          else if (criteria.variation === PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY) {
            for (var k = 0; k < criteria.variationItemsSku.length; k++) {
              amountOffCategoryNameDictTemp[criteria.variationItemsSku[k]] = {
                amountOff: criteria.amountOff,
                maxQuantity: criteria.maxQuantity,
                minQuantity: criteria.minQuantity,

                quantityMin: criteria.quantityMin,
                quantityMax: criteria.quantityMax,
                priceMin: criteria.priceMin,
                priceMax: criteria.priceMax,
              };
            }
          }
        }
      }
      else if (availablePromotions[i].promotionType === PROMOTION_TYPE.TAKE_PERCENTAGE_OFF) {
        for (var j = 0; j < availablePromotions[i].criteriaList.length; j++) {
          const criteria = availablePromotions[i].criteriaList[j];

          if (criteria.variation === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS) {
            for (var k = 0; k < criteria.variationItemsSku.length; k++) {
              percentageOffItemSkuDictTemp[criteria.variationItemsSku[k]] = {
                percentageOff: criteria.percentageOff,
                maxQuantity: criteria.maxQuantity,
                minQuantity: criteria.minQuantity,

                quantityMin: criteria.quantityMin,
                quantityMax: criteria.quantityMax,
                priceMin: criteria.priceMin,
                priceMax: criteria.priceMax,
              };
            }
          }
          else if (criteria.variation === PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY) {
            for (var k = 0; k < criteria.variationItemsSku.length; k++) {
              percentageOffCategoryNameDictTemp[criteria.variationItemsSku[k]] = {
                percentageOff: criteria.percentageOff,
                maxQuantity: criteria.maxQuantity,
                minQuantity: criteria.minQuantity,

                quantityMin: criteria.quantityMin,
                quantityMax: criteria.quantityMax,
                priceMin: criteria.priceMin,
                priceMax: criteria.priceMax,
              };
            }
          }
        }
      }
    }

    CommonStore.update(s => {
      s.overrideItemPriceSkuDict = overrideItemPriceSkuDictTemp;
      s.amountOffItemSkuDict = amountOffItemSkuDictTemp;
      s.percentageOffItemSkuDict = percentageOffItemSkuDictTemp;

      s.overrideCategoryPriceNameDict = overrideCategoryPriceNameDictTemp;
      s.amountOffCategoryNameDict = amountOffCategoryNameDictTemp;
      s.percentageOffCategoryNameDict = percentageOffCategoryNameDictTemp;
    });
  }, [availablePromotions]);

  useEffect(() => {
    var pointsRedeemItemSkuDict = {};
    var pointsRedeemCategoryNameDict = {};

    for (var i = 0; i < availablePointsRedeemPackages.length; i++) {
      const pointsRedeemPackage = availablePointsRedeemPackages[i];

      if (pointsRedeemPackage.variation === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS) {
        for (var k = 0; k < pointsRedeemPackage.variationItemsSku.length; k++) {
          pointsRedeemItemSkuDict[pointsRedeemPackage.variationItemsSku[k]] = {
            packageId: pointsRedeemPackage.uniqueId,
            limitRedemptionPerUser: pointsRedeemPackage.limitRedemptionPerUser,
            conversionCurrency: pointsRedeemPackage.conversionCurrency,
            conversionCurrencyTo: pointsRedeemPackage.conversionCurrencyTo,
            conversionPointsFrom: pointsRedeemPackage.conversionPointsFrom,
          };
        }
      }
      else if (pointsRedeemPackage.variation === PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY) {
        for (var k = 0; k < pointsRedeemPackage.variationItemsSku.length; k++) {
          pointsRedeemCategoryNameDict[pointsRedeemPackage.variationItemsSku[k]] = {
            packageId: pointsRedeemPackage.uniqueId,
            limitRedemptionPerUser: pointsRedeemPackage.limitRedemptionPerUser,
            conversionCurrency: pointsRedeemPackage.conversionCurrency,
            conversionCurrencyTo: pointsRedeemPackage.conversionCurrencyTo,
            conversionPointsFrom: pointsRedeemPackage.conversionPointsFrom,
          };
        }
      }
    }

    CommonStore.update(s => {
      s.pointsRedeemItemSkuDict = pointsRedeemItemSkuDict;
      s.pointsRedeemCategoryNameDict = pointsRedeemCategoryNameDict;
    });
  }, [availablePointsRedeemPackages]);

  /////////////////////////////////////////////////////

  useEffect(() => {
    if (cartItems.length > 0) {
      setCartIcon(true);
    }
    else {
      setCartIcon(false);
    }
  }, [cartItems.length]);

  const onCartClicked = () => {
    if (cartItems.length > 0) {
      // navigationObj && navigationObj.navigate("Cart", { test: null, outletData: selectedOutlet });
      linkToFunc && linkToFunc(`${prefix}/outlet/cart`);
    } else {
      CommonStore.update(s => {
        s.alertObj = {
          title: 'Info',
          message: 'No item in your cart at the moment',
        };

        // s.isAuthenticating = false;
      });

      // Alert.alert("Info", "No item in your cart at the moment", [
      //   { text: "OK", onPress: () => { } }
      // ],
      //   { cancelable: false })      
    }
  };

  return (
    <View style={{
      width: isMobile() ? Dimensions.get('window').width : Dimensions.get('window').width,
      height: Dimensions.get('window').height,
      alignSelf: 'center',
      justifyContent: 'center'
    }}>
      <style type="text/css">{`
        @font-face {
          font-family: 'FontAwesome';
          src: url(${FontAwesomeTTF}) format('truetype');
        }

        @font-face {
          font-family: 'SimpleLineIcons';
          src: url(${SimpleLineIconsTTF}) format('truetype');
        }

        @font-face {
          font-family: 'Entypo';
          src: url(${EntypoTTF}) format('truetype');
        }

        @font-face {
          font-family: 'Ionicons';
          src: url(${IoniconsTTF}) format('truetype');
        }

        @font-face {
          font-family: 'Feather';
          src: url(${FeatherTTF}) format('truetype');
        }

        @font-face {
          font-family: 'AntDesign';
          src: url(${AntDesignTTF}) format('truetype');
        }

        @font-face {
          font-family: 'Fontisto';
          src: url(${FontistoTTF}) format('truetype');
        }

        @font-face {
          font-family: 'NunitoSans-Bold';
          src: url(${NunitoSansBoldTTF}) format('truetype');
        }

        @font-face {
          font-family: 'NunitoSans-SemiBold';
          src: url(${NunitoSansSemiBoldTTF}) format('truetype');
        }

        @font-face {
          font-family: 'NunitoSans-Regular';
          src: url(${NunitoSansRegularTTF}) format('truetype');
        }
      `}</style>

      <AwesomeAlert
        show={alertObj !== null}
        showProgress={false}
        title={alertObj ? alertObj.title : ''}
        message={alertObj ? alertObj.message : ''}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={true}
        // cancelText="No, cancel"
        confirmText="OK"
        confirmButtonColor="#DD6B55"
        onCancelPressed={() => {
          // this.hideAlert();
          // setShowAlertLogin(false)
          CommonStore.update(s => {
            s.alertObj = null;
          });
        }}
        onConfirmPressed={() => {
          // this.hideAlert();
          // setShowAlertLogin(false)
          CommonStore.update(s => {
            s.alertObj = null;
          });
        }}
        alertContainerStyle={{
          zIndex: 100,
        }}
      />

      <AppNavigator />

      {(cartIcon && currPage !== 'Cart') ?
        <Draggable
          shouldReverse={false}
          renderSize={100}
          // renderColor={Colors.secondaryColor}
          isCircle
          x={270}
          y={470}
          // onShortPressRelease={() => { goToCart(), cartCount() }}
          onShortPressRelease={onCartClicked}
        >
          <View style={{
            width: 60,
            height: 60,
            backgroundColor: Colors.secondaryColor,
            borderRadius: 30,
            justifyContent: "center",
            // backgroundColor: 'red',
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 1,
            },
            shadowOpacity: 0.22,
            shadowRadius: 2.22,
            elevation: 5,
          }}>
            <View style={{ alignSelf: "center" }}>
              <Ionicons name="cart-outline" size={42} color={Colors.mainTxtColor} />
            </View>
            <View style={styles.cartCount}>
              <Text style={{
                color: Colors.whiteColor,
                fontSize: 14,
                fontFamily: "NunitoSans-Bold"
              }}>{cartItems.length}</Text>
            </View>
          </View>
        </Draggable>
        : <></>
      }
    </View>
  );
};

const styles = StyleSheet.create({
  cartCount: {
    position: 'absolute',
    top: -12,
    right: -10,
    backgroundColor: Colors.primaryColor,
    width: 30,
    height: 30,
    borderRadius: 30 / 2,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default App;
