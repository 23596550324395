import React, { Component, useEffect, useState } from "react";
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  TouchableOpacity,
  Alert,
  TextInput,
  Modal,
  Dimensions, ActivityIndicator
} from "react-native";
import Colors from "../constant/Colors";
import ApiClient from "../util/ApiClient";
import API from "../constant/API";
import AntDesign from "react-native-vector-icons/AntDesign";
import Icon from "react-native-vector-icons/Ionicons";
import Ionicons from "react-native-vector-icons/Ionicons";
import * as Cart from "../util/Cart";
import Styles from "../constant/Styles";
import Icons from "react-native-vector-icons/Feather";
import Entypo from "react-native-vector-icons/Entypo";
import Close from "react-native-vector-icons/AntDesign";
// import NumericInput from "react-native-numeric-input";
import Draggable from "react-native-draggable";
import Icon1 from "react-native-vector-icons/Feather";
import { CommonStore } from "../store/commonStore";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { UserStore } from "../store/userStore";
import AsyncImage from "../components/asyncImage";
import { useLinkTo } from "@react-navigation/native";
import { prefix } from "../constant/env";
import { ORDER_TYPE } from "../constant/common";
import { isMobile } from "../util/commonFuncs";
import { DataStore } from "../store/dataStore";
/**
 * MenuItemDetailScreen
 * function
 * *select the quantity, size, add-on, variation and remarks of the item
 * 
 * route.params
 * *outletData: array of data of the current outlet
 * *menuItem: data of the current item
 * *orderType: type of current order (unused?)
 * *refresh: boolean value to determine if page need refreshing
 * *cartItem: data of items currently in cart
 */

// var quantity2;
var total;
// var options;
var refreshAction = null;

const MenuItemDetailsScreen = props => {
  const {
    navigation,
    route,
  } = props;

  // const { orderType, refresh } = route.params;
  // const outletDataParam = route.params.outletData;
  // const menuItemParam = route.params.menuItem;
  // const cartItemParam = route.params.cartItem;

  const linkTo = useLinkTo();

  navigation.setOptions({
    headerLeft: () => (
      <TouchableOpacity style={{
      }} onPress={() => {
        // props.navigation.goBack();
        linkTo(`${prefix}/outlet/menu`);
      }}>
        <View style={{
          marginLeft: 10,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}>
          <Icon
            name="chevron-back"
            size={26}
            color={Colors.fieldtTxtColor}
            style={{
            }}
          />

          <Text
            style={{
              color: Colors.fieldtTxtColor,
              fontSize: 16,
              textAlign: 'center',
              fontFamily: 'NunitoSans-Regular',
              lineHeight: 22,
              marginTop: -1,
            }}>
            Back
          </Text>
        </View>
      </TouchableOpacity>
    ),
    headerRight: () => (
      <TouchableOpacity onPress={() => {
        // props.navigation.navigate('Profile')
      }} style={{
        opacity: 0,
      }}>
        <View style={{ marginRight: 15 }}>
          <Icon name="menu" size={30} color={Colors.primaryColor} />
        </View>
      </TouchableOpacity>
    ),
    headerTitle: () => (
      <View style={{
        justifyContent: 'center',
        alignItems: 'center',
        bottom: -1,
      }}>
        {selectedOutlet &&
          <Text
            style={{
              fontSize: 20,
              lineHeight: 25,
              textAlign: 'center',
              fontFamily: 'NunitoSans-Bold',
              color: Colors.mainTxtColor,
            }}
          >
            {selectedOutlet.name}
          </Text>
        }
      </View>
    ),
  });

  // const [menuItem, setMenuItem] = useState(menuItemParam);
  // const [totalState, setTotalState] = useState(menuItemParam.price);
  // const [totalState, setTotalState] = useState(0);

  const [remark, setRemark] = useState('');
  // const [outletData, setOutletData] = useState(outletDataParam);
  const [visible, setVisible] = useState(false);
  const [optional, setOptional] = useState(0);
  const [optional1, setOptional1] = useState(1);
  const [quantity1, setQuantity1] = useState(1);
  const [qty, setQty] = useState(null);
  const [detail, setDetail] = useState([]);
  // const [cartItem, setCartItem] = useState([]);
  const [choice, setChoice] = useState(null);
  // const [cartItem, setCartItem] = useState(cartItemParam);
  const [size, setSize] = useState("small");
  const [clicked, setClicked] = useState(1);
  const [clicked1, setClicked1] = useState(0);
  const [clicked2, setClicked2] = useState(0);
  const [cartIcon, setCartIcon] = useState(false);
  const [expandChoice, setExpandChoice] = useState(false);

  // const [quantity2, setQuantity2] = useState(cartItem ? cartItem.options : []);
  // const [options, setOptions] = useState(cartItem ? cartItem.quantity : quantity);

  const [quantity, setQuantity] = useState(1);

  const [totalPrice, setTotalPrice] = useState(0);
  const [addOnPrice, setAddOnPrice] = useState(0);

  const firebaseUid = UserStore.useState(s => s.firebaseUid);

  const selectedOutlet = CommonStore.useState(s => s.selectedOutlet);
  const outletsItemAddOnDict = CommonStore.useState(s => s.outletsItemAddOnDict);
  const outletsItemAddOnChoiceDict = CommonStore.useState(s => s.outletsItemAddOnChoiceDict);
  const selectedOutletItem = CommonStore.useState(s => s.selectedOutletItem);
  const selectedOutletItemAddOn = CommonStore.useState(s => s.selectedOutletItemAddOn);
  const selectedOutletItemAddOnChoice = CommonStore.useState(s => s.selectedOutletItemAddOnChoice);

  const selectedOutletItemCategoriesDict = CommonStore.useState(s => s.selectedOutletItemCategoriesDict);

  const cartItems = CommonStore.useState(s => s.cartItems);
  const cartItemChoices = CommonStore.useState(s => s.cartItemChoices);

  const onUpdatingCartItem = CommonStore.useState(s => s.onUpdatingCartItem);
  const userCart = CommonStore.useState(s => s.userCart);

  const orderType = CommonStore.useState(s => s.orderType);

  const selectedOutletTableId = CommonStore.useState(s => s.selectedOutletTableId);
  const selectedOutletTablePax = CommonStore.useState(s => s.selectedOutletTablePax);
  const selectedOutletWaiterId = CommonStore.useState(s => s.selectedOutletWaiterId);

  const overrideItemPriceSkuDict = CommonStore.useState(s => s.overrideItemPriceSkuDict);
  const amountOffItemSkuDict = CommonStore.useState(s => s.amountOffItemSkuDict);

  const overrideCategoryPriceNameDict = CommonStore.useState(s => s.overrideCategoryPriceNameDict);
  const amountOffCategoryNameDict = CommonStore.useState(s => s.amountOffCategoryNameDict);

  //////////////////////////////////////////////////////////////////////////////////////

  const [newSelectedOutletItemAddOn, setNewSelectedOutletItemAddOn] = useState({});
  const [newSelectedOutletItemAddOnDetails, setNewSelectedOutletItemAddOnDetails] = useState({});

  //////////////////////////////////////////////////////////////////////////////////////

  const setState = () => { };

  useEffect(() => {
    if (orderType === ORDER_TYPE.DINEIN && userCart.uniqueId === undefined) {
      linkTo(`${prefix}/scan`);
    }
  }, [userCart, orderType]);

  useEffect(() => {
    if (linkTo) {
        DataStore.update(s => {
            s.linkToFunc = linkTo;
        });   
    }
}, [linkTo]);

  useEffect(() => {
    if (onUpdatingCartItem) {
      setQuantity(onUpdatingCartItem.quantity);

      return () => {
        CommonStore.update(s => {
          s.onUpdatingCartItem = null;
        });
      }
    }
  }, [onUpdatingCartItem]);

  useEffect(() => {
    if (selectedOutletItem && selectedOutletItem.price) {
      var overrideCategoryPrice = undefined;
      if (selectedOutletItemCategoriesDict[selectedOutletItem.categoryId] && overrideCategoryPriceNameDict[selectedOutletItemCategoriesDict[selectedOutletItem.categoryId].name] !== undefined) {
        overrideCategoryPrice = overrideCategoryPriceNameDict[selectedOutletItemCategoriesDict[selectedOutletItem.categoryId].name];
      }

      if (overrideItemPriceSkuDict[selectedOutletItem.sku] !== undefined) {
        setTotalPrice(overrideItemPriceSkuDict[selectedOutletItem.sku]);
      }
      else if (overrideCategoryPrice !== undefined) {
        setTotalPrice(overrideCategoryPrice);
      }
      else {
        setTotalPrice(selectedOutletItem.price);
      }
    }
  }, [selectedOutletItem, overrideItemPriceSkuDict, overrideCategoryPriceNameDict]);

  useEffect(() => {
    const addOnList = Object.entries(selectedOutletItemAddOn).map(([key, value]) => ({ key: key, value: value }));

    var addOnPriceTemp = 0;

    for (var i = 0; i < addOnList.length; i++) {
      var isValid = false;

      if (outletsItemAddOnDict[selectedOutletItem.uniqueId] && outletsItemAddOnDict[selectedOutletItem.uniqueId].length > 0) {
        for (var j = 0; j < outletsItemAddOnDict[selectedOutletItem.uniqueId].length; j++) {
          if (outletsItemAddOnDict[selectedOutletItem.uniqueId][j].outletItemId === selectedOutletItem.uniqueId) {
            isValid = true;
          }
        }
      }

      if (isValid && addOnList[i].value) {
        const addOnChoiceList = Object.entries(addOnList[i].value).map(([key, value]) => ({ key: key, value: value }));

        for (var j = 0; j < addOnChoiceList.length; j++) {
          // console.log(addOnChoiceList[j].value);
          // console.log(outletsItemAddOnChoiceDict[addOnList[i].key]);
          // console.log(outletsItemAddOnChoiceDict[addOnList[i].key][addOnChoiceList[j].key]);

          if (addOnChoiceList[j].value) {
            const actualAddOnChoiceList = outletsItemAddOnChoiceDict[addOnList[i].key];

            if (actualAddOnChoiceList && actualAddOnChoiceList.length > 0) {
              for (var k = 0; k < actualAddOnChoiceList.length; k++) {
                if (addOnChoiceList[j].key === actualAddOnChoiceList[k].uniqueId) {
                  // add this addon price

                  addOnPriceTemp += actualAddOnChoiceList[k].price;
                }
              }
            }
          }
        }
      }
    }

    //////////////////////////////////////////

    // const newSelectedOutletItemAddOnList = Object.entries(newSelectedOutletItemAddOn).map(([key, value]) => ({ key: key, value: value }));

    // for (var i = 0; i < newSelectedOutletItemAddOnList.length; i++) {
    //   if (newSelectedOutletItemAddOnList[i].value) {
    //     const addOnTemp = newSelectedOutletItemAddOnDetails[newSelectedOutletItemAddOnList[i].key];

    //     if (addOnTemp) {
    //       addOnPriceTemp += addOnTemp.quantity * addOnTemp.price;
    //     }
    //   }
    // }

    // const newSelectedOutletItemAddOnList = Object.entries(newSelectedOutletItemAddOn).map(([key, value]) => ({ key: key, value: value }));
    const newSelectedOutletItemAddOnList = Object.entries(newSelectedOutletItemAddOnDetails).map(([key, value]) => ({ key: key, value: value }));

    for (var i = 0; i < newSelectedOutletItemAddOnList.length; i++) {
      if (newSelectedOutletItemAddOnList[i].value) {
        const addOnTemp = newSelectedOutletItemAddOnDetails[newSelectedOutletItemAddOnList[i].key];

        if (addOnTemp) {
          addOnPriceTemp += addOnTemp.quantity * addOnTemp.price;
        }
      }
    }

    //////////////////////////////////////////

    setAddOnPrice(addOnPriceTemp);
  }, [
    selectedOutletItemAddOn,
    selectedOutletItem,
    outletsItemAddOnChoiceDict,
    outletsItemAddOnDict,

    newSelectedOutletItemAddOn,
    newSelectedOutletItemAddOnDetails,
  ]);

  useEffect(() => {
    // options = cartItem ? cartItem.options : [];
    // quantity2 = cartItem ? cartItem.quantity : quantity;
    // total = cartItem
    //   ? setState({ total: cartItem.price })
    //   : total;
    // refreshAction = refresh;

    // ApiClient.GET(API.getItemAddOnChoice + menuItem.id).then(
    //   (result) => {
    //     setState({ menuItemDetails: result });
    //   }
    // );

    // setInterval(() => {
    //   cartCount();
    // }, 5000);

    cartCount();
  }, []);

  /////////////////////////////////////////////////////////////////

  // useEffect(() => {
  //   if (selectedOutletItem && selectedOutletItem.uniqueId) {
  //     for (var i = 0; i < outletsItemAddOnDict[selectedOutletItem.uniqueId].length; i++) {
  //       const addOn = outletsItemAddOnDict[selectedOutletItem.uniqueId][i];

  //       for (var j = 0; j < outletsItemAddOnChoiceDict[addOn.uniqueId]; j++) {
  //         if ()
  //       }
  //     }
  //   }
  // }, [selectedOutletItem, outletsItemAddOnDict])

  const isLoading = CommonStore.useState(s => s.isLoading);

  const [addOnVerified, setAddOnVerified] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      if (selectedOutletItem && selectedOutletItem.uniqueId) {
        const addOnList = outletsItemAddOnDict[selectedOutletItem.uniqueId];

        if (addOnList && addOnList.length > 0) {
          // got addons

          var resultList = [];

          for (var i = 0; i < addOnList.length; i++) {
            if (addOnList[i].minSelect !== undefined && addOnList[i].maxSelect !== undefined) {
              var result = false;

              const addOnId = addOnList[i].uniqueId;
              const minSelect = addOnList[i].minSelect;
              const maxSelect = addOnList[i].maxSelect;

              if (selectedOutletItemAddOn[addOnId]) {
                const selectedOutletItemAddOnValueList = Object.entries(selectedOutletItemAddOn[addOnId]).map(([key, value]) => (value));

                const selectedCount = selectedOutletItemAddOnValueList.filter(value => value === true).length;

                if (selectedCount >= minSelect && selectedCount <= maxSelect) {
                  result = true;
                }
              }

              resultList.push(result);
            }
            else {
              resultList.push(true);
            }
          }

          setAddOnVerified(resultList.filter(result => result === false).length === 0);
        }
        else {
          setAddOnVerified(true);
        }
      }
    }
  }, [isLoading, selectedOutletItem, outletsItemAddOnDict, outletsItemAddOnChoiceDict, selectedOutletItemAddOn]);

  /////////////////////////////////////////////////////////////////

  const expandChoiceFunc = (param) => {
    if (expandChoice == false) {
      return setState({ expandChoice: true }), param.expandChoice = true;
    } else {
      return setState({ expandChoice: false }), param.expandChoice = false;
    }
  }

  const cartCount = () => {
    if (Cart.getCartItem().length > 0) {
      setState({ cartIcon: true })
    }
    else {
      setState({ cartIcon: false })
    }
  }

  const changeClick = () => {
    if (clicked == 1) {
      setState({ clicked: 0 })
    }
    else {
      setState({ clicked: 1, clicked1: 0, clicked2: 0, size: "small" })
    }
  }

  const changeClick1 = () => {
    if (clicked1 == 1) {
      setState({ clicked1: 0 })
    }
    else {
      setState({ clicked1: 1, clicked: 0, clicked2: 0, size: "medium" })
    }
  }
  const changeClick2 = () => {
    if (clicked2 == 1) {
      setState({ clicked2: 0 })
    }
    else {
      setState({ clicked2: 1, clicked: 0, clicked1: 0, size: "big" })
    }
  }

  const getCartItem = () => {
    // setState({ cartItem: Cart.getCartItem() });
  }

  // function here
  const addToCart = () => {
    // Cart.setTax(5);
    // if (Cart.getOutletId() != outletData.id) {
    //   Cart.setOutletId(outletData.id);
    //   Cart.clearCart();
    // }
    // options.forEach((element, index) => {
    //   if (element.quantity == 0) options.splice(index, 1);
    // });
    // var data = {
    //   itemId: menuItem.id,
    //   quantity: quantity,
    //   remarks: remark,
    //   options: options,
    //   image: menuItem.image,
    //   name: menuItem.name,
    //   price: parseFloat(totalState),
    //   fireOrder: optional,
    //   menuItem: menuItem,
    //   size: size
    // };
    // Cart.setCartItem(data);
    // Cart.setOutletId(outletData.id);
    // setState({ refresh: true });
    // refreshAction();
    // Cart.getRefreshCartPage();
  }

  const showPrice = () => {
    // if (price == false) {
    //   setState({ price: true });
    // } else {
    //   setState({ price: false });
    // }
  }

  const addToCart1 = () => {
    // Cart.setTax(5);
    // if (Cart.getOutletId() != outletData.id) {
    //   Cart.setOutletId(outletData.id);
    //   Cart.clearCart();
    // }
    // options.forEach((element, index) => {
    //   if (element.quantity == 0) options.splice(index, 1);
    // });
    // var data = {
    //   itemId: menuItem.id,
    //   quantity: quantity,
    //   remark: remark,
    //   options: options,
    //   image: menuItem.image,
    //   name: menuItem.name,
    //   price: parseFloat(totalState),
    //   fireOrder: optional1,
    //   menuItem: menuItem,
    // };
    // Cart.setCartItem(data);
    // Cart.setOutletId(outletData.id);
    // setState({ refresh: true });
    // refreshAction();
    // Cart.getRefreshCartPage();
  }

  const containsObject = (id) => {
    // var i;
    // for (i = 0; i < options.length; i++) {
    //   if (options[i].choice === id) {
    //     return true;
    //   }
    // }
    // return false;
  }

  const addLeastItem = (name, choiceId, price, cat) => {
    // var i;
    // var total = 0;
    // var prevChoice = 0;
    // for (i = 0; i < options.length; i++) {
    //   if (options[i].least == true && options[i].cat == cat) {
    //     prevChoice = options[i].choice
    //     total =
    //       parseFloat(totalState) -
    //       options[i].price * quantity1;
    //     options.splice(i, 1);
    //   }
    // }
    // if (prevChoice != choiceId) {
    //   var choice = {
    //     choice: choiceId,
    //     quantity1: 1,
    //     itemName: name,
    //     least: true,
    //     cat: cat,
    //     price: price
    //   };
    //   // setState({
    //   //   total:
    //   //     (total != 0 ? total : parseFloat(total)) +
    //   //     parseFloat(price) * quantity1,
    //   // });
    //   setTotalState((total != 0 ? total : parseFloat(totalState)) +
    //     parseFloat(price) * quantity1);
    //   options.push(choice);
    // } else {
    //   // setState({ total: total })
    //   setTotalState(total)
    // }
    // setState({ refresh: true });
  }

  const addOption = (name, quantity, choiceId, price) => {
    // if (quantity > 0) {
    //   if (!containsObject(choiceId, options)) {
    //     var choice = {
    //       choice: choiceId,
    //       quantity: 1,
    //       itemName: "Add " + name,
    //       least: false,
    //       price: price,
    //     };
    //     // setState({
    //     //   total: parseFloat(totalState) + parseFloat(price),
    //     // });
    //     setState(parseFloat(totalState) + parseFloat(price));
    //     options.push(choice);
    //   } else {
    //     var i;
    //     for (i = 0; i < options.length; i++) {
    //       if (options[i].choice === choiceId) {
    //         options[i].quantity = options[i].quantity + 1;
    //         // setState({
    //         //   total: parseFloat(total) + parseFloat(price),
    //         // });
    //         setState(parseFloat(totalState) + parseFloat(price));
    //       }
    //     }
    //   }
    // } else {
    //   var i;
    //   for (i = 0; i < options.length; i++) {
    //     if (options[i].choice === choiceId) {
    //       if (options[i].quantity > 0) {
    //         options[i].quantity = options[i].quantity + quantity;
    //         if (options[i] - 1 == 0) {
    //           options.splice(i, 1);
    //         }
    //         // setState({
    //         //   total: parseFloat(total) - parseFloat(price),
    //         // });
    //         setState(parseFloat(totalState) + parseFloat(price));
    //       } else {
    //         options.splice(i, 1);
    //       }
    //     }
    //   }
    // }
  }

  const getQuantity = (itemId) => {
    // var i;
    // for (i = 0; i < cartItem.length; i++) {
    //   if (cartItem[i].itemId === itemId) {
    //     quantity = options[i].quantity;
    //   }
    // }
    // return quantity;
  }

  const getOptionQuantity = (choiceId) => {
    // var quantity = 0;
    // var i;
    // for (i = 0; i < options.length; i++) {
    //   if (options[i].choice === choiceId) {
    //     quantity = options[i].quantity;
    //   }
    // }
    // return quantity;
  }

  const quantityFunc = () => {
    // if (quantity == null) {
    //   setState({ quantity: 1 });
    //   return quantity
    // } else if (cartItem != null) {
    //   return quantity2;
    // } else {
    //   return quantity;
    // }
  }
  // onChangeQty(e, id) {
  //   const cartItem = cartItem;
  //   console.log(cartItem);
  //   const item = cartItem.find((obj) => obj.itemId === id);
  //   item.quantity = e;
  //   setState({
  //     cartItem,
  //   });
  // }

  const goToCart = () => {
    if (Cart.getCartItem().length > 0) {
      props.navigation.navigate('Cart', {
        test: test,
        // outletData: outletData,
        // menuItem: menuItem,
        clicked: clicked,
        clicked1: clicked1,
        clicked2: clicked2,
      })
    } else {
      Alert.alert("Info", "No item in your cart at the moment", [
        { text: "OK", onPress: () => { } }
      ],
        { cancelable: false })
    }
  }

  const lessqty = (id) => {
    // if (cartItem != null) {
    //   const cartItem = cartItem;
    //   cartItem.quantity = cartItem.quantity - 1;
    //   save();
    // } else {
    //   return setState({ quantity: quantity - 1 });
    // }
  }

  const addqty = (id) => {
    // if (cartItem != null) {
    //   const cartItem = cartItem;
    //   cartItem.quantity = cartItem.quantity + 1;
    //   save();
    // } else {
    //   return setState({ quantity: quantity + 1 });
    // }
  }

  const totals = () => {
    // if (cartItem != null) {
    //   return totalState;
    // } else {
    //   return totalState;
    // }
  }

  const save = () => {
    // if (cartItem != null) {
    //   return goToCart();
    // } else {
    //   return setState({ visible: true });
    // }
  }

  const updateUserCart = async (newCartItems) => {
    const body = {
      userId: firebaseUid,
      outletId: selectedOutlet.uniqueId,
      tableId: selectedOutletTableId,
      tablePax: selectedOutletTablePax,
      cartItems: newCartItems,

      waiterId: selectedOutletWaiterId,
    };

    ApiClient.POST(API.updateUserCart, body).then((result) => {
      if (result && result.status === 'success') {
        console.log('ok');
      }
    });
  };

  // function end

  var overrideCategoryPrice = undefined;
  if (selectedOutletItemCategoriesDict[selectedOutletItem.categoryId] && overrideCategoryPriceNameDict[selectedOutletItemCategoriesDict[selectedOutletItem.categoryId].name] !== undefined) {
    overrideCategoryPrice = overrideCategoryPriceNameDict[selectedOutletItemCategoriesDict[selectedOutletItem.categoryId].name];
  }

  return (
    <View style={[styles.container, {
      // flex: 1
      width: Dimensions.get('window').width,
      height: Dimensions.get('window').height,
    }]}>
      <ScrollView style={[{
        flex: 1,
      }]}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 5,
        }}>
        {/* <View
          style={{
            flexDirection: "row",
            flex: 1,
            alignContent: "center",
            alignItems: "center",
            marginBottom: 30,
          }}
        >
          <View
            style={[{
              backgroundColor: Colors.secondaryColor,
              width: 60,
              height: 60,
              borderRadius: 10,
            }, menuItem.image ? {

            } : {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }]}
          >
            {menuItem.image
              ?
              <Image
                source={{ uri: menuItem.image }}
                style={{ width: 60, height: 60, borderRadius: 10 }}
              />
              :
              <Icon name="fast-food-outline" size={45} />
            }
          </View>

          <View style={{ marginLeft: 15, width: "80%" }}>
            <Text
              style={{
                fontWeight: "600",
                fontSize: 16,
                textTransform: "uppercase",
              }}
            >
              {menuItem.name}
            </Text>
            <Text
              style={{
                color: Colors.primaryColor,
                fontWeight: "700",
                paddingTop: 5,
                fontSize: 12,
              }}>
              RM{parseFloat(menuItem.price).toFixed(2)}
            </Text>
          </View>
        </View> */}

        <View style={{
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
          width: "75%",
          display: 'flex',
          justifyContent: 'flex-start',
          // backgroundColor: 'blue',
          marginBottom: 20,
        }}>
          <View style={[{
            backgroundColor: Colors.secondaryColor,
            // width: 60,
            // height: 60,
            width: isMobile() ? Dimensions.get('window').width * 0.22 : Dimensions.get('window').width * 0.05,
            height: isMobile() ? Dimensions.get('window').width * 0.22 : Dimensions.get('window').width * 0.05,
            borderRadius: 10,
          }, selectedOutletItem && selectedOutletItem.image ? {

          } : {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }]}>
            {selectedOutletItem && selectedOutletItem.image
              ?
              <AsyncImage source={{ uri: selectedOutletItem.image }} style={{
                // width: 60,
                // height: 60,
                width: isMobile() ? Dimensions.get('window').width * 0.22 : Dimensions.get('window').width * 0.05,
                height: isMobile() ? Dimensions.get('window').width * 0.22 : Dimensions.get('window').width * 0.05,
                borderRadius: 10
              }} />
              :
              <Ionicons name="fast-food-outline" size={50} />
            }
          </View>
          <View style={{
            marginLeft: 14,
            // flexDirection: 'row',
            // flexShrink: 1,
            width: '90%',
            // backgroundColor: 'red',
          }}>
            <Text
              // numberOfLines={1} 
              style={{
                fontSize: 15,
                textTransform:
                  'uppercase',
                fontFamily: "NunitoSans-Bold",
                // flexWrap: 'wrap',
                // flex: 1,
                // flexShrink: 1,    
                // width: '100%',
              }}>{selectedOutletItem && selectedOutletItem.name ? selectedOutletItem.name : ''}</Text>

            <Text style={{
              color: Colors.primaryColor,
              fontFamily: "NunitoSans-Bold",
              paddingTop: 5,
              fontSize: 16,
            }}>RM{
                selectedOutletItem && selectedOutletItem.sku && (overrideItemPriceSkuDict[selectedOutletItem.sku] !== undefined ||
                  overrideCategoryPrice !== undefined)
                  ?
                  parseFloat(overrideItemPriceSkuDict[selectedOutletItem.sku] || overrideCategoryPrice).toFixed(2)
                  :
                  parseFloat(selectedOutletItem.price).toFixed(2)
              }</Text>
          </View>
        </View>

        {/* <View style={{ marginBottom: 15 }}>
          <Text style={{ fontWeight: "700", fontSize: 16 }}>
            Choose size
              </Text>
          <View style={{ marginTop: 15, flexDirection: "row" }}>
            <TouchableOpacity style={{
              width: "30%", height: 40, backgroundColor: clicked == 1 ? Colors.primaryColor : Colors.whiteColor, justifyContent: 'center', borderRadius: 20, borderWidth: 1,
              borderColor: Colors.descriptionColor,
            }}
              onPress={() => { changeClick() }}>
              <Text style={{ alignSelf: "center", color: clicked == 1 ? Colors.whiteColor : Colors.descriptionColor }}>Small</Text>
            </TouchableOpacity>

            <View style={{ width: '5%' }}></View>

            <TouchableOpacity style={{
              width: "30%", height: 40, backgroundColor: clicked1 == 1 ? Colors.primaryColor : Colors.whiteColor, justifyContent: 'center', borderRadius: 20, borderWidth: 1,
              borderColor: Colors.descriptionColor,
            }}
              onPress={() => { changeClick1() }}>
              <Text style={{ alignSelf: "center", color: clicked1 == 1 ? Colors.whiteColor : Colors.descriptionColor }}>Medium</Text>
            </TouchableOpacity>

            <View style={{ width: '5%' }}></View>

            <TouchableOpacity style={{
              width: "30%", height: 40, backgroundColor: clicked2 == 1 ? Colors.primaryColor : Colors.whiteColor, justifyContent: 'center', borderRadius: 20, borderWidth: 1,
              borderColor: Colors.descriptionColor,
            }}
              onPress={() => { changeClick2() }}>
              <Text style={{ alignSelf: "center", color: clicked2 == 1 ? Colors.whiteColor : Colors.descriptionColor }}>Big</Text>
            </TouchableOpacity>
          </View>
        </View> */}

        {isLoading ?
          <View>
            <ActivityIndicator color={Colors.primaryColor} size={"large"} />
          </View>
          :
          <>
            {outletsItemAddOnDict[selectedOutletItem.uniqueId]
              ? outletsItemAddOnDict[selectedOutletItem.uniqueId].map((item, index) => {
                // console.log('outletsItemAddOnDict');
                // console.log(outletsItemAddOnDict);
                // console.log('outletsItemAddOnChoiceDict');
                // console.log(outletsItemAddOnChoiceDict);

                if (item.minSelect !== undefined && item.maxSelect !== undefined) {
                  // means is variant

                  return (
                    (item.name !== "" ? <View style={{ marginBottom: 15 }}>
                      <Text
                        style={{
                          color: Colors.descriptionColor,
                          // fontWeight: "500",
                          // fontSize: 16,
                          marginBottom: 0,
                          color: Colors.descriptionColor,
                          // color: Colors.mainTxtColor,
                          // fontWeight: "500",
                          fontSize: 17,
                          fontFamily: 'NunitoSans-SemiBold',
                        }}
                      >
                        {item.name}
                      </Text>

                      <View style={{ marginTop: 10, flexDirection: "row" }}>
                        {outletsItemAddOnChoiceDict[item.uniqueId] && outletsItemAddOnChoiceDict[item.uniqueId].map((item2, index) => {
                          return (
                            <>
                              <TouchableOpacity style={{
                                width: "40%",
                                height: 40,
                                backgroundColor: selectedOutletItemAddOnChoice[item2.uniqueId] ? Colors.primaryColor : Colors.whiteColor, justifyContent: 'center', borderRadius: 20, borderWidth: 1,
                                borderColor: selectedOutletItemAddOnChoice[item2.uniqueId] ? 'transparent' : Colors.descriptionColor,
                                // borderColor: Colors.descriptionColor,
                              }}
                                onPress={() => {
                                  /////////////////////////////////
                                  // minSelect/maxSelect calculations

                                  var selectedOutletItemAddOnChoiceRemoved = {};

                                  if (item.minSelect === 1 && item.maxSelect === 1) {
                                    // means can only choose 1 addon choice, the others need removed

                                    for (var i = 0; i < outletsItemAddOnChoiceDict[item.uniqueId].length; i++) {
                                      selectedOutletItemAddOnChoiceRemoved[outletsItemAddOnChoiceDict[item.uniqueId][i].uniqueId] = false;
                                    }
                                  }

                                  /////////////////////////////////

                                  var chooseValue = false;
                                  if (selectedOutletItemAddOnChoice[item2.uniqueId] === undefined ||
                                    selectedOutletItemAddOnChoice[item2.uniqueId] === false) {
                                    chooseValue = true;
                                  }
                                  else {
                                    chooseValue = false;
                                  }

                                  CommonStore.update(s => {
                                    s.selectedOutletItemAddOnChoice = {
                                      ...selectedOutletItemAddOnChoice,

                                      ...selectedOutletItemAddOnChoiceRemoved,

                                      [item2.uniqueId]: chooseValue,
                                    };

                                    if (selectedOutletItemAddOn[item.uniqueId] === undefined) {
                                      s.selectedOutletItemAddOn = {
                                        ...selectedOutletItemAddOn,
                                        // [item.uniqueId]: new Set(),
                                        [item.uniqueId]: {},
                                      };
                                    }

                                    s.selectedOutletItemAddOn = {
                                      ...selectedOutletItemAddOn,
                                      [item.uniqueId]: {
                                        ...(selectedOutletItemAddOn[item.uniqueId]),

                                        ...selectedOutletItemAddOnChoiceRemoved,

                                        [item2.uniqueId]: chooseValue,
                                      },
                                    };

                                    console.log({
                                      ...selectedOutletItemAddOn,
                                      [item.uniqueId]: {
                                        ...(selectedOutletItemAddOn[item.uniqueId]),

                                        ...selectedOutletItemAddOnChoiceRemoved,

                                        [item2.uniqueId]: chooseValue,
                                      },
                                    });
                                  });
                                }}>
                                <Text style={{
                                  alignSelf: "center",
                                  color: selectedOutletItemAddOnChoice[item2.uniqueId] ? Colors.whiteColor : Colors.descriptionColor,
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: 14,
                                }}>{`${item2.name} (RM${item2.price.toFixed(2)})`}</Text>
                              </TouchableOpacity>

                              <View style={{ width: '5%' }}></View>
                            </>
                          );
                        })}
                      </View>
                    </View> : null)
                  );
                }
                else {
                  // means is addon

                  return (
                    (item.name !== "" ? <View style={{ marginBottom: 15 }}>
                      <Text
                        style={{
                          color: Colors.descriptionColor,
                          // fontWeight: "500",
                          // fontSize: 16,
                          marginBottom: 0,
                          color: Colors.descriptionColor,
                          // color: Colors.mainTxtColor,
                          // fontWeight: "500",
                          fontSize: 17,
                          fontFamily: 'NunitoSans-SemiBold',
                        }}
                      >
                        {item.name}
                      </Text>

                      <View style={{ marginTop: 10, flexDirection: "column" }}>
                        {outletsItemAddOnChoiceDict[item.uniqueId] && outletsItemAddOnChoiceDict[item.uniqueId].map((item2, index) => {
                          return (
                            <View style={{
                              width: '100%',
                              flexDirection: 'row',
                              alignItems: 'center',
                              marginTop: 10,
                              marginBottom: 10,
                              justifyContent: 'space-between',
                            }}>
                              <View style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                width: '45%',
                                // backgroundColor: 'red',
                              }}>
                                {/* <TouchableOpacity
                                  underlayColor="transparent"
                                  onPress={() => {
                                    setNewSelectedOutletItemAddOn({
                                      ...newSelectedOutletItemAddOn,
                                      [item2.uniqueId]: !newSelectedOutletItemAddOn[item2.uniqueId],
                                    });
                                  }}>
                                  <View
                                    style={[
                                      {
                                        borderWidth: StyleSheet.hairlineWidth,
                                        borderColor: Colors.descriptionColor,
                                        width: 26,
                                        height: 26,
                                        borderRadius: 8,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      },
                                      {
                                        backgroundColor: newSelectedOutletItemAddOn[item2.uniqueId]
                                          ? Colors.primaryColor
                                          : null,
                                      },
                                    ]}>
                                    <AntDesign name="check" size={18} color="#ffffff" />
                                  </View>
                                </TouchableOpacity> */}

                                <Text style={{
                                  fontFamily: 'NunitoSans-Bold',
                                  fontSize: 14,
                                  // marginLeft: 15,
                                }}>
                                  {item2.name}
                                </Text>
                              </View>

                              <View style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                // backgroundColor: 'red',                                
                                justifyContent: 'space-between',
                                // width: Dimensions.get('window').width * 0.4,
                                width: '50%',
                              }}>
                                <View style={{ flexDirection: "row", width: '40%' }}>
                                  <TouchableOpacity
                                    onPress={() => {
                                      // if (quantity - 1 > 0) {
                                      //   lessqty(menuItem.id);
                                      // }

                                      // setQuantity(quantity - 1 >= 0 ? quantity - 1 : 0);

                                      var quantityTemp = 0;

                                      if (newSelectedOutletItemAddOnDetails[item2.uniqueId]) {
                                        quantityTemp = newSelectedOutletItemAddOnDetails[item2.uniqueId].quantity;

                                        quantityTemp = quantityTemp - 1 >= 0 ? quantityTemp - 1 : 0;

                                        setNewSelectedOutletItemAddOnDetails({
                                          ...newSelectedOutletItemAddOnDetails,
                                          [item2.uniqueId]: {
                                            ...newSelectedOutletItemAddOnDetails[item2.uniqueId],
                                            quantity: quantityTemp,
                                            price: item2.price,

                                            outletItemAddOnChoiceId: item2.uniqueId,
                                            outletItemAddOnId: item.uniqueId,

                                            choiceName: item2.name,
                                            addOnName: item.name,

                                            minSelect: item2.minSelect,
                                            maxSelect: item2.maxSelect,
                                          }
                                        });
                                      }
                                      else {
                                        setNewSelectedOutletItemAddOnDetails({
                                          ...newSelectedOutletItemAddOnDetails,
                                          [item2.uniqueId]: {
                                            quantity: quantityTemp,
                                            price: item2.price,

                                            outletItemAddOnChoiceId: item2.uniqueId,
                                            outletItemAddOnId: item.uniqueId,

                                            choiceName: item2.name,
                                            addOnName: item.name,

                                            minSelect: item2.minSelect,
                                            maxSelect: item2.maxSelect,
                                          }
                                        });
                                      }
                                    }}>
                                    <View
                                      style={[
                                        styles.addBtn,
                                        {
                                          backgroundColor: Colors.descriptionColor,

                                          width: 20,
                                          height: 22,
                                        },
                                      ]}
                                    >
                                      <Text
                                        style={{
                                          fontSize: 15,
                                          fontWeight: "500",
                                          color: Colors.whiteColor,
                                        }}
                                      >
                                        -
                                      </Text>
                                    </View>
                                  </TouchableOpacity>

                                  <View
                                    style={[
                                      styles.addBtn,
                                      {
                                        backgroundColor: Colors.whiteColor,
                                        borderWidth: StyleSheet.hairlineWidth,
                                        borderColor: Colors.descriptionColor,
                                        borderWidth: 1.5,

                                        width: 25,
                                        height: 22,
                                      },
                                    ]}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 13,
                                        // fontWeight: "bold",
                                        fontFamily: 'NunitoSans-Bold',
                                        // color: Colors.primaryColor,
                                        color: Colors.descriptionColor,
                                        top: -1,
                                      }}>
                                      {/* {quantityFunc()} */}
                                      {newSelectedOutletItemAddOnDetails[item2.uniqueId] ?
                                        newSelectedOutletItemAddOnDetails[item2.uniqueId].quantity
                                        : 0
                                      }
                                    </Text>
                                  </View>

                                  <TouchableOpacity
                                    onPress={() => {
                                      // addqty(menuItem.id);
                                      // setQuantity(quantity + 1);

                                      var quantityTemp = 0;

                                      if (newSelectedOutletItemAddOnDetails[item2.uniqueId]) {
                                        quantityTemp = newSelectedOutletItemAddOnDetails[item2.uniqueId].quantity;

                                        quantityTemp = quantityTemp + 1;

                                        setNewSelectedOutletItemAddOnDetails({
                                          ...newSelectedOutletItemAddOnDetails,
                                          [item2.uniqueId]: {
                                            ...newSelectedOutletItemAddOnDetails[item2.uniqueId],
                                            quantity: quantityTemp,
                                            price: item2.price,

                                            outletItemAddOnChoiceId: item2.uniqueId,
                                            outletItemAddOnId: item.uniqueId,

                                            choiceName: item2.name,
                                            addOnName: item.name,

                                            minSelect: item2.minSelect,
                                            maxSelect: item2.maxSelect,
                                          }
                                        });
                                      }
                                      else {
                                        setNewSelectedOutletItemAddOnDetails({
                                          ...newSelectedOutletItemAddOnDetails,
                                          [item2.uniqueId]: {
                                            quantity: 1,
                                            price: item2.price,

                                            outletItemAddOnChoiceId: item2.uniqueId,
                                            outletItemAddOnId: item.uniqueId,

                                            choiceName: item2.name,
                                            addOnName: item.name,

                                            minSelect: item2.minSelect,
                                            maxSelect: item2.maxSelect,
                                          }
                                        });
                                      }
                                    }}>
                                    <View
                                      style={[
                                        styles.addBtn,
                                        {
                                          backgroundColor: Colors.primaryColor,
                                          left: -1,

                                          width: 20,
                                          height: 22,
                                        },
                                      ]}
                                    >
                                      <Text
                                        style={{
                                          fontSize: 15,
                                          fontWeight: "500",
                                          color: Colors.whiteColor,
                                        }}
                                      >
                                        +
                                      </Text>
                                    </View>
                                  </TouchableOpacity>
                                </View>

                                <View style={{
                                  width: '60%',
                                  flexDirection: 'row',
                                  // backgroundColor: 'blue',
                                  justifyContent: 'flex-end',
                                }}>
                                  <Text style={{
                                    fontFamily: 'NunitoSans-Regular',
                                    fontSize: 14,
                                    marginLeft: 10,
                                  }}>+ RM{((newSelectedOutletItemAddOnDetails[item2.uniqueId] ?
                                    (newSelectedOutletItemAddOnDetails[item2.uniqueId].quantity > 0 ? newSelectedOutletItemAddOnDetails[item2.uniqueId].quantity : 1)
                                    : 1) * item2.price).toFixed(2)
                                    }</Text>
                                </View>
                              </View>
                            </View>
                          );
                        })}
                      </View>
                    </View> : null)
                  );
                }
              })
              : null}
          </>
        }

        {/* {outletsItemAddOnDict[selectedOutletItem.uniqueId]
          ? outletsItemAddOnDict[selectedOutletItem.uniqueId].map((item, index) => {  
            console.log('outletsItemAddOnDict');
            console.log(outletsItemAddOnDict);
            console.log('outletsItemAddOnChoiceDict');
            console.log(outletsItemAddOnChoiceDict);

            return (
              (item.name !== "" ? <View style={{ marginBottom: 15 }}>
                <Text
                  style={{
                    color: Colors.descriptionColor,
                    fontWeight: "500",
                    fontSize: 16,
                    marginBottom: 15,
                  }}
                >
                  {item.name}
                </Text>
                {outletsItemAddOnChoiceDict[item.uniqueId] && outletsItemAddOnChoiceDict[item.uniqueId].map((item2, index) => {
                  return (
                    (item2.name !== "" ? <View
                      style={{
                        marginBottom: 20,
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {item.leastSelect == 1 ? (
                          <View
                            style={{
                              flexDirection: 'row',
                              marginRight: 10,
                            }}>

                            <TouchableOpacity
                              style={[
                                styles.addBtnSmall,
                                {
                                  backgroundColor:
                                    getOptionQuantity(item2.uniqueId) > 0
                                      ? Colors.primaryColor
                                      : "#e8e9eb",
                                },
                              ]}
                              onPress={() => {
                                console.log("getOptionQuantity(item2.uniqudId)", -getOptionQuantity(item2.uniqueId))
                                if (getOptionQuantity(item2.uniqueId) >= 1) {
                                  addOption(
                                    item2.name,
                                    -getOptionQuantity(item2.uniqueId),
                                    item2.uniqueId,
                                    item2.price,
                                  );
                                }
                                else {
                                  addOption(
                                    item2.name,
                                    1,
                                    item2.uniqueId,
                                    item2.price,
                                  );
                                }


                              }}
                            >
                              <Icon1
                                name="check"
                                size={25}
                                color={
                                  getOptionQuantity(item2.uniqueId) > 0
                                    ? Colors.whiteColor
                                    : Colors.descriptionColor
                                }
                              />
                            </TouchableOpacity>

                          </View>
                        ) : (
                            <View
                              style={{
                                flexDirection: 'row',
                                marginRight: 10,
                              }}>
                              <View>
                                <TouchableOpacity
                                  style={[
                                    styles.addBtnSmall,
                                    {
                                      backgroundColor:
                                        containsObject(item2.uniqueId) == true
                                          ? Colors.primaryColor
                                          : '#e8e9eb',
                                    },
                                  ]}
                                  onPress={() => {
                                    addLeastItem(
                                      item2.name,
                                      item2.uniqueId,
                                      item2.price,
                                      1,
                                    );
                                  }}>
                                  <Icon1
                                    name="check"
                                    size={25}
                                    color={
                                      containsObject(item2.uniqueId) == true
                                        ? Colors.whiteColor
                                        : Colors.descriptionColor
                                    }
                                  />
                                </TouchableOpacity>
                              </View>
                            </View>
                          )}
                        <View style={{ width: '55%' }}>
                          <Text style={{ fontSize: 14, fontWeight: '700' }}>
                            {item2.name}
                          </Text>
                        </View>
                        {item.leastSelect == 1 ? (
                          <View style={{ width: '14%', marginLeft: 3 }}>
                            <View style={{ flexDirection: 'row' }}>
                              <TouchableOpacity
                                disabled={false}
                                onPress={() => {
                                  if (item2.quantity > 0) {
                                    item2.quantity - 1;
                                  }
                                  addOption(
                                    item2.name,
                                    -1,
                                    item2.uniqueId,
                                    item2.price,
                                  );
                                }}>
                                <View
                                  style={[
                                    styles.addBtnSmall,
                                    {
                                      backgroundColor:
                                        Colors.descriptionColor,
                                    },
                                  ]}>
                                  <Text
                                    style={{
                                      fontSize: 10,
                                      fontWeight: '500',
                                      color: Colors.whiteColor,
                                    }}>
                                    -
                                    </Text>
                                </View>
                              </TouchableOpacity>
                              <View
                                style={[
                                  styles.addBtnSmall,
                                  {
                                    backgroundColor: Colors.whiteColor,
                                    borderWidth: StyleSheet.hairlineWidth,
                                    borderColor: Colors.descriptionColor,
                                  },
                                ]}>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    color: Colors.primaryColor,
                                  }}>
                                  {getOptionQuantity(item2.uniqueId)}
                                </Text>
                              </View>
                              <TouchableOpacity
                                disabled={false}
                                onPress={() => {
                                  item2.uniqueId + 1;
                                  addOption(
                                    item2.name,
                                    1,
                                    item2.uniqueId,
                                    item2.price,
                                  );
                                }}>
                                <View
                                  style={[
                                    styles.addBtnSmall,
                                    {
                                      backgroundColor: Colors.primaryColor,
                                    },
                                  ]}>
                                  <Text
                                    style={{
                                      fontSize: 10,
                                      fontWeight: '500',
                                      color: Colors.whiteColor,
                                    }}>
                                    +
                                    </Text>
                                </View>
                              </TouchableOpacity>
                            </View>
                          </View>
                        ) : (
                            <View
                              style={{ width: '14%', marginLeft: 3 }}></View>
                          )}
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}>
                        {item.leastSelect == 1 ? (
                          <View style={{ width: '50%' }}>
                            <Text style={{ fontSize: 14, fontWeight: '300' }}>
                              {' '}
                                + RM
                                {(parseFloat(item2.price).toFixed(2) *
                                getOptionQuantity(item2.uniqueId)).toFixed(2)}
                            </Text>
                          </View>
                        ) : (
                            <View style={{ width: '50%' }}>
                              <Text style={{ fontSize: 14, fontWeight: '300' }}>
                                {' '}
                                + RM
                                {parseFloat(
                                  item2.price,
                                ).toFixed(2) * 1}
                              </Text>
                            </View>
                          )}
                      </View>
                    </View> : null)
                  );
                })}
              </View> : null)
            );
          })
          : null} */}

        <View style={{
          marginBottom: 15,
          marginTop: 5,
        }}>
          <Text
            style={{
              color: Colors.descriptionColor,
              // color: Colors.mainTxtColor,
              // fontWeight: "500",
              fontSize: 17,
              fontFamily: 'NunitoSans-SemiBold',
              marginBottom: 15,
            }}
          >
            Special Remarks:
          </Text>
          <TextInput
            underlineColorAndroid={Colors.fieldtBgColor}
            style={styles.textInput}
            placeholder="eg: no onions"
            onChangeText={(text) => {
              // setState({ remark: text });
              setRemark(text);
            }}
            value={remark}
            multiline={true}
          />
        </View>

        <Text
          style={{
            fontSize: 18,
            // fontWeight: "700",
            color: Colors.primaryColor,
            alignSelf: 'flex-end',
            fontFamily: 'NunitoSans-SemiBold',
          }}>
          {/* RM{(parseFloat(totals()) * quantityFunc()).toFixed(2)} */}
          RM{(
            (totalPrice + addOnPrice) * quantity
          ).toFixed(2)}
        </Text>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <TouchableOpacity
              onPress={() => {
                // if (quantity - 1 > 0) {
                //   lessqty(menuItem.id);
                // }

                setQuantity(quantity - 1 >= 0 ? quantity - 1 : 0);
              }}>
              <View
                style={[
                  styles.addBtn,
                  { backgroundColor: Colors.descriptionColor },
                ]}
              >
                <Text
                  style={{
                    fontSize: 30,
                    fontWeight: "500",
                    color: Colors.whiteColor,
                    marginBottom: isMobile() ? 0 : 8,
                  }}
                >
                  -
                </Text>
              </View>
            </TouchableOpacity>
            <View
              style={[
                styles.addBtn,
                {
                  backgroundColor: Colors.whiteColor,
                  borderWidth: StyleSheet.hairlineWidth,
                  borderColor: Colors.descriptionColor,
                  borderWidth: 1.5,

                  width: 50,
                },
              ]}
            >
              <Text
                style={{
                  fontSize: 25,
                  fontWeight: "bold",
                  color: Colors.primaryColor,
                  marginBottom: isMobile() ? 0 : 3,
                }}>
                {/* {quantityFunc()} */}
                {quantity}
              </Text>
            </View>
            <TouchableOpacity
              onPress={() => {
                // addqty(menuItem.id);
                setQuantity(quantity + 1);
              }}>
              <View
                style={[
                  styles.addBtn,
                  {
                    backgroundColor: Colors.primaryColor,
                    left: -1,
                  },
                ]}
              >
                <Text
                  style={{
                    fontSize: 30,
                    fontWeight: "500",
                    color: Colors.whiteColor,
                    marginBottom: isMobile() ? 0 : 8,
                  }}
                >
                  +
                </Text>
              </View>
            </TouchableOpacity>
          </View>
          <View style={{
            flex: 1,
            paddingLeft: 30,
          }}>
            <TouchableOpacity
              onPress={async () => {
                // save();
                // setState({ optional: 0 });
                // //console.log(optional);
                // addToCart(),
                //   props.navigation.goBack();  

                if (addOnVerified && !isLoading) {
                  var newCartItems = [];

                  if (selectedOutletItem) {
                    var tempCartItemChoices = {};

                    // if (cartItemChoices[selectedOutletItem.uniqueId]) {
                    //   // means got previous choices for this outlet item, continue to accumulate

                    //   tempCartItemChoices = {
                    //     ...cartItemChoices[selectedOutletItem.uniqueId],
                    //   };
                    // }

                    if (outletsItemAddOnDict[selectedOutletItem.uniqueId]) {
                      const tempOutletsItemAddOnList = outletsItemAddOnDict[selectedOutletItem.uniqueId];

                      for (var i = 0; i < tempOutletsItemAddOnList.length; i++) {
                        // check against the default item add on list

                        const tempAddOn = tempOutletsItemAddOnList[i];

                        if (selectedOutletItemAddOn[tempAddOn.uniqueId] !== undefined) {
                          // means this addon got choices selected before (could also means deselected, so need checks further as shown below)

                          const tempAddOnSelectedObjList = Object.entries(selectedOutletItemAddOn[tempAddOn.uniqueId]).map(([key, value]) => ({ key: key, value: value }));

                          for (var j = 0; j < tempAddOnSelectedObjList.length; j++) {
                            if (tempAddOnSelectedObjList[j].value === true) {
                              // means this addon's choice is selected

                              tempCartItemChoices[tempAddOnSelectedObjList[j].key] = true;
                            }
                            else {
                              tempCartItemChoices[tempAddOnSelectedObjList[j].key] = false;
                            }
                          }
                        }
                      }
                    }

                    ///////////////////////////////////////////////////

                    // const newSelectedOutletItemAddOnList = Object.entries(newSelectedOutletItemAddOn).map(([key, value]) => ({ key: key, value: value }));
                    const newSelectedOutletItemAddOnList = Object.entries(newSelectedOutletItemAddOnDetails).map(([key, value]) => ({ key: key, value: value }));

                    var addOnGroupList = [];

                    for (var i = 0; i < newSelectedOutletItemAddOnList.length; i++) {
                      if (newSelectedOutletItemAddOnList[i].value) {
                        const addOnTemp = newSelectedOutletItemAddOnDetails[newSelectedOutletItemAddOnList[i].key];

                        if (addOnTemp) {
                          addOnGroupList.push(addOnTemp);
                        }
                      }
                    }

                    ///////////////////////////////////////////////////

                    if (onUpdatingCartItem) {
                      // update existing cart item

                      var updateCartItemIndex = 0;

                      for (var i = 0; i < cartItems.length; i++) {
                        if (cartItems[i].itemId === onUpdatingCartItem.itemId &&
                          cartItems[i].cartItemDate === onUpdatingCartItem.cartItemDate) {
                          updateCartItemIndex = i;
                        }
                      }

                      // var newCartItems = [];

                      if (quantity <= 0) {
                        newCartItems = [
                          ...cartItems.slice(0, updateCartItemIndex),
                          ...cartItems.slice(updateCartItemIndex + 1),
                        ];
                      }
                      else {
                        newCartItems = [
                          ...cartItems.slice(0, updateCartItemIndex),
                          {
                            itemId: selectedOutletItem.uniqueId,
                            choices: tempCartItemChoices,
                            remarks: remark,
                            fireOrder: false,
                            quantity: quantity,
                            cartItemDate: Date.now(), // hmm need update or use existing?

                            addOnGroupList: addOnGroupList,

                            itemSku: selectedOutletItem.sku,
                            categoryId: selectedOutletItem.categoryId,
                          },
                          ...cartItems.slice(updateCartItemIndex + 1),
                        ];
                      }

                      if (newCartItems.length > 0) {
                        await AsyncStorage.setItem(`${firebaseUid}.cartItems`, JSON.stringify(newCartItems));

                        await AsyncStorage.setItem(`${firebaseUid}.cartOutletId`, selectedOutlet.uniqueId);
                      }
                      else {
                        await AsyncStorage.removeItem(`${firebaseUid}.cartItems`);
                        await AsyncStorage.removeItem(`${firebaseUid}.cartOutletId`);
                      }

                      CommonStore.update(s => {
                        // s.cartItemChoices = {
                        //   ...cartItemChoices,
                        //   [selectedOutletItem.uniqueId]: {
                        //     ...tempCartItemChoices,
                        //   },
                        // };

                        // s.cartItems = new Set(cartItems).add(selectedOutletItem.uniqueId);
                        s.cartItems = newCartItems;

                        if (newCartItems.length <= 0) {
                          s.cartOutletId = null;
                        }
                      });
                    }
                    else {
                      // add to cart

                      if (quantity <= 0) {
                        Alert.alert('Error', 'Please add one or more item');

                        return;
                      }

                      newCartItems = [
                        ...cartItems,
                        {
                          itemId: selectedOutletItem.uniqueId,
                          choices: tempCartItemChoices,
                          remarks: remark,
                          fireOrder: false,
                          quantity: quantity,
                          cartItemDate: Date.now(),

                          addOnGroupList: addOnGroupList,

                          itemSku: selectedOutletItem.sku,
                          categoryId: selectedOutletItem.categoryId,
                        },
                      ];

                      await AsyncStorage.setItem(`${firebaseUid}.cartItems`, JSON.stringify(newCartItems));

                      await AsyncStorage.setItem(`${firebaseUid}.cartOutletId`, selectedOutlet.uniqueId);

                      CommonStore.update(s => {
                        s.cartItemChoices = {
                          ...cartItemChoices,
                          [selectedOutletItem.uniqueId]: {
                            ...tempCartItemChoices,
                          },
                        };

                        // s.cartItems = new Set(cartItems).add(selectedOutletItem.uniqueId);
                        s.cartItems = newCartItems;
                      });

                      console.log('cartItemChoices');
                      console.log({
                        ...cartItemChoices,
                        [selectedOutletItem.uniqueId]: {
                          ...tempCartItemChoices,
                        },
                      });
                      console.log('cartItems');
                      console.log(newCartItems);
                    }
                  }

                  if (selectedOutletTableId.length > 0) {
                    await updateUserCart(newCartItems);
                  }

                  CommonStore.update(s => {
                    s.cartOutletId = selectedOutlet.uniqueId;
                  });

                  if (onUpdatingCartItem) {
                    linkTo(`${prefix}/outlet/cart`);
                  }
                  else {
                    linkTo(`${prefix}/outlet/menu`);
                  }

                  // navigation.goBack();
                }
                else {
                  // Alert.alert('Info', 'Please select your choice before proceed.');

                  CommonStore.update(s => {
                    s.alertObj = {
                      title: 'Info',
                      message: 'Please select your choice before proceed.',
                    };
                  });
                }

                // if (onUpdatingCartItem === null && quantity > 0) {

                // }
                // else {
                //   Alert.alert('Error', 'Please add one or more item');
                // }            
              }}>
              <View style={[Styles.button, { paddingVertical: 12 }]}>
                <Text
                  style={{
                    color: "#ffffff",
                    fontSize: 18,
                    // fontWeight: "bold",
                    fontFamily: 'NunitoSans-SemiBold',
                  }}
                >
                  {onUpdatingCartItem ? 'Update cart' : 'Add to Cart'}
                </Text>
              </View>
            </TouchableOpacity>
            {/* <Modal
              style={{ flex: 1 }}
              visible={visible}
              transparent={true}
              animationType="slide"
            >
              <View
                style={{
                  backgroundColor: "rgba(0,0,0,0.5)",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View style={styles.confirmBox}>
                  <TouchableOpacity
                    onPress={() => {
                      setState({ visible: false });
                    }}
                  >
                    <View
                      style={{
                        alignSelf: "flex-start",
                        padding: 16,
                      }}
                    >
                      <Close name="close" size={24} />
                    </View>
                  </TouchableOpacity>
                  <View>
                    <Text
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: 14,
                        marginBottom: 5,
                      }}
                    >
                      Confirm order?
                    </Text>
                  </View>
                  <View
                    style={{
                      alignItems: "center",
                      width: "100%",
                      alignContent: "center",
                      marginBottom: "6%",
                      height: "20%",
                      marginTop: "5%",
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        setState({ optional: 0 });
                        console.log(optional);
                        addToCart(),
                          props.navigation.navigate("OutletMenu");
                        setState({ visible: false });
                      }}
                      style={{
                        backgroundColor: Colors.whiteColor,
                        borderColor: Colors.primaryColor,
                        borderWidth: 1,
                        width: "70%",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        borderRadius: 5,
                        height: "80%",
                        marginBottom: "5%",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 15,
                          color: Colors.primaryColor,
                        }}
                      >
                        Confirm Order
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        // setState({ optional1: 1 });
                        // console.log(optional1);
                        // setState({ visible: false });
                        // addToCart1(),
                        //   props.navigation.navigate("OutletMenu");
                        setState({ visible: false });
                      }}
                      style={{
                        backgroundColor: Colors.primaryColor,
                        width: "70%",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        borderRadius: 5,
                        height: "80%",
                        marginBottom: "2%",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 15,
                          color: Colors.whiteColor,
                        }}
                      >
                        CANCEL
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </Modal> */}
          </View>
        </View>
        <View style={{ minHeight: 100 }} />
      </ScrollView>
      {/* {cartIcon ?
        <Draggable
          shouldReverse={reverse}
          renderSize={100}
          renderColor={Colors.secondaryColor}
          isCircle
          x={280}
          y={500}
          onShortPressRelease={() => {
            goToCart();
          }}
        >
          <View style={{ width: 60, height: 60, justifyContent: "center" }}>
            <View style={{ alignSelf: "center" }}>
              <Icon name="cart-outline" size={45} />
            </View>
            <View style={styles.cartCount}>
              <Text style={{ color: Colors.whiteColor, fontSize: 10 }}>
                {Cart.getCartItem().length}
              </Text>
            </View>
          </View>
        </Draggable>
        : null} */}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#ffffff",
    padding: 16,
  },
  floatCartBtn: {
    position: "absolute",
    bottom: 30,
    right: 30,
    width: 60,
    height: 60,
    borderRadius: 60 / 2,
    backgroundColor: Colors.secondaryColor,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
  },
  textInput: {
    // height: 100,
    height: isMobile() ? Dimensions.get('window').width * 0.35 : Dimensions.get('window').width * 0.08,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 12,

    fontSize: 14,
    fontFamily: 'NunitoSans-Regular',
    // color: Colors.descriptionColor,
    textAlignVertical: 'top',
    paddingVertical: 15,
  },
  addBtn: {
    backgroundColor: Colors.primaryColor,
    width: 40,
    height: 45,
    justifyContent: "center",
    alignItems: "center",

    borderColor: 'transparent',
  },
  addBtnSmall: {
    backgroundColor: Colors.primaryColor,
    width: 25,
    height: 25,
    justifyContent: "center",
    alignItems: "center",
  },
  cartCount: {
    position: "absolute",
    top: -12,
    right: -10,
    backgroundColor: Colors.primaryColor,
    width: 30,
    height: 30,
    borderRadius: 30 / 2,
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  confirmBox: {
    width: "70%",
    height: "40%",
    borderRadius: 10,
    backgroundColor: Colors.whiteColor,
  },
  textFieldInput: {
    height: "100%",
    width: "100%",
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
  },
  addBtn1: {
    backgroundColor: Colors.primaryColor,
    width: 20,
    height: 20,
    justifyContent: "center",
    alignItems: "center",
  },
});
export default MenuItemDetailsScreen;
