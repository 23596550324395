import React, { Component, useState, useEffect } from 'react';
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  TouchableOpacity,
  Alert,
  Dimensions,
  Modal, ActivityIndicator,
  FlatList,
  BackHandler
} from 'react-native';
import Colors from '../constant/Colors';
import Styles from '../constant/Styles';
import ApiClient from '../util/ApiClient';
import API from '../constant/API';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import Entypo from "react-native-vector-icons/Entypo";
import Ionicons from 'react-native-vector-icons/Ionicons';
import * as Cart from '../util/Cart';
import Draggable from 'react-native-draggable';
import Back from 'react-native-vector-icons/EvilIcons';
// import { FlatList } from 'react-native-gesture-handler';
// import StickyParallaxHeader from 'react-native-sticky-parallax-header'
import Close from 'react-native-vector-icons/AntDesign';
import { CommonStore } from '../store/commonStore';
import { ORDER_TYPE } from '../constant/common';
import { UserStore } from '../store/userStore';
import AsyncStorage from "@react-native-async-storage/async-storage";
import AsyncImage from '../components/asyncImage';
import { useFocusEffect, useLinkTo } from '@react-navigation/native';
import { useCallback } from 'react/cjs/react.production.min';
import { DataStore } from '../store/dataStore';
import { prefix } from '../constant/env';
import { isMobile } from '../util/commonFuncs';

/**
 * OutletMenuScreenr
 * function
 * *display list of menu for purchase
 * *access to cart screen
 * 
 * route.params
 * *outletData: array of data of the current outlet 
 * *orderType: type of order being made (takeaway, pick up, dine in)
 * *test: ???
 * *navFrom: the screen stack this route is on 
 */

const OutletMenuScreen = props => {
  const {
    navigation,
    route
  } = props;

  console.log('outlet menu rendered!');

  // const { orderType } = route.params;
  // const outletDataParam = route.params.outletData;
  // const testParam = route.params.test;
  // const navFromParam = route.params.navFrom;

  navigation.setOptions({
    headerLeft: () => (
      <TouchableOpacity style={{
        opacity: 0,
      }} onPress={() => {
        // props.navigation.goBack();
      }}>
        <View style={{
          marginLeft: 10,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}>
          <Ionicons
            name="chevron-back"
            size={26}
            color={Colors.fieldtTxtColor}
            style={{
            }}
          />

          <Text
            style={{
              color: Colors.fieldtTxtColor,
              fontSize: 16,
              textAlign: 'center',
              fontFamily: 'NunitoSans-Regular',
              lineHeight: 22,
              marginTop: -1,
            }}>
            Back
          </Text>
        </View>
      </TouchableOpacity>
    ),
    headerRight: () => (
      <TouchableOpacity onPress={() => {
        // props.navigation.navigate('Profile')
      }} style={{
        opacity: 0,
      }}>
        <View style={{ marginRight: 15 }}>
          <Ionicons name="menu" size={30} color={Colors.primaryColor} />
        </View>
      </TouchableOpacity>
    ),
    headerTitle: () => (
      <View style={{
        justifyContent: 'center',
        alignItems: 'center',
        bottom: -1,
      }}>
        <Text
          style={{
            fontSize: 20,
            lineHeight: 25,
            textAlign: 'center',
            fontFamily: 'NunitoSans-Bold',
            color: Colors.mainTxtColor,
          }}>
          {/* {orderType == ORDER_TYPE.DINEIN ? "Dine In" : orderType == ORDER_TYPE.DELIVERY ? "Delivery" : "Takeaway"} */}
          {selectedOutlet ? selectedOutlet.name : ''}
        </Text>
      </View>
    ),
    headerTintColor: Colors.blackColor,
  });

  const linkTo = useLinkTo();

  // const [outletData, setOutletData] = useState(outletDataParam);
  const [outletMenu, setOutletMenu] = useState([]);
  const [category, setCategory] = useState('');
  const [menu, setMenu] = useState([]);
  const [cartIcon, setCartIcon] = useState(false);
  const [reverse, setReverse] = useState(false);
  const [cartItem, setCartItem] = useState([]);
  const [categoryOutlet, setCategoryOutlet] = useState([]);
  // const [test, setTest] = useState(testParam);
  const [currentMenu, setCurrentMenu] = useState([]);
  const [productList2, setProductList2] = useState([]);
  const [productList, setProductList] = useState([]);
  const [choice, setChoice] = useState(null);
  const [categoryIndex, setCategoryIndex] = useState(0);
  // const [navFrom, setNavFrom] = useState(navFromParam);
  const [isInfoTabHitTop, setIsInfoTabHitTop] = useState(false);
  const [onStartVisible, setOnStartVisible] = useState(false);
  const [cartWarning, setCartWarning] = useState(false);
  const [cartProceed, setCartProceed] = useState([]);

  const [tempItem, setTempItem] = useState({});

  const selectedOutletItems = CommonStore.useState(s => s.selectedOutletItems);
  const selectedOutletItemCategories = CommonStore.useState(s => s.selectedOutletItemCategories);
  const selectedOutletItemCategory = CommonStore.useState(s => s.selectedOutletItemCategory);

  const selectedOutletItemCategoriesDict = CommonStore.useState(s => s.selectedOutletItemCategoriesDict);

  const isSwitchingOutlets = CommonStore.useState(s => s.isSwitchingOutlets);

  const cartItems = CommonStore.useState(s => s.cartItems);
  const orderType = CommonStore.useState(s => s.orderType);

  const selectedOutlet = CommonStore.useState(s => s.selectedOutlet);
  const cartOutletId = CommonStore.useState(s => s.cartOutletId);

  const firebaseUid = UserStore.useState(s => s.firebaseUid);

  const scannedQrData = CommonStore.useState(s => s.scannedQrData);

  const userCart = CommonStore.useState(s => s.userCart);

  const overrideItemPriceSkuDict = CommonStore.useState(s => s.overrideItemPriceSkuDict);
  const amountOffItemSkuDict = CommonStore.useState(s => s.amountOffItemSkuDict);
  const percentageOffItemSkuDict = CommonStore.useState(s => s.percentageOffItemSkuDict);
  const buy1Free1ItemSkuDict = CommonStore.useState(s => s.buy1Free1ItemSkuDict);

  const overrideCategoryPriceNameDict = CommonStore.useState(s => s.overrideCategoryPriceNameDict);
  const amountOffCategoryNameDict = CommonStore.useState(s => s.amountOffCategoryNameDict);
  const percentageOffCategoryNameDict = CommonStore.useState(s => s.percentageOffCategoryNameDict);
  const buy1Free1CategoryNameDict = CommonStore.useState(s => s.buy1Free1CategoryNameDict);

  const pointsRedeemItemSkuDict = CommonStore.useState(s => s.pointsRedeemItemSkuDict);
  const pointsRedeemCategoryNameDict = CommonStore.useState(s => s.pointsRedeemCategoryNameDict);

  const linkToFunc = DataStore.useState(s => s.linkToFunc);

  useEffect(() => {
    window.addEventListener('popstate', e => {
      // e.preventDefault();
      // console.log('unload!');

      linkTo(`${prefix}/outlet/menu`);
    }, false);

    window.addEventListener('load', e => {
      // e.preventDefault();
      // console.log('unload!');

      // linkTo('/web/outlet/menu');

      setTimeout(() => {
        readStates();
      }, 1000);

      setTimeout(() => {
        readCommonStates();
      }, 1000);
    }, false);

    // window.onpopstate = e => {
    //   e.preventDefault();
    //   console.log('unload!');
    // }
  }, []);

  useEffect(() => {
    if (linkTo) {
        DataStore.update(s => {
            s.linkToFunc = linkTo;
        });   
    }
}, [linkTo]);

  useEffect(() => {
    if (selectedOutlet === null) {
      readStates();
    }

    readCommonStates();
  }, [selectedOutlet])

  const readStates = async () => {
    if (selectedOutlet === null) {
      const commonStoreDataRaw = await AsyncStorage.getItem('@commonStore');
      if (commonStoreDataRaw !== null) {
        const commonStoreData = JSON.parse(commonStoreDataRaw);

        const latestOutletId = await AsyncStorage.getItem('latestOutletId');

        if (commonStoreData.selectedOutlet && latestOutletId === commonStoreData.selectedOutlet.uniqueId) {
          // check if it's the same outlet user scanned

          if (commonStoreData.orderType === ORDER_TYPE.DINEIN && commonStoreData.userCart.uniqueId === undefined) {
            // logout the user

            linkTo(`${prefix}/scan`);
          }

          CommonStore.replace(commonStoreData);

          const userStoreDataRaw = await AsyncStorage.getItem('@userStore');
          if (userStoreDataRaw !== null) {
            const userStoreData = JSON.parse(userStoreDataRaw);

            UserStore.replace(userStoreData);
          }

          // const dataStoreDataRaw = await AsyncStorage.getItem('@dataStore');
          // if (dataStoreDataRaw !== null) {
          //   const dataStoreData = JSON.parse(dataStoreDataRaw);

          //   DataStore.replace(dataStoreData);
          // }
        }
      }
    }
  };

  const readCommonStates = async () => {
    // if (!linkToFunc) {
    //   const dataStoreDataRaw = await AsyncStorage.getItem('@dataStore');
    //   if (dataStoreDataRaw !== null) {
    //     const dataStoreData = JSON.parse(dataStoreDataRaw);

    //     DataStore.replace(dataStoreData);
    //   }
    // }
  };

  // const userCart = CommonStore.useState(s => s.userCart);
  // const selectedOutletTableId = CommonStore.useState(s => s.selectedOutletTableId);

  // useEffect(() => {
  //   syncWithDineInCart();
  // }, [selectedOutletTableId, userCart]);

  useEffect(() => {
    if (cartItems.length > 0) {
      setCartIcon(true);
    }
    else {
      setCartIcon(false);
    }
  }, [cartItems.length]);

  const setState = () => { };

  useEffect(() => {
    categoryFunc();
    refresh();
    refreshMenu()
    // ApiClient.GET(API.outlet2 + outletData.id).then((result) => {
    //   // console.log(result)
    //   setState({ outletData: result })

    //   refreshMenu();
    // });
    // menu item
    // ApiClient.GET(API.merchantMenu + outletData.id).then((result) => {
    //   // console.log(result)
    //   if (result.length > 0) {
    //     setState({ category: result[0].category, menu: result[0].items })
    //   }
    //   setState({ outletMenu: result })
    // });

    // setInterval(() => {
    //   cartCount();
    //   getCartItem();
    // }, 1000);

    // setInterval(() => {
    //   cartCount();
    //   getCartItem();
    // }, 2500);
  }, []);

  useEffect(() => {
    return () => {
      CommonStore.update(s => {
        // s.selectedOutletItems = [];
        // s.selectedOutletItemCategories = [];

        // s.scannedQrData = null;

        // s.selectedOutlet = null;

        s.orderType = ORDER_TYPE.DELIVERY;

        s.selectedOutletTableId = '';
      });
    }
  }, [
    // scannedQrData,
  ]);

  useEffect(() => {
    console.log('scannedQrData');
    console.log(scannedQrData);

    if (scannedQrData !== null) {
      return () => {
        proceedDeleteUserCart();

        CommonStore.update(s => {
          // s.selectedOutletItems = [];
          // s.selectedOutletItemCategories = [];

          s.scannedQrData = null;

          s.selectedOutlet = null;

          s.orderType = ORDER_TYPE.DELIVERY;

          s.selectedOutletTableId = '';
        });
      }
    }
  }, [
    scannedQrData,
  ]);

  const proceedDeleteUserCart = async () => {
    await deleteUserCart();
  };

  const deleteUserCart = async () => {
    if (userCart.uniqueId) {
      const body = {
        userCartId: userCart.uniqueId,
      };

      ApiClient.POST(API.deleteUserCart, body).then((result) => {
        if (result && result.status === 'success') {
          console.log('ok');
        }
      });
    }
  };

  // function here  

  // const syncWithDineInCart = async () => {
  //   if (selectedOutletTableId.length > 0) {
  //     await clearCartItems();

  //     if (userCart.tableId === selectedOutletTableId) {
  //       CommonStore.update(s => {
  //         s.cartItems = userCart.cartItems;
  //       });
  //     }
  //   }
  // };

  const getCartItem = () => {
    setState({ cartItem: Cart.getCartItem() })
    // console.log(cartItem)
  }

  const cartCount = () => {
    if (Cart.getCartItem() !== null) {
      if (Cart.getCartItem().length > 0) {
        setState({ cartIcon: true })
      }
      else {
        setState({ cartIcon: false })
      }
    }
    else {
      setState({ cartIcon: false })
    }
  }

  const goToCart = () => {
    // if (Cart.getCartItem().length > 0) {
    //   if (navFrom == "TAKEAWAY") {
    //     props.navigation.navigate('Cart', {
    //       screen: "Cart", params: {
    //         // test: test,
    //         outletData: selectedOutlet, navFrom: navFrom
    //       }
    //     })
    //   }
    //   else {
    //     props.navigation.navigate("Cart", { 
    //       // test: test, 
    //       outletData: selectedOutlet });
    //   }

    // } else {
    //   Alert.alert("Info", "No item in your cart at the moment", [
    //     { text: "OK", onPress: () => { } }
    //   ],
    //     { cancelable: false })
    // }
  }

  const onCartClicked = () => {
    // if (cartItems.length > 0) {
    //   if (navFrom == "TAKEAWAY") {
    //     // props.navigation.navigate('Cart', { screen: "Cart", params: { test: test, outletData: outletData, navFrom: navFrom } })
    //     props.navigation.navigate('Cart', { 
    //       // test: test, 
    //       outletData: selectedOutlet, navFrom: navFrom });
    //   }
    //   else {
    //     props.navigation.navigate("Cart", { 
    //       // test: test, 
    //       outletData: selectedOutlet });
    //   }

    // } else {
    //   Alert.alert("Info", "No item in your cart at the moment", [
    //     { text: "OK", onPress: () => { } }
    //   ],
    //     { cancelable: false })
    // }
  };

  const categoryFunc = () => {

    // ApiClient.GET(API.activeMenu + outletData.id).then((result) => {
    //   const tmpCategories = {};
    //   for (const category of result) {
    //     const categoryName = category.name
    //     const categoryId = category.id
    //     if (!tmpCategories[categoryName]) {
    //       tmpCategories[categoryName] = {
    //         label: categoryName,
    //         value: categoryId,
    //       };
    //     }

    //   }
    //   const categories = Object.values(tmpCategories);
    //   setState({ categoryOutlet: categories, category: categories[0].label });

    // }).catch(err => {
    //   console.log("Error")
    //   console.log(err)
    // });
  }

  const refresh = () => {
    // ApiClient.GET(API.merchantMenu + outletData.id).then((result) => {
    //   if (result != undefined && result.length > 0) {
    //     var productListRaw = [];

    //     result.forEach((element) => {
    //       console.log(element.items);
    //       productListRaw = productListRaw.concat(element.category);
    //       const activeItem = productListRaw.filter(item => item.active == 1)
    //       setState({ productList: productListRaw, productList2: activeItem, }, () => { });
    //     });
    //   }
    // });
  }

  const refreshMenu = () => {
    // ApiClient.GET(API.activeMenu + outletData.id).then((result) => {
    //   const category = result.filter(i => i.name == category)
    //   category.map(i => {
    //     // const newList = []
    //     // for (const item of i.items){
    //     //   if(item.name !== ""){
    //     //     newList.push(item)
    //     //   }
    //     // }
    //     // setState({ currentMenu: newList })
    //     setState({ currentMenu: i.items })


    //   })
    //   // }

    //   // }else{
    //   //   setState({ currentMenu: result });
    //   // }

    // });
  }

  // const refresh = () => {
  //   setState({ refresh: true });
  // }

  const renderMenu = ({ item }) => {
    var quantity = 0;
    //const cartItem = cartItem.find(obj => obj.itemId === item.id);

    const itemsInCart = cartItems.filter(obj => obj.itemId === item.uniqueId);
    if (itemsInCart) {
      for (const obj of itemsInCart) {
        quantity += parseInt(obj.quantity);
      }
    }

    var itemNameFontSize = 15;

    if (Dimensions.get('window').width <= 360) {
      itemNameFontSize = 13;
      //console.log(Dimensions.get('window').width)
    }

    const itemNameTextScale = {
      fontSize: itemNameFontSize,
    };

    var overrideCategoryPrice = undefined;

    if (selectedOutletItemCategoriesDict[item.categoryId] && overrideCategoryPriceNameDict[selectedOutletItemCategoriesDict[item.categoryId].name] !== undefined) {
      overrideCategoryPrice = overrideCategoryPriceNameDict[selectedOutletItemCategoriesDict[item.categoryId].name];
    }

    if (item.categoryId === selectedOutletItemCategory.uniqueId) {
      var amountOffCategory = undefined;
      if (selectedOutletItemCategoriesDict[item.categoryId] && amountOffCategoryNameDict[selectedOutletItemCategoriesDict[item.categoryId].name] !== undefined) {
        amountOffCategory = amountOffCategoryNameDict[selectedOutletItemCategoriesDict[item.categoryId].name];
      }

      var percentageOffCategory = undefined;
      if (selectedOutletItemCategoriesDict[item.categoryId] && percentageOffCategoryNameDict[selectedOutletItemCategoriesDict[item.categoryId].name] !== undefined) {
        percentageOffCategory = percentageOffCategoryNameDict[selectedOutletItemCategoriesDict[item.categoryId].name];
      }

      var pointsRedeemCategory = undefined;
      if (selectedOutletItemCategoriesDict[item.categoryId] && pointsRedeemCategoryNameDict[selectedOutletItemCategoriesDict[item.categoryId].name] !== undefined) {
        pointsRedeemCategory = pointsRedeemCategoryNameDict[selectedOutletItemCategoriesDict[item.categoryId].name];
      }

      var buy1Free1Category = undefined;
      if (selectedOutletItemCategoriesDict[item.categoryId] && buy1Free1CategoryNameDict[selectedOutletItemCategoriesDict[item.categoryId].name] !== undefined) {
        buy1Free1Category = buy1Free1CategoryNameDict[selectedOutletItemCategoriesDict[item.categoryId].name];
      }

      return (
        <TouchableOpacity onPress={() => {
          if (checkCartOutlet()) {
            // setState({ cartWarning: true, })

            setTempItem(item);

            setCartWarning(true);
          } else {
            if (item.isActive) {
              CommonStore.update(s => {
                s.selectedOutletItem = item;
              });

              linkTo && linkTo(`${prefix}/outlet/menu/item`);

              // props.navigation.navigate('MenuItemDetails', { refresh: refresh.bind(this), menuItem: item, outletData: selectedOutlet })
            }
            else {
              // Alert.alert(
              //   'Info',
              //   'Sorry, this product is not available for now.',
              // );

              CommonStore.update(s => {
                s.alertObj = {
                  title: 'Info',
                  message: 'Sorry, this product is not available for now.',
                };
              });
            }
          }

        }}>
          <View style={{
            flexDirection: 'row',
            paddingHorizontal: 20,
            paddingBottom: 15,
            paddingTop: 10,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}>
            <View style={{
              flexDirection: 'row',
              alignContent: 'center',
              alignItems: 'center',
              width: isMobile() ? '75%' : Dimensions.get('window').width * 0.55,
              display: 'flex',
              justifyContent: 'flex-start',
              // backgroundColor: 'blue',
            }}>
              <View>
                <View style={[{
                  backgroundColor: Colors.secondaryColor,
                  // width: 60,
                  // height: 60,
                  width: isMobile() ? Dimensions.get('window').width * 0.22 : Dimensions.get('window').width * 0.05,
                  height: isMobile() ? Dimensions.get('window').width * 0.22 : Dimensions.get('window').width * 0.05,
                  borderRadius: 10,
                }, item.image ? {

                } : {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }]}>
                  {item.image
                    ?
                    // <Image source={{ uri: item.image }} style={{
                    //   width: Dimensions.get('window').width * 0.22,
                    //   height: Dimensions.get('window').width * 0.22,
                    //   borderRadius: 10
                    // }} />
                    <AsyncImage source={{ uri: item.image }} style={{
                      width: isMobile() ? Dimensions.get('window').width * 0.22 : Dimensions.get('window').width * 0.05,
                      height: isMobile() ? Dimensions.get('window').width * 0.22 : Dimensions.get('window').width * 0.05,
                      borderRadius: 10
                    }} />
                    :
                    <Ionicons name="fast-food-outline" size={50} />
                  }

                  {
                    !item.isActive
                      ?
                      <View style={{
                        position: 'absolute',
                        zIndex: 3,
                      }}>
                        <View
                          style={{
                            // width: 120,
                            width: isMobile() ? Dimensions.get('window').width * 0.25 : Dimensions.get('window').width * 0.06,
                            left: isMobile() ? -Dimensions.get('window').width * 0.03 : -Dimensions.get('window').width * 0.01,
                            padding: 0,
                            paddingLeft: isMobile() ? Dimensions.get('window').width * 0.02 : Dimensions.get('window').width * 0.005,
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: Colors.tabRed,
                            height: 20,
                            borderTopRightRadius: 10,
                            borderBottomRightRadius: 3,
                          }}>
                          <Text style={{
                            color: '#FFF',
                            fontFamily: 'NunitoSans-Bold',
                            fontSize: 10,
                            bottom: 1,
                          }}>Not available</Text>
                        </View>
                        <View
                          style={{
                            left: isMobile() ? -Dimensions.get('window').width * 0.03 : -Dimensions.get('window').width * 0.01,
                            width: 0,
                            height: 0,
                            backgroundColor: 'transparent',
                            borderStyle: 'solid',
                            borderRightWidth: isMobile() ? Dimensions.get('window').width * 0.03 : Dimensions.get('window').width * 0.01,
                            borderTopWidth: isMobile() ? Dimensions.get('window').width * 0.03 : Dimensions.get('window').width * 0.01,
                            borderRightColor: 'transparent',
                            borderTopColor: 'red',
                            transform: [{ rotate: '90deg' }],
                          }}
                        />
                      </View>
                      :
                      <></>
                  }
                </View>

                {
                  (pointsRedeemItemSkuDict[item.sku] !== undefined ||
                    pointsRedeemCategory !== undefined)
                    ?
                    <View style={{
                      marginTop: 5,

                    }}>
                      <Text style={{
                        color: Colors.primaryColor,
                        fontFamily: "NunitoSans-SemiBold",
                        fontSize: 12,
                        textAlign: 'center'
                      }}>{
                          pointsRedeemItemSkuDict[item.sku] !== undefined
                            ?
                            `${pointsRedeemItemSkuDict[item.sku].conversionPointsFrom} Points to RM${pointsRedeemItemSkuDict[item.sku].conversionCurrencyTo}`
                            :
                            `${pointsRedeemCategory.conversionPointsFrom} Points to RM${pointsRedeemCategory.conversionCurrencyTo}`
                        }</Text>
                    </View>
                    :
                    <></>
                }

                {
                  (buy1Free1ItemSkuDict[item.sku] !== undefined ||
                    buy1Free1Category !== undefined)
                    ?
                    <View style={{
                      marginTop: 5,

                    }}>
                      <Text style={{
                        color: Colors.descriptionColor,
                        fontFamily: "NunitoSans-SemiBold",
                        fontSize: 12,
                        textAlign: 'center'
                      }}>{
                          buy1Free1ItemSkuDict[item.sku] !== undefined
                            ?
                            `Buy ${buy1Free1ItemSkuDict[item.sku].buyAmount} Free ${buy1Free1ItemSkuDict[item.sku].getAmount}`
                            :
                            `Buy ${buy1Free1Category.buyAmount} Free ${buy1Free1Category.getAmount}`
                        }</Text>
                    </View>
                    :
                    <></>
                }
              </View>

              <View style={{
                marginLeft: 15,
                // flexDirection: 'row',
                // flexShrink: 1,
                width: isMobile() ? '55%' : Dimensions.get('window').width * 0.45,
                // backgroundColor: 'red',
              }}>
                <Text
                  // numberOfLines={1} 
                  style={[itemNameTextScale, {
                    //fontSize: 16,
                    textTransform:
                      'uppercase',
                    fontFamily: "NunitoSans-Bold",
                    // flexWrap: 'wrap',
                    // flex: 1,
                    // flexShrink: 1,    
                    // width: '100%',
                  }]} numberOfLines={3}>{item.name}</Text>

                <View style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                  <Text style={{
                    color: Colors.primaryColor,
                    fontFamily: "NunitoSans-Bold",
                    paddingTop: 5,
                    fontSize: 16,
                    textDecorationLine:
                      (overrideItemPriceSkuDict[item.sku] !== undefined ||
                        overrideCategoryPrice !== undefined)
                        ?
                        'line-through'
                        :
                        'none',
                  }}
                  >RM{
                      parseFloat(item.price).toFixed(2)
                    }</Text>

                  {overrideItemPriceSkuDict[item.sku] !== undefined ||
                    overrideCategoryPrice !== undefined
                    ?
                    <Text style={{
                      color: Colors.secondaryColor,
                      fontFamily: "NunitoSans-Bold",
                      paddingTop: 5,
                      fontSize: 16,
                      marginLeft: 5,
                    }}>RM{
                        parseFloat((overrideItemPriceSkuDict[item.sku] || overrideCategoryPrice)).toFixed(2)
                      }</Text>
                    :
                    <></>
                  }
                </View>

                <View style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                  {(amountOffItemSkuDict[item.sku] !== undefined ||
                    amountOffCategory !== undefined)
                    ?
                    <Text style={{
                      color: Colors.descriptionColor,
                      fontFamily: "NunitoSans-SemiBold",
                      paddingTop: 5,
                      fontSize: 14,
                      // marginLeft: 5,
                    }}>{
                        amountOffItemSkuDict[item.sku] !== undefined
                          ?
                          `Buy ${amountOffItemSkuDict[item.sku].quantityMin} ~ ${amountOffItemSkuDict[item.sku].quantityMax} pcs to enjoy RM${amountOffItemSkuDict[item.sku].amountOff.toFixed(2)} off\n(Min ~ max purchases: RM${amountOffItemSkuDict[item.sku].priceMin}~${amountOffItemSkuDict[item.sku].priceMax})`
                          :
                          `Buy ${amountOffCategory.quantityMin} ~ ${amountOffCategory.quantityMax} pcs to enjoy ${amountOffCategory.amountOff.toFixed(0)}% off\n(Min ~ max purchases: RM${amountOffCategory.priceMin}~${amountOffCategory.priceMax})`
                      }</Text>
                    :
                    <></>
                  }
                </View>

                <View style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                  {(percentageOffItemSkuDict[item.sku] !== undefined ||
                    percentageOffCategory !== undefined)
                    ?
                    <Text style={{
                      color: Colors.descriptionColor,
                      fontFamily: "NunitoSans-SemiBold",
                      paddingTop: 5,
                      fontSize: 14,
                      // marginLeft: 5,
                    }}>{
                        percentageOffItemSkuDict[item.sku] !== undefined
                          ?
                          `Buy ${percentageOffItemSkuDict[item.sku].quantityMin} ~ ${percentageOffItemSkuDict[item.sku].quantityMax} pcs to enjoy ${percentageOffItemSkuDict[item.sku].percentageOff.toFixed(0)}% off\n(Min ~ max purchases: RM${percentageOffItemSkuDict[item.sku].priceMin}~${percentageOffItemSkuDict[item.sku].priceMax})`
                          :
                          `Buy ${percentageOffCategory.quantityMin} ~ ${percentageOffCategory.quantityMax} pcs to enjoy ${percentageOffCategory.percentageOff.toFixed(0)}% off\n(Min ~ max purchases: RM${percentageOffCategory.priceMin}~${percentageOffCategory.priceMax})`
                      }</Text>
                    :
                    <></>
                  }
                </View>
              </View>
            </View>

            <View style={{
              flexDirection: 'row',
              // width: "20%", 
              // marginLeft: 60 
              // backgroundColor: 'red',
              ...!isMobile() && {
                // width: Dimensions.get('window').width,
                height: 26,
                // right: 0,
                alignSelf: 'center',
              },
            }}>
              <View style={{
                backgroundColor: "#e3e1e1",
                // width: 67, 
                // height: 24,

                width: 68,
                height: 26,

                // paddingVertical: 4,
                // paddingHorizontal: 20, 

                borderRadius: 10,
                justifyContent: "center",
                alignSelf: "center"
              }}>
                <TouchableOpacity onPress={() => {
                  if (checkCartOutlet()) {
                    // setState({
                    //   cartWarning: true,
                    //   cartProceed: item
                    // })

                    setTempItem(item);

                    setCartWarning(true);
                  } else {
                    if (item.isActive) {
                      CommonStore.update(s => {
                        s.selectedOutletItem = item;
                      });

                      linkTo && linkTo(`${prefix}/outlet/menu/item`);

                      // props.navigation.navigate('MenuItemDetails', { refresh: refresh.bind(this), menuItem: item, outletData: selectedOutlet })
                    }
                    else {
                      Alert.alert(
                        'Info',
                        'Sorry, this product is not available for now.',
                      );
                    }
                  }

                }}>
                  <Text style={{
                    alignSelf: "center",
                    color: "#8f8f8f",
                    fontSize: 13,
                    fontFamily: "NunitoSans-Bold"
                  }}>{quantity > 0 ? quantity : "Add"}</Text>

                </TouchableOpacity>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      );
    }
  }

  // onContainerScroll = e => {
  //   console.log(Dimensions.get('window').width);
  //   console.log(e.nativeEvent.contentOffset.y);
  //   console.log('---------------------------')

  //   if (e.nativeEvent.contentOffset.y * 2 >= Dimensions.get('window').width) {
  //     console.log('hit top');

  //     // setState({
  //     //   isInfoTabHitTop: true,
  //     // });
  //   }
  //   else {
  //     console.log('not hit top');

  //     // setState({
  //     //   isInfoTabHitTop: false,
  //     // });
  //   }
  // }
  const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
    const paddingToBottom = 20;
    return layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom;
  };

  const nextCategory = () => {
    const catLength = categoryOutlet.length
    if (categoryIndex == catLength - 1) {
      setState({
        category: categoryOutlet[0],
        categoryIndex: 0,
        // menu: choice[index].category,

      })
    } else {
      setState({
        category: categoryOutlet[categoryIndex + 1],
        categoryIndex: categoryIndex + 1,
        // menu: choice[index].category,

      })
    }
    refreshMenu();
  }

  const checkCartOutlet = () => {
    // const outletId = outletData.id
    // console.log(Cart.getOutletId() != null)
    // if (selectedOutlet && selectedOutlet.uniqueId != Cart.getOutletId() && Cart.getOutletId() != null) {
    if (selectedOutlet && cartOutletId && selectedOutlet.uniqueId !== cartOutletId) {
      return true
    }
    return false
  }

  const clearCartItems = async () => {
    await AsyncStorage.removeItem(`${firebaseUid}.cartItems`);
    await AsyncStorage.removeItem(`${firebaseUid}.cartOutletId`);

    CommonStore.update(s => {
      s.cartItems = [];
      s.cartItemsProcessed = [];

      s.cartOutletId = null;
    });
  };

  // function end

  return (
    <View style={styles.container}>
      <Modal
        style={{ flex: 1 }}
        visible={cartWarning}
        transparent={true}
        animationType="slide">
        <View
          style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: Dimensions.get('window').height,
          }}>
          <View style={styles.confirmBox}>
            <TouchableOpacity
              onPress={() => {
                // setState({ cartWarning: false });
                setCartWarning(false);
              }}>
              <View
                style={{
                  alignSelf: 'flex-start',
                  padding: 14,
                }}>
                <Close name="close" size={28} color={"#b0b0b0"} />
              </View>
            </TouchableOpacity>
            <View style={{ marginBottom: 10, paddingHorizontal: 10 }}>
              <Text
                style={{
                  textAlign: 'center',
                  // fontWeight: '700',
                  fontSize: 18,
                  fontFamily: 'NunitoSans-Bold',
                }}>
                You are entering a different outlet
              </Text>
              <Text
                style={{
                  textAlign: 'center',
                  // fontWeight: '700',
                  fontSize: 14,
                  fontFamily: 'NunitoSans-Regular',
                  marginTop: 10,
                }}>
                Your existing cart items will be cleared if you proceed. Are you sure?
              </Text>
            </View>
            <View
              style={{
                alignSelf: 'center',
                marginTop: 30,
                justifyContent: 'center',
                alignItems: 'center',
                // width: 250,
                // height: 40,
                alignContent: 'center',
                marginTop: 12
              }}>
              <TouchableOpacity
                style={{
                  backgroundColor: Colors.primaryColor,
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignContent: 'center',
                  borderRadius: 10,
                  // height: 60,
                  // marginTop: 30,
                  alignSelf: 'center',

                  width: Dimensions.get('window').width * 0.5,
                  // paddingHorizontal: 60,
                  paddingVertical: 10,
                }} onPress={async () => {
                  // setState({ cartWarning: false, })
                  // Cart.clearCart();
                  setCartWarning(false);

                  await clearCartItems();

                  if (tempItem.isActive) {
                    CommonStore.update(s => {
                      s.selectedOutletItem = tempItem;
                    });

                    linkTo && linkTo(`${prefix}/outlet/menu/item`);

                    // props.navigation.navigate('MenuItemDetails', {
                    //   refresh: refresh.bind(this), menuItem: tempItem,
                    //   outletData: selectedOutlet
                    // })
                  }
                  else {
                    Alert.alert(
                      'Info',
                      'Sorry, this product is not available for now.',
                    );
                  }
                }}>
                <Text style={{ fontSize: 20, color: Colors.whiteColor, fontFamily: 'NunitoSans-SemiBold' }}>
                  Proceed
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => { setCartWarning(false); }}
                style={{
                  backgroundColor: Colors.secondaryColor,
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignContent: 'center',
                  borderRadius: 10,
                  // height: 60,

                  width: Dimensions.get('window').width * 0.5,
                  marginTop: 10,
                  // paddingHorizontal: 60,
                  paddingVertical: 10,
                }}>
                <Text style={{ fontSize: 20, color: Colors.whiteColor, fontFamily: 'NunitoSans-SemiBold' }}>
                  Take me back
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <ScrollView showsHorizontalScrollIndicator={false}
        // onScroll={onContainerScroll}
        onScroll={({ nativeEvent }) => {
          if (isCloseToBottom(nativeEvent)) {
            //console.log("HIT BOTTOM")
            //nextCategory()
          }
        }}
      // stickyHeaderIndices={[1]}
      >
        {console.log('selectedOutlet')}
        {console.log(selectedOutlet)}

        {
          isMobile()
            ?
            <AsyncImage
              source={{ uri: selectedOutlet && selectedOutlet.cover ? selectedOutlet.cover : '' }}
              style={styles.outletCover}
            />
            :
            <View style={{
              width: '100%',
              // height: 300,
              height: Dimensions.get('window').height * 0.5,
            }}>
              <AsyncImage
                source={{ uri: selectedOutlet && selectedOutlet.cover ? selectedOutlet.cover : '' }}
                style={styles.outletCover}
              />
            </View>
        }

        <View>
          <View style={{
            position: 'absolute', alignItems: 'center', paddingVertical: 12,
            width: 20,
            height: 49, backgroundColor: Colors.highlightColor, opacity: 0.85,
            justifyContent: 'center',
            shadowColor: '#000',
            shadowOffset: {
              width: 1,
              height: 0.5,
            },
            shadowOpacity: 0.12,
            shadowRadius: 3.22,
          }}>
            <Entypo name='chevron-thin-left' size={20} color={Colors.primaryColor} style={{ marginLeft: -1, }} />
          </View>
          <View style={{
            position: 'absolute', alignItems: 'center', alignSelf: 'flex-end', paddingVertical: 12,
            width: 20,
            height: 49, backgroundColor: Colors.highlightColor, opacity: 0.85,
            justifyContent: 'center',
            shadowColor: '#000',
            shadowOffset: {
              width: 1,
              height: 0.5,
            },
            shadowOpacity: 0.12,
            shadowRadius: 3.22,
          }}>
            <Entypo name='chevron-thin-right' size={20} color={Colors.primaryColor} style={{ marginLeft: -1, }} />
          </View>

          <View>
            <ScrollView
              showsHorizontalScrollIndicator={isMobile() ? false : true}
              alwaysBounceHorizontal={true}
              horizontal={true}
              contentContainerStyle={{
                paddingLeft: 20,
              }}
              style={[styles.infoTab, {
                // ...!isInfoTabHitTop && { position: 'absolute' },
                // ...!isInfoTabHitTop && { top: 120 },
                zIndex: -1,
                // backgroundColor: 'red',
                // width: Dimensions.get('window').width * 0.4,
                ...!isMobile() && {
                  width: Dimensions.get('window').width,
                },
              }]}
            // stickyHeaderIndices={[0]} 
            >
              {/* {categoryOutlet.map((item, index) => {
              return (
                <TouchableOpacity
                  onPress={() => {
                    setState({
                      category: item.label,
                      categoryIndex: index,
                      // menu: choice[index].category,

                    })
                    refreshMenu();
                  }}>
                  <View
                    style={[
                      styles.category,
                      {
                        borderBottomColor:
                          category == item.label
                            ? Colors.primaryColor
                            : null,
                        borderBottomWidth:
                          category == item.label ? 3 : 0,
                      },
                    ]}>
                    <Text
                      style={{
                        textTransform: 'capitalize',
                        paddingVertical: 12,
                        fontFamily: category == item.label
                          ? "NunitoSans-Bold"
                          : "NunitoSans-Regular",
                        color: category == item.label
                          ? Colors.primaryColor
                          : Colors.blackColor,
                        fontSize: 15,
                      }}>
                      {item.label}
                    </Text>
                  </View>
                </TouchableOpacity>
              );
            })} */}

              {!isSwitchingOutlets && selectedOutletItemCategories.map((item, index) => {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      // setState({
                      //   category: item.label,
                      //   categoryIndex: index,
                      //   // menu: choice[index].category,

                      // })
                      // refreshMenu();

                      CommonStore.update(s => {
                        s.selectedOutletItemCategory = item;
                      });
                    }}>
                    <View
                      style={[
                        styles.category,
                        {
                          // borderBottomColor:
                          //   selectedOutletItemCategory.name == item.name
                          //     ? Colors.primaryColor
                          //     : null,
                          // borderBottomWidth:
                          //   selectedOutletItemCategory.name == item.name ? 3 : 0,
                        },
                      ]}>
                      <View style={{
                        borderBottomColor:
                          selectedOutletItemCategory.name == item.name
                            ? Colors.primaryColor
                            : null,
                        borderBottomWidth:
                          selectedOutletItemCategory.name == item.name ? 3 : 0,
                      }}>
                        <Text
                          style={{
                            textTransform: 'capitalize',
                            paddingVertical: 12,
                            fontFamily: selectedOutletItemCategory.name == item.name
                              ? "NunitoSans-Bold"
                              : "NunitoSans-Regular",
                            color: selectedOutletItemCategory.name == item.name
                              ? Colors.primaryColor
                              : Colors.mainTxtColor,
                            fontSize: 16,
                          }}>
                          {item.name}
                        </Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                );
              })}
            </ScrollView>
          </View>

          {console.log('isSwitchingOutlets')}
          {console.log(isSwitchingOutlets)}
          {console.log('selectedOutletItems')}
          {console.log(selectedOutletItems)}

          {!isSwitchingOutlets ?
            <FlatList
              data={selectedOutletItems}
              extraData={selectedOutletItems}
              renderItem={renderMenu}
              keyExtractor={(item, index) => index}
              contentContainerStyle={{
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 20,
              }}
            />
            : <View style={{
              width: '100%',
              height: Dimensions.get('window').height * 0.5,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <ActivityIndicator color={Colors.primaryColor} size={'large'} />
            </View>
          }
        </View>
        <View style={{ minHeight: 100 }} />
      </ScrollView>

      {/* {cartIcon ?
        <Draggable
          shouldReverse={reverse}
          renderSize={100}
          renderColor={Colors.secondaryColor}
          isCircle
          x={270}
          y={470}
          // onShortPressRelease={() => { goToCart(), cartCount() }}
          onShortPressRelease={onCartClicked}
        >
          <View style={{ width: 60, height: 60, justifyContent: "center" }}>
            <View style={{ alignSelf: "center" }}>
              <Ionicons name="cart-outline" size={42} color={Colors.mainTxtColor} />
            </View>
            <View style={styles.cartCount}>              
              <Text style={{
                color: Colors.whiteColor,
                fontSize: 14,
                fontFamily: "NunitoSans-Bold"
              }}>{cartItems.length}</Text>
            </View>
          </View>
        </Draggable>
        : null} */}
    </View>
  )
}

const styles = StyleSheet.create({
  confirmBox: {
    width: 350,
    // height: Dimensions.get('window').height * 0.35,
    height: 285,
    borderRadius: 20,
    backgroundColor: Colors.whiteColor,
  },
  container: {
    // flex: 1,
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
    backgroundColor: '#ffffff',
    position: 'relative',
  },
  outletCover: {
    width: isMobile() ? '100%' : Dimensions.get('window').width,
    alignSelf: 'center',
    height: isMobile() ? undefined : Dimensions.get('window').height * 0.5,
    aspectRatio: isMobile() ? 2 : 2,
    resizeMode: isMobile() ? 'stretch' : 'stretch',
    ...!isMobile() && {
      // position: 'absolute',
      // left: 0,

      // marginRight: Dimensions.get('window').width * 0.6,
      // left: 0,
    },
    // borderRadius: 5,
  },
  infoTab: {
    backgroundColor: Colors.fieldtBgColor,
  },
  workingHourTab: {
    padding: 16,
    flexDirection: 'row',
  },
  outletAddress: {
    textAlign: 'center',
    color: Colors.mainTxtColor,
  },
  outletName: {
    fontWeight: 'bold',
    fontSize: 20,
    marginBottom: 10,
  },
  logo: {
    width: 100,
    height: 100,
  },
  actionTab: {
    flexDirection: 'row',
    marginTop: 20,
  },
  actionView: {
    width: Styles.width / 4,
    height: Styles.width / 4,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  actionBtn: {
    borderRadius: 50,
    width: 70,
    height: 70,
    borderColor: Colors.secondaryColor,
    borderWidth: StyleSheet.hairlineWidth,
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionText: {
    fontSize: 12,
    marginTop: 10,
  },
  category: {
    // width: 150,
    paddingHorizontal: 25,
    justifyContent: 'center',
    alignItems: 'center',
  },
  floatCartBtn: {
    zIndex: 2,
    position: 'absolute',
    bottom: 30,
    right: 30,
    width: 60,
    height: 60,
    borderRadius: 60 / 2,
    backgroundColor: Colors.secondaryColor,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
  },
  cartCount: {
    position: 'absolute',
    top: -12,
    right: -10,
    backgroundColor: Colors.primaryColor,
    width: 30,
    height: 30,
    borderRadius: 30 / 2,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
export default OutletMenuScreen;
