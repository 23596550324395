import { Alert } from 'react-native';
import * as Token from '../util/Token';
import User from '../util/User';
import { apiUrl } from '../constant/env';
import AsyncStorage from '@react-native-async-storage/async-storage'
import axios from 'axios';

const client = axios.create({
    baseURL: apiUrl,
    timeout: 7000,
});

client.interceptors.request.use(async options => {
    // let token = Token.getToken();
    // options.headers['Test'] = `Test`;
    const token = await AsyncStorage.getItem('accessToken');
    // options.headers['Authorization'] = token ? `Bearer ${token}` : `Firebase ${Token.getFirebaseToken()}`;
    options.headers['Authorization'] = `Bearer ${token}`;
    return options;
});

const logoutUser = async function () {
    await AsyncStorage.clear();
    // Token.clear();
    // User.setlogin(false);
    // User.getRefreshMainScreen();
};

client.interceptors.response.use(
    response => {
        return response;
    },
    async function (error) {
        console.error(error.response);

        const originalRequest = error.config;
        if (error.response && error.response.status === 401) {
            if (!originalRequest._retry) {
                originalRequest._retry = true;
                const refreshToken = Token.getRefreshToken();
                if (refreshToken) {
                    try {
                        const refreshRes = await client.get(
                            `/token?ctoken=REFRESH:${refreshToken}`,
                        );
                        if (refreshRes.data) {
                            Token.setToken(refreshRes.data.token);
                            Token.setRefreshToken(refreshRes.data.refreshToken);
                            return client(originalRequest);
                        }
                    } catch (err) {
                        logoutUser();
                    }
                } else {
                    logoutUser();
                }
            } else {
                logoutUser();
            }            
        }

        return Promise.reject(error);
    },
);


////////////////////////////////////

const GET = async function (url) {
    try {
        const { data } = await client.get(url);
        if (data.error) {
            // Alert.alert("Error", data.message, [
            //     { text: "OK", onPress: () => { } }
            // ],
            //     { cancelable: false })
            // console.log("ERROR", url, data)
        }
        else {
            return data;
        }
    }
    catch (err) {
        // Alert.alert("Error", url + err.message, [
        //     { text: "OK", onPress: () => { } }
        // ],
        //     { cancelable: false })
        // console.log("ERROR", url, err)
    }
}

const POST = async function (url, params, config = {}) {
    try {
        // const result = await client.post(url, params || {}, {
        //     headers: {
        //         // 'Content-?Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        //         Accept: 'application/json',
        //         'Content-Type': 'application/json',
        //     }
        // });
        // console.log('post result');
        // console.log(result);
        // let data = {};

        const { data } = await client.post(url, params, config);
        if (data.error) {
            // Alert.alert("Error", data.message, [
            //     { text: "OK", onPress: () => { } }
            // ],
            //     { cancelable: false })
            // console.log("ERROR", url, data)
        }
        else {
            return data;
        }
    }
    catch (err) {
        // Alert.alert("Error", url + err.message, [
        //     { text: "OK", onPress: () => { } }
        // ],
        //     { cancelable: false })
        // console.log("ERROR", url, err)
    }
}

const PATCH = async function (url, params) {
    try {
        const { data } = await client.patch(url, params);
        if (data.error) {
            // Alert.alert("Error", data.message, [
            //     { text: "OK", onPress: () => { } }
            // ],
            //     { cancelable: false })
            // console.log("ERROR", url, data)
        }
        else {
            return data;
        }
    }
    catch (err) {
        // Alert.alert("Error", url + err, [
        //     { text: "OK", onPress: () => { } }
        // ],
        //     { cancelable: false })
        // console.log("ERROR", url, err)
    }
}

const PUT = async function (url, params) {
    try {
        const { data } = await client.put(url, params);
        if (data.error) {
            // Alert.alert("Error", data.message, [
            //     { text: "OK", onPress: () => { } }
            // ],
            //     { cancelable: false })
            // console.log("ERROR", url, data)
        }
        else {
            return data;
        }
    }
    catch (err) {
        // Alert.alert("Error", url + err, [
        //     { text: "OK", onPress: () => { } }
        // ],
        //     { cancelable: false })
        // console.log("ERROR", url, err)
    }
}

const ApiClient = {
    GET,
    POST,
    PATCH,
    PUT,
}

export default ApiClient
