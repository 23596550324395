export const PROMOTION_TYPE = {    
    OVERRIDE_EXISTING_PRICE: 'OVERRIDE_EXISTING_PRICE',
    BUY_A_GET_B_FOR_FREE: 'BUY_A_GET_B_FOR_FREE',    
    PWP: 'PWP',
    CASHBACK: 'CASHBACK',
    TAKE_AMOUNT_OFF: 'TAKE_AMOUNT_OFF',
    TAKE_PERCENTAGE_OFF: 'TAKE_PERCENTAGE_OFF',
    COMBO_SET_OR_PACKAGE_OR_BUNDLE: 'COMBO_SET_OR_PACKAGE_OR_BUNDLE',
};

export const PROMOTION_TYPE_VARIATION = {
    PRODUCT_OF_CATEGORY: 'PRODUCT_OF_CATEGORY',
    SPECIFIC_PRODUCTS: 'SPECIFIC_PRODUCTS',
};
