var token = null;
var refreshToken = null;
var firebaseToken = null;

export function getToken() {
    return token;
}

export function setToken(data) {
    token = data;
}

export function setRefreshToken(param) {
    refreshToken = param;
}

export function getRefreshToken() {
    return refreshToken;
}

export function setFirebaseToken(param) {
    firebaseToken = param;
}

export function getFirebaseToken() {
    return firebaseToken;
}