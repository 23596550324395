import React, { Component, useReducer, useState, useEffect } from "react";
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  TouchableOpacity,
  TextInput,
  FlatList,
  RefreshControl,
  Alert,
  Modal,
  Dimensions, ActivityIndicator,
  Platform
} from "react-native";
import Colors from "../constant/Colors";
import * as Cart from "../util/Cart";
import * as User from "../util/User";
import { isMobile, setRouteFrom } from '../util/commonFuncs';
import Styles from "../constant/Styles";
import ApiClient from "../util/ApiClient";
import API from "../constant/API";
import { mp_username, mp_password, mp_merchant_ID, mp_app_name, mp_verification_key, prefix } from '../constant/env';
import FontAwesome from "react-native-vector-icons/FontAwesome";
import Ionicons from "react-native-vector-icons/Ionicons";
import Icons from "react-native-vector-icons/Feather";
import Entypo from "react-native-vector-icons/Entypo";
import Close from "react-native-vector-icons/AntDesign";
import AntDesign from 'react-native-vector-icons/AntDesign';
// import NumericInput from "react-native-numeric-input";
import Back from "react-native-vector-icons/EvilIcons";
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
// import DateTimePickerModal from "react-native-modal-datetime-picker";
// import { SpinPicker } from 'react-native-spin-picker'
import moment from 'moment';
import { createIconSetFromFontello, createIconSetFromIcoMoon } from "react-native-vector-icons";
// import molpay from "molpay-mobile-xdk-reactnative-beta";
import { CommonStore } from "../store/commonStore";
import { ORDER_TYPE, COURIER_CODE, MERCHANT_VOUCHER_TYPE, USER_ORDER_PAYMENT_OPTIONS, SENDER_DROPDOWN_LIST, MPS_CHANNEL_LIST } from "../constant/common";
import firebase from 'firebase';
import { Collections } from "../constant/firebase";
import { UserStore } from "../store/userStore";
import AsyncStorage from "@react-native-async-storage/async-storage";
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';
import AsyncImage from "../components/asyncImage";
import { CommonActions, useLinkTo } from "@react-navigation/native";
import { PROMOTION_TYPE, PROMOTION_TYPE_VARIATION } from "../constant/promotions";
import { $dataMetaSchema } from "ajv";
import { DataStore } from "../store/dataStore";

/*
 * CartScreen
 * function:
 * *Displays the items inside the cart
 * *Allows user to edit the current items in the cart
 * *Shows other popular items for users to purchase
 * *Place order here, delivery, pickup or dine in
 * 
 * route.params:
 * *test: ??
 * *test1: if 1, redemption purchase
 * *test2: if 1, redemption extension
 * *paymentMethod: the selected payment method
 * *outletData: array of data of this cart is purchasing from
 * *navFrom: the navigation stack of this route, from takeaway or from outlet
 */

const CartScreen = props => {
  const {
    navigation,
    route,
  } = props;

  const linkTo = useLinkTo();

  // const { test, test1, test2, paymentMethod, outletData, navFrom } = route.params;
  // const testParam = route.params.test;
  // const test1Param = route.params.test1;
  // const test2Param = route.params.test2;
  // const paymentMethodParam = route.params.paymentMethod;
  // const outletDataParam = route.params.outletData;
  // const navFromParam = route.params.navFrom;

  // const [paymentMethod, setPaymentMethod] = useState(paymentMethodParam);
  const [cartItem, setCartItem] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [value, setValue] = useState("");
  const [editingItemId, setEditingItemId] = useState(null);
  // const [outletData, setOutletData] = useState(outletDataParam);
  const [menu, setMenu] = useState([]);
  const [menuItem, setMenuItem] = useState([]);
  const [category, setCategory] = useState([]);
  const [outletMenu, setOutletMenu] = useState([]);
  const [menuItemDetails, setMenuItemDetails] = useState([]);
  const [qty, setQty] = useState([]);
  // const [test, setTest] = useState(testParam);
  // const [test1, setTest1] = useState(test1Param);
  // const [test2, setTest2] = useState(test2Param);
  const [popularOutlet, setPopularOutlet] = useState([]);
  const [popular, setPopular] = useState([]);
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [pickerMode, setPickerMode] = useState('datetime');
  const [currentMenu, setCurrentMenu] = useState([]);
  const [type, setType] = useState(Cart.getOrderType()); // 0 "Dine In"
  const [schedulteTimeList, setSchedulteTimeList] = useState([]);
  const [schedulteTimeSelected, setSchedulteTimeSelected] = useState('');
  const [totalFloat, setTotalFloat] = useState(0);
  const [taxFloat, setTaxFloat] = useState(0);
  // const [navFrom, setNavFrom] = useState(navFromParam);
  const [deliveryQuotation, setDeliveryQuotation] = useState({});
  const [clicked, setClicked] = useState(1); //delivery
  const [clicked1, setClicked1] = useState(0); //pickup
  const [rev_date, setRev_date] = useState(undefined);

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);

  const [promoCode, setPromoCode] = useState('');

  const [totalPrepareTime, setTotalPrepareTime] = useState(0);

  const [orderIdLocal, setOrderIdLocal] = useState(uuidv4());
  const [orderIdServer, setOrderIdServer] = useState('');

  const [mpsChannel, setMpsChannel] = useState('');
  const [showMpsChannelModal, setShowMpsChannelModal] = useState(false);

  const [showUserInfoModal, setShowUserInfoModal] = useState(false);

  const isLoading = CommonStore.useState(s => s.isLoading);
  const isPlacingOrder = CommonStore.useState(s => s.isPlacingOrder);

  const cartItems = CommonStore.useState(s => s.cartItems);
  const cartOutletItemsDict = CommonStore.useState(s => s.cartOutletItemsDict);
  const cartOutletItemAddOnDict = CommonStore.useState(s => s.cartOutletItemAddOnDict);
  const cartOutletItemAddOnChoiceDict = CommonStore.useState(s => s.cartOutletItemAddOnChoiceDict);

  const selectedOutletItemCategoriesDict = CommonStore.useState(s => s.selectedOutletItemCategoriesDict);

  const cartItemsProcessed = CommonStore.useState(s => s.cartItemsProcessed);

  const outletsTaxDict = CommonStore.useState(s => s.outletsTaxDict);

  const orderType = CommonStore.useState(s => s.orderType);

  const selectedOutletTableId = CommonStore.useState(s => s.selectedOutletTableId);
  const selectedOutletTablePax = CommonStore.useState(s => s.selectedOutletTablePax);
  const selectedOutletTableCode = CommonStore.useState(s => s.selectedOutletTableCode);
  const userCart = CommonStore.useState(s => s.userCart);

  const selectedOutletWaiterName = CommonStore.useState(s => s.selectedOutletWaiterName);
  const selectedOutletWaiterId = CommonStore.useState(s => s.selectedOutletWaiterId);

  const selectedOutlet = CommonStore.useState(s => s.selectedOutlet);
  // const lat = CommonStore.useState(s => s.lat);
  // const lng = CommonStore.useState(s => s.lng);

  const merchantVouchersDict = CommonStore.useState(s => s.merchantVouchersDict);
  const selectedVoucherId = UserStore.useState(s => s.selectedVoucherId);

  const selectedUserAddress = UserStore.useState(s => s.selectedUserAddress);
  const firebaseUid = UserStore.useState(s => s.firebaseUid);
  const userName = UserStore.useState(s => s.name);
  const userNumber = UserStore.useState(s => s.number);
  const userEmail = UserStore.useState(s => s.email);

  const userGroups = UserStore.useState(s => s.userGroups);

  const availablePromotions = CommonStore.useState(s => s.availablePromotions);
  const overrideItemPriceSkuDict = CommonStore.useState(s => s.overrideItemPriceSkuDict);
  const amountOffItemSkuDict = CommonStore.useState(s => s.amountOffItemSkuDict);
  const percentageOffItemSkuDict = CommonStore.useState(s => s.percentageOffItemSkuDict);
  const buy1Free1ItemSkuDict = CommonStore.useState(s => s.buy1Free1ItemSkuDict);

  const overrideCategoryPriceNameDict = CommonStore.useState(s => s.overrideCategoryPriceNameDict);
  const amountOffCategoryNameDict = CommonStore.useState(s => s.amountOffCategoryNameDict);
  const percentageOffCategoryNameDict = CommonStore.useState(s => s.percentageOffCategoryNameDict);
  const buy1Free1CategoryNameDict = CommonStore.useState(s => s.buy1Free1CategoryNameDict);

  //////////////////////////////////////////////////////

  const [lalamoveQuotation, setLalamoveQuotation] = useState({}); // { totalFee: "0.00", totalFeeCurrency: "MYR" }

  const [paymentOptionsList, setPaymentOptionsList] = useState([]);
  const [selectedPaymentOptions, setSelectedPaymentOptions] = useState({});
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);

  const [senderList, setSenderList] = useState(SENDER_DROPDOWN_LIST);
  const [voucherList, setVoucherList] = useState(
    [
      // {
      //   label: 'Birthday Promotion',
      //   value: 'Birthday',
      // },
      // {
      //   label: 'Beverage Off',
      //   value: 'DRINKS',
      // },
    ]
  );

  const [selectedSender, setSelectedSender] = useState({});
  const [selectedVoucher, setSelectedVoucher] = useState({});
  const [showSender, setShowSender] = useState(false);
  const [showVoucher, setShowVoucher] = useState(false);

  const [cartItemsTotalQuantity, setCartItemsTotalQuantity] = useState(0);

  const [pointsToRedeem, setPointsToRedeem] = useState(0);
  const [pointsToRedeemPackageIdList, setPointsToRedeemPackageIdList] = useState([]);
  const [pointsToRedeemAmountList, setPointsToRedeemAmountList] = useState([]);
  const [pointsToRedeemDiscount, setPointsToRedeemDiscount] = useState(0);
  const [usePointsToRedeem, setUsePointsToRedeem] = useState(false);
  const [showPointsToRedeemOptions, setShowPointsToRedeemOptions] = useState(false);

  // const [userInfoName, setUserInfoName] = useState('');
  // const [userInfoPhone, setUserInfoPhone] = useState('');
  const userInfoName = UserStore.useState(s => s.userInfoName);
  const userInfoPhone = UserStore.useState(s => s.userInfoPhone);

  const userPointsBalance = UserStore.useState(s => s.userPointsBalance);

  const pointsRedeemItemSkuDict = CommonStore.useState(s => s.pointsRedeemItemSkuDict);
  const pointsRedeemCategoryNameDict = CommonStore.useState(s => s.pointsRedeemCategoryNameDict);

  const selectedOutletItemsSkuDict = CommonStore.useState(s => s.selectedOutletItemsSkuDict);

  const voucherIdRedemptionList = UserStore.useState(s => s.voucherIdRedemptionList);

  const paymentDetails = CommonStore.useState(s => s.paymentDetails);

  //////////////////////////////////////////////////////

  useEffect(() => {
    if (paymentDetails !== null) {
      const paymentDetailsResult = paymentDetails;

      if (!isLoading) {
        CommonStore.update(s => {
          s.paymentDetails = null;
        }, () => {
          if (paymentDetailsResult.statusCode === '00') {
            placeUserOrder(paymentDetailsResult);
          }
          else {
            // error

            CommonStore.update(s => {
              s.alertObj = {
                title: 'Error',
                message: 'Payment failed. Please try again.',
              }
            });
          }
        });
      }
    }
  }, [paymentDetails, isLoading]);

  useEffect(() => {
    if (orderType === ORDER_TYPE.DINEIN && userCart.uniqueId === undefined) {
      linkTo(`${prefix}/scan`);
    }
  }, [userCart, orderType]);

  useEffect(() => {
    if (linkTo) {
      DataStore.update(s => {
        s.linkToFunc = linkTo;
      });
    }
  }, [linkTo]);


  useEffect(() => {
    var voucherListTemp = [];

    for (var i = 0; i < voucherIdRedemptionList.length; i++) {
      const record = voucherIdRedemptionList[i];

      // to-do: check for start/end date/time, min/max spend
      if (record.usageDate === null) {
        var voucherLogoSource = {
          uri: '',
        };

        if (merchantVouchersDict[record.voucherId]) {
          if (merchantVouchersDict[record.voucherId].customLogo) {
            voucherLogoSource = {
              uri: merchantVouchersDict[record.voucherId].customLogo,
            };
          }
          else if (merchantVouchersDict[record.voucherId].merchantLogo) {
            voucherLogoSource = {
              uri: merchantVouchersDict[record.voucherId].merchantLogo,
            };
          }
          else {
            voucherLogoSource = require('../asset/image/extend.png');
          }
        }

        voucherListTemp.push({
          label: (merchantVouchersDict[record.voucherId] ? merchantVouchersDict[record.voucherId].title : '') + ` [${record.code}]`,
          value: record.voucherId,
          code: record.code,
          img: voucherLogoSource,
        });
      }
    }

    setVoucherList(voucherListTemp);
  }, [voucherIdRedemptionList, merchantVouchersDict]);

  useEffect(() => {
    CommonStore.update(s => {
      s.currPage = 'Cart';
    });

    return () => {
      CommonStore.update(s => {
        s.currPage = '';
      });
    };
  }, []);

  useEffect(() => {
    if (selectedOutlet && selectedOutlet.uniqueId && orderType) {
      var paymentOptionsTemp = '';

      if (orderType === ORDER_TYPE.DELIVERY) {
        paymentOptionsTemp = selectedOutlet.deliveryPaymentOptions;
      }
      else if (orderType === ORDER_TYPE.PICKUP) {
        paymentOptionsTemp = selectedOutlet.pickupPaymentOptions;
      }
      else if (orderType === ORDER_TYPE.DINEIN) {
        paymentOptionsTemp = selectedOutlet.dineinPaymentOptions;
      }

      var paymentOptionsListTemp = [];

      if (paymentOptionsTemp === USER_ORDER_PAYMENT_OPTIONS.ONLINE) {
        paymentOptionsListTemp.push({
          label: 'Online',
          value: USER_ORDER_PAYMENT_OPTIONS.ONLINE,
        });
      }
      else if (paymentOptionsTemp === USER_ORDER_PAYMENT_OPTIONS.OFFLINE) {
        paymentOptionsListTemp.push({
          label: 'Offline',
          value: USER_ORDER_PAYMENT_OPTIONS.OFFLINE,
        });
      }
      else if (paymentOptionsTemp === USER_ORDER_PAYMENT_OPTIONS.ALL) {
        paymentOptionsListTemp.push({
          label: 'Online',
          value: USER_ORDER_PAYMENT_OPTIONS.ONLINE,
        });

        paymentOptionsListTemp.push({
          label: 'Offline',
          value: USER_ORDER_PAYMENT_OPTIONS.OFFLINE,
        });
      }

      setPaymentOptionsList(paymentOptionsListTemp);

      setSelectedPaymentOptions(paymentOptionsListTemp[0]);
    }
  }, [selectedOutlet, orderType]);

  useEffect(() => {
    if (selectedOutlet && selectedOutlet.uniqueId && orderType) {
      // var senderListTemp = [
      //   {
      //     label: 'Lalamove',
      //     value: COURIER_CODE.LALAMOVE,
      //   },
      //   {
      //     label: 'Mr. Speedy',
      //     value: COURIER_CODE.MRSPEEDY,
      //   },
      // ];

      // setSenderList(senderListTemp);

      setSelectedSender(senderList[0]);
    }
  }, [selectedOutlet, orderType]);

  useEffect(() => {
    console.log('utc time');
    console.log(moment().utc().toISOString());

    console.log('delay utc time');
    console.log(moment().add(totalPrepareTime, 'second').utc().toISOString());

    console.log('current time');
    console.log(moment().toISOString());

    console.log('delay current time');
    console.log(moment().add(totalPrepareTime, 'second').toISOString());

    console.log('totalPrepareTime');
    console.log(totalPrepareTime);

    if (orderType === ORDER_TYPE.DELIVERY &&
      selectedUserAddress &&
      selectedUserAddress.uniqueId &&
      selectedOutlet &&
      selectedOutlet.uniqueId &&
      totalPrepareTime > 0) {

      if (selectedSender.value === COURIER_CODE.LALAMOVE) {
        var body = {
          outletLat: selectedOutlet.lat,
          outletLng: selectedOutlet.lng,
          outletAddress: selectedOutlet.address,

          outletPhone: selectedOutlet.phone,
          outletName: selectedOutlet.name,

          userLat: selectedUserAddress.lat,
          userLng: selectedUserAddress.lng,
          userAddress: selectedUserAddress.address,

          userName: userName,
          userPhone: userNumber,
          userRemarks: selectedUserAddress.note,

          scheduleAt: moment().add(totalPrepareTime, 'second').add(15, 'minute').utc().toISOString(),
        };

        console.log('quotation body');
        console.log(body);

        ApiClient.POST(API.lalamoveQuotation, body).then((result) => {
          console.log("lalamove quotation result");

          if (result === undefined) {
            // means lalamove can't deliver to this address

            Alert.alert(
              'Info',
              'Sorry, we unable to deliver to this address, please try another one.',
            );

            setDeliveryQuotation({});
          }
          else if (result && result.totalFee) {
            // { totalFee: "0.00", totalFeeCurrency: "MYR" }

            setDeliveryQuotation({
              totalFee: parseFloat(result.totalFee),
              totalFeeCurrency: result.totalFeeCurrency,
              courierCode: COURIER_CODE.LALAMOVE,
            });
          }
        });
      }
      else if (selectedSender.value === COURIER_CODE.MRSPEEDY) {
        const totalWeightKg = Math.ceil(cartItemsTotalQuantity * 0.2); // for now we assume each item weighted 200 gram

        var body = {
          outletLat: selectedOutlet.lat,
          outletLng: selectedOutlet.lng,
          outletAddress: selectedOutlet.address,

          outletPhone: selectedOutlet.phone,
          outletName: selectedOutlet.name,

          userLat: selectedUserAddress.lat,
          userLng: selectedUserAddress.lng,
          userAddress: selectedUserAddress.address,

          userName: userName,
          userPhone: userNumber,
          userRemarks: selectedUserAddress.note,

          totalWeightKg: totalWeightKg,
          outletRequiredStartDatetime: moment().add(totalPrepareTime, 'second').add(5, 'minute').utc().toISOString(),
          outletRequiredFinishDatetime: moment().add(totalPrepareTime, 'second').add(10, 'minute').utc().toISOString(),
          userRequiredStartDatetime: moment().add(totalPrepareTime, 'second').add(15, 'minute').utc().toISOString(),
          userRequiredFinishDatetime: moment().add(totalPrepareTime, 'second').add(30, 'minute').utc().toISOString(),
        };

        console.log('quotation body');
        console.log(body);

        ApiClient.POST(API.mrSpeedyCalculateOrder, body).then((result) => {
          console.log("mrspeedy quotation result");
          console.log(result);

          if (!result || !result.is_successful) {
            // means lalamove can't deliver to this address

            Alert.alert(
              'Info',
              'Sorry, we unable to deliver to this address, please try another one.',
            );

            setDeliveryQuotation({});
          }
          else if (result.is_successful && result.order && result.order.payment_amount) {
            // { totalFee: "0.00", totalFeeCurrency: "MYR" }

            setDeliveryQuotation({
              totalFee: parseFloat(result.order.payment_amount),
              totalFeeCurrency: 'MYR',
              courierCode: COURIER_CODE.MRSPEEDY,
            });
          }
        });
      }
      else if (selectedSender.value === COURIER_CODE.TELEPORT) {
        const totalWeightKg = Math.ceil(cartItemsTotalQuantity * 0.2); // for now we assume each item weighted 200 gram

        var body = {
          outletLat: selectedOutlet.lat,
          outletLng: selectedOutlet.lng,
          outletAddress: selectedOutlet.address,

          outletPhone: selectedOutlet.phone,
          outletName: selectedOutlet.name,

          userLat: selectedUserAddress.lat,
          userLng: selectedUserAddress.lng,
          userAddress: selectedUserAddress.address,

          userName: userName,
          userPhone: userNumber,
          userRemarks: selectedUserAddress.note,

          outletEmail: selectedOutlet.email,
          userEmail: userEmail,

          pickupDatetime: moment().add(totalPrepareTime, 'second').add(10, 'minute').utc().toISOString(),

          totalWeightKg: totalWeightKg,
        };

        console.log('quotation body');
        console.log(body);

        ApiClient.POST(API.teleportOrderEstimatePricing, body).then((result) => {
          console.log("teleport quotation result");
          console.log(result);

          if (!result) {
            // means teleport can't deliver to this address

            Alert.alert(
              'Info',
              'Sorry, we unable to deliver to this address, please try another one.',
            );

            setDeliveryQuotation({});
          }
          else if (result && result.total_amount) {
            // { totalFee: "0.00", totalFeeCurrency: "MYR" }

            setDeliveryQuotation({
              totalFee: parseFloat(result.total_amount),
              totalFeeCurrency: 'MYR',
              courierCode: COURIER_CODE.TELEPORT,
            });
          }
        });
      }
    }
  }, [
    selectedUserAddress,
    selectedOutlet,
    totalPrepareTime,
    orderType,

    selectedSender,
    cartItemsTotalQuantity,
  ]);

  useEffect(() => {
    if (selectedVoucherId && selectedOutlet && selectedOutlet.uniqueId) {
      if (merchantVouchersDict[selectedVoucherId] && merchantVouchersDict[selectedVoucherId].merchantId === selectedOutlet.merchantId) {
        // means valid

        if (merchantVouchersDict[selectedVoucherId].type === MERCHANT_VOUCHER_TYPE.CASH_VOUCHER) {
          setTotalDiscount(merchantVouchersDict[selectedVoucherId].value);
        }
        else if (merchantVouchersDict[selectedVoucherId].type === MERCHANT_VOUCHER_TYPE.PERCENTAGE_VOUCHER) {
          setTotalDiscount(totalPrice * merchantVouchersDict[selectedVoucherId].value / 100);
        }
        else {
          setTotalDiscount(0);
        }
      }
      else {
        setTotalDiscount(0);
      }
    }
    else {
      setTotalDiscount(0);
    }
  }, [selectedVoucherId, selectedOutlet, totalPrice]);

  useEffect(() => {
    // if (cartItems.length > 0) {
    //   updateCartItemsDict();
    // }

    updateCartItemsDict();
  }, [
    cartItems,
    cartItems.length,

    // availablePromotions,

    overrideItemPriceSkuDict,
    amountOffItemSkuDict,
    percentageOffItemSkuDict,
    buy1Free1ItemSkuDict,

    overrideCategoryPriceNameDict,
    amountOffCategoryNameDict,
    percentageOffCategoryNameDict,
    buy1Free1CategoryNameDict,

    usePointsToRedeem,
    userPointsBalance,
    pointsRedeemItemSkuDict,
    pointsRedeemCategoryNameDict,
  ]);

  const updateCartItemsDict = async () => {
    if (cartItems.length > 0) {
      CommonStore.update(s => {
        s.isLoading = true;
      });
    }

    var tempCartOutletItemsDict = {
      ...cartOutletItemsDict,
    };

    var tempCartOutletItemAddOnDict = {
      ...cartOutletItemAddOnDict,
    };

    var tempCartOutletItemAddOnChoiceDict = {
      ...cartOutletItemAddOnChoiceDict,
    };

    var tempOutletsTaxDict = {
      ...outletsTaxDict,
    };

    var tempCartItemsProcessed = [];

    var tempTotalPrice = 0;
    var tempTotalPrepareTime = 0;

    var currOutletId = '';

    var cartItemsTotalQuantityTemp = 0;

    var userPointsBalanceTemp = userPointsBalance;
    var pointsToRedeemPackageIdListTemp = [];
    var pointsToRedeemAmountListTemp = [];
    var pointsToRedeemDiscountTemp = 0;
    var pointsToRedeemTemp = 0;

    var cartItemPointsDeducted = 0;
    var cartItemAmountDeducted = 0;

    var cartItemQuantitySummaries = [];

    for (var i = 0; i < cartItems.length; i++) {
      const tempCartItem = cartItems[i];

      var tempCartItemPrice = 0;

      var tempCartItemAddOnCategorized = {};
      var tempCartItemAddOnCategorizedPrice = {};

      var tempCartItemAddOnParsed = [];

      if (tempCartOutletItemsDict[tempCartItem.itemId] === undefined) {
        // need retrive the actual item, to show price, pic, etc

        const outletItemSnapshot = await firebase.firestore().collection(Collections.OutletItem)
          .where('uniqueId', '==', tempCartItem.itemId)
          .limit(1)
          .get();

        if (!outletItemSnapshot.empty) {
          tempCartOutletItemsDict[tempCartItem.itemId] = outletItemSnapshot.docs[0].data();
        }
      }

      // tempCartItemPrice = tempCartItem.quantity * tempCartOutletItemsDict[tempCartItem.itemId].price;
      // tempCartItemPrice = overrideItemPriceSkuDict[tempCartItem.itemSku] !== undefined ? overrideItemPriceSkuDict[tempCartItem.itemSku] : tempCartOutletItemsDict[tempCartItem.itemId].price;

      var overrideCategoryPrice = undefined;
      if (selectedOutletItemCategoriesDict[tempCartItem.categoryId] && overrideCategoryPriceNameDict[selectedOutletItemCategoriesDict[tempCartItem.categoryId].name] !== undefined) {
        overrideCategoryPrice = overrideCategoryPriceNameDict[selectedOutletItemCategoriesDict[tempCartItem.categoryId].name];
      }

      if (overrideItemPriceSkuDict[tempCartItem.itemSku] !== undefined) {
        tempCartItemPrice = overrideItemPriceSkuDict[tempCartItem.itemSku];
      }
      else if (overrideCategoryPrice !== undefined) {
        tempCartItemPrice = overrideCategoryPrice;
      }
      else {
        tempCartItemPrice = tempCartOutletItemsDict[tempCartItem.itemId].price;
      }

      //////////////////////////////////////////////////////////

      currOutletId = tempCartOutletItemsDict[tempCartItem.itemId].outletId;

      if (tempOutletsTaxDict[currOutletId] === undefined) {
        // need retrieve the tax rate of this outlet

        const outletTaxSnapshot = await firebase.firestore().collection(Collections.OutletTax)
          .where('outletId', '==', currOutletId)
          .limit(1)
          .get();

        if (!outletTaxSnapshot.empty) {
          tempOutletsTaxDict[currOutletId] = outletTaxSnapshot.docs[0].data();
        }
      }

      //////////////////////////////////////////////////////////

      if (tempCartItem.choices) {
        const tempCartItemChoices = Object.entries(tempCartItem.choices).map(([key, value]) => ({ key: key, value: value }));

        for (var j = 0; j < tempCartItemChoices.length; j++) {
          if (tempCartItemChoices[j].value) {
            // means the addon of this item is picked, need to retrieve the actual addon

            if (tempCartOutletItemAddOnChoiceDict[tempCartItemChoices[j].key] === undefined) {
              const outletItemAddOnChoiceSnapshot = await firebase.firestore().collection(Collections.OutletItemAddOnChoice)
                .where('uniqueId', '==', tempCartItemChoices[j].key)
                .limit(1)
                .get();

              if (!outletItemAddOnChoiceSnapshot.empty) {
                tempCartOutletItemAddOnChoiceDict[tempCartItemChoices[j].key] = outletItemAddOnChoiceSnapshot.docs[0].data();
              }
            }

            tempCartItemPrice += tempCartOutletItemAddOnChoiceDict[tempCartItemChoices[j].key].price;

            // need to retrieve the description/type name of this addon choice

            const tempCartItemAddOnChoice = tempCartOutletItemAddOnChoiceDict[tempCartItemChoices[j].key];

            if (tempCartOutletItemAddOnDict[tempCartItemAddOnChoice.outletItemAddOnId] === undefined) {
              const outletItemAddOnSnapshot = await firebase.firestore().collection(Collections.OutletItemAddOn)
                .where('uniqueId', '==', tempCartItemAddOnChoice.outletItemAddOnId)
                .limit(1)
                .get();

              if (!outletItemAddOnSnapshot.empty) {
                tempCartOutletItemAddOnDict[tempCartItemAddOnChoice.outletItemAddOnId] = outletItemAddOnSnapshot.docs[0].data();
              }
            }

            if (tempCartItemAddOnCategorized[tempCartItemAddOnChoice.outletItemAddOnId] === undefined) {
              tempCartItemAddOnCategorized[tempCartItemAddOnChoice.outletItemAddOnId] = [];
            }

            tempCartItemAddOnCategorized[tempCartItemAddOnChoice.outletItemAddOnId].push(tempCartItemAddOnChoice.name);

            if (tempCartItemAddOnCategorizedPrice[tempCartItemAddOnChoice.outletItemAddOnId] === undefined) {
              tempCartItemAddOnCategorizedPrice[tempCartItemAddOnChoice.outletItemAddOnId] = [];
            }

            tempCartItemAddOnCategorizedPrice[tempCartItemAddOnChoice.outletItemAddOnId].push(tempCartItemAddOnChoice.price);
          }
        }

        const tempCartItemAddOnCategorizedList = Object.entries(tempCartItemAddOnCategorized).map(([key, value]) => ({ key: key, value: value }));
        const tempCartItemAddOnCategorizedPriceList = Object.entries(tempCartItemAddOnCategorizedPrice).map(([key, value]) => ({ key: key, value: value }));

        if (tempCartItemAddOnCategorizedList.length > 0) {
          for (var j = 0; j < tempCartItemAddOnCategorizedList.length; j++) {
            const tempCartItemAddOnName = tempCartOutletItemAddOnDict[tempCartItemAddOnCategorizedList[j].key].name;

            tempCartItemAddOnParsed.push({
              name: tempCartItemAddOnName,
              choiceNames: tempCartItemAddOnCategorizedList[j].value,
              prices: tempCartItemAddOnCategorizedPriceList[j].value,
            });
          }
        }
      }

      //////////////////////////////////////////////////////////////////////

      // for add-on group

      if (tempCartItem.addOnGroupList) {
        for (var j = 0; j < tempCartItem.addOnGroupList.length; j++) {
          // now separate all, might change in future

          const addOnGroup = tempCartItem.addOnGroupList[j];

          tempCartItemAddOnParsed.push({
            name: addOnGroup.addOnName,
            addOnId: addOnGroup.outletItemAddOnId,
            choiceNames: [addOnGroup.choiceName],
            prices: [addOnGroup.quantity * addOnGroup.price],
            quantities: [addOnGroup.quantity],
            singlePrices: [addOnGroup.price],
            addOnChoiceIdList: [addOnGroup.outletItemAddOnChoiceId],
            minSelectList: [addOnGroup.minSelect],
            maxSelectList: [addOnGroup.maxSelect],
          });

          tempCartItemPrice += addOnGroup.quantity * addOnGroup.price;
        }
      }

      //////////////////////////////////////////////////////////////////////

      tempCartItemPrice = tempCartItemPrice * tempCartItem.quantity;

      cartItemQuantitySummaries.push({
        item: tempCartItem,
        quantity: tempCartItem.quantity,
      });

      var amountOffCategory = undefined;
      if (selectedOutletItemCategoriesDict[tempCartItem.categoryId] && amountOffCategoryNameDict[selectedOutletItemCategoriesDict[tempCartItem.categoryId].name] !== undefined) {
        amountOffCategory = amountOffCategoryNameDict[selectedOutletItemCategoriesDict[tempCartItem.categoryId].name];
      }

      if (amountOffItemSkuDict[tempCartItem.itemSku] !== undefined) {
        if (tempCartItem.quantity >= amountOffItemSkuDict[tempCartItem.itemSku].quantityMin &&
          tempCartItem.quantity <= amountOffItemSkuDict[tempCartItem.itemSku].quantityMax) {
          if (tempCartItemPrice >= amountOffItemSkuDict[tempCartItem.itemSku].priceMin &&
            tempCartItemPrice <= amountOffItemSkuDict[tempCartItem.itemSku].priceMax) {
            tempCartItemPrice -= amountOffItemSkuDict[tempCartItem.itemSku].amountOff;
            tempCartItemPrice = Math.max(tempCartItemPrice, 0);
          }
        }
      }
      else if (amountOffCategory !== undefined) {
        if (tempCartItem.quantity >= amountOffCategory.quantityMin &&
          tempCartItem.quantity <= amountOffCategory.quantityMax) {
          if (tempCartItemPrice >= amountOffCategory.priceMin &&
            tempCartItemPrice <= amountOffCategory.priceMax) {
            tempCartItemPrice -= amountOffCategory.amountOff;
            tempCartItemPrice = Math.max(tempCartItemPrice, 0);
          }
        }
      }

      var percentageOffCategory = undefined;
      if (selectedOutletItemCategoriesDict[tempCartItem.categoryId] && percentageOffCategoryNameDict[selectedOutletItemCategoriesDict[tempCartItem.categoryId].name] !== undefined) {
        percentageOffCategory = percentageOffCategoryNameDict[selectedOutletItemCategoriesDict[tempCartItem.categoryId].name];
      }

      if (percentageOffItemSkuDict[tempCartItem.itemSku] !== undefined) {
        if (tempCartItem.quantity >= percentageOffItemSkuDict[tempCartItem.itemSku].quantityMin &&
          tempCartItem.quantity <= percentageOffItemSkuDict[tempCartItem.itemSku].quantityMax) {
          if (tempCartItemPrice >= percentageOffItemSkuDict[tempCartItem.itemSku].priceMin &&
            tempCartItemPrice <= percentageOffItemSkuDict[tempCartItem.itemSku].priceMax) {
            tempCartItemPrice -= tempCartItemPrice * percentageOffItemSkuDict[tempCartItem.itemSku].percentageOff / 100;
            tempCartItemPrice = Math.max(tempCartItemPrice, 0);
          }
        }
      }
      else if (percentageOffCategory !== undefined) {
        if (tempCartItem.quantity >= percentageOffCategory.quantityMin &&
          tempCartItem.quantity <= percentageOffCategory.quantityMax) {
          if (tempCartItemPrice >= percentageOffCategory.priceMin &&
            tempCartItemPrice <= percentageOffCategory.priceMax) {
            tempCartItemPrice -= tempCartItemPrice * percentageOffCategory.percentageOff / 100;
            tempCartItemPrice = Math.max(tempCartItemPrice, 0);
          }
        }
      }

      cartItemPointsDeducted = 0;
      cartItemAmountDeducted = 0;

      if (userPointsBalanceTemp > 0) {
        // get this item particular off   

        var pointsRedeemObj = null;

        var pointsRedeemCategory = undefined;
        if (selectedOutletItemCategoriesDict[tempCartItem.categoryId] && pointsRedeemCategoryNameDict[selectedOutletItemCategoriesDict[tempCartItem.categoryId].name] !== undefined) {
          pointsRedeemCategory = pointsRedeemCategoryNameDict[selectedOutletItemCategoriesDict[tempCartItem.categoryId].name];
        }

        if (pointsRedeemItemSkuDict[tempCartItem.itemSku] !== undefined) {
          pointsRedeemObj = pointsRedeemItemSkuDict[tempCartItem.itemSku];
        }
        else if (pointsRedeemCategory !== undefined) {
          pointsRedeemObj = pointsRedeemCategory;
        }

        if (pointsRedeemObj) {
          pointsToRedeemPackageIdListTemp.push(pointsRedeemObj.packageId);

          var pointsRedeemAmountCurrency = userPointsBalanceTemp / pointsRedeemObj.conversionPointsFrom * pointsRedeemObj.conversionCurrencyTo;

          if (tempCartItemPrice >= pointsRedeemAmountCurrency) {
            var amountDiscount = pointsRedeemAmountCurrency;
          }
          else {
            var amountDiscount = tempCartItemPrice;
          }

          pointsToRedeemAmountListTemp.push(amountDiscount);
          pointsToRedeemDiscountTemp += amountDiscount;

          var amountPoint = amountDiscount / pointsRedeemObj.conversionCurrencyTo * pointsRedeemObj.conversionPointsFrom;

          userPointsBalanceTemp -= amountPoint;
          pointsToRedeemTemp += amountPoint;

          cartItemPointsDeducted = amountPoint;
          cartItemAmountDeducted = amountDiscount;
        }
      }

      //////////////////////////////////////////////////////////////////////

      tempCartItemsProcessed.push({
        itemId: tempCartItem.itemId,
        choices: tempCartItem.choices,
        remarks: tempCartItem.remarks,
        fireOrder: tempCartItem.fireOrder,
        cartItemDate: tempCartItem.cartItemDate,

        image: tempCartOutletItemsDict[tempCartItem.itemId].image,
        name: tempCartOutletItemsDict[tempCartItem.itemId].name,
        itemName: tempCartOutletItemsDict[tempCartItem.itemId].name,
        itemSku: tempCartOutletItemsDict[tempCartItem.itemId].sku,
        price: +tempCartItemPrice.toFixed(2),
        quantity: tempCartItem.quantity,
        addOns: tempCartItemAddOnParsed,

        deliveredAt: null,
        cookedAt: null,
        isChecked: false,

        orderType: orderType, // might relocate in other places in future

        prepareTime: tempCartOutletItemsDict[tempCartItem.itemId].prepareTime * tempCartItem.quantity,

        cartItemPointsDeducted: cartItemPointsDeducted,
        cartItemAmountDeducted: cartItemAmountDeducted,
      });

      tempTotalPrice += tempCartItemPrice;
      tempTotalPrepareTime += tempCartOutletItemsDict[tempCartItem.itemId].prepareTime * tempCartItem.quantity;

      cartItemsTotalQuantityTemp += tempCartItem.quantity;
    }

    //////////////////////////////////////////////////////////

    // add free items to processed carts

    var freeItemQuantitySummaries = [];

    var redeemedB1F1PromotionIdList = [];

    const buy1Free1ItemSkuDictList = Object.entries(buy1Free1ItemSkuDict).map(([key, value]) => ({ ...value }));

    for (var i = 0; i < buy1Free1ItemSkuDictList.length; i++) {
      var quantity = 0;

      for (var j = 0; j < cartItemQuantitySummaries.length; j++) {
        if (buy1Free1ItemSkuDict[cartItemQuantitySummaries[j].item.sku]) {
          // means eligible item, accum quantity

          quantity += cartItemQuantitySummaries[j].quantity;
        }
      }

      if (quantity >= buy1Free1ItemSkuDictList[i].buyAmount &&
        !redeemedB1F1PromotionIdList.includes(buy1Free1ItemSkuDictList[i].promotionId)) {
        redeemedB1F1PromotionIdList.push(buy1Free1ItemSkuDictList[i].promotionId);
      }
    }

    const buy1Free1CategoryNameDictList = Object.entries(buy1Free1CategoryNameDict).map(([key, value]) => ({ ...value }));

    for (var i = 0; i < buy1Free1CategoryNameDictList.length; i++) {
      var quantity = 0;

      for (var j = 0; j < cartItemQuantitySummaries.length; j++) {
        if (selectedOutletItemCategoriesDict[cartItemQuantitySummaries[j].item.categoryId] &&
          buy1Free1CategoryNameDict[selectedOutletItemCategoriesDict[cartItemQuantitySummaries[j].item.categoryId].name]) {
          // means eligible item, accum quantity

          quantity += cartItemQuantitySummaries[j].quantity;
        }
      }

      if (quantity >= buy1Free1CategoryNameDictList[i].buyAmount &&
        !redeemedB1F1PromotionIdList.includes(buy1Free1CategoryNameDictList[i].promotionId)) {
        redeemedB1F1PromotionIdList.push(buy1Free1CategoryNameDictList[i].promotionId);
      }
    }

    const buy1Free1ItemAllList = buy1Free1ItemSkuDictList.concat(buy1Free1CategoryNameDictList);

    for (var i = 0; i < redeemedB1F1PromotionIdList.length; i++) {
      const b1f1Promotion = buy1Free1ItemAllList.find(promotion => promotion.promotionId === redeemedB1F1PromotionIdList[i]);

      if (b1f1Promotion && b1f1Promotion.getVariation === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS) {
        for (var j = 0; j < b1f1Promotion.getVariationItemsSku.length; j++) {
          const freeItem = selectedOutletItemsSkuDict[b1f1Promotion.getVariationItemsSku[j]];

          if (freeItem) {
            tempCartItemsProcessed.push({
              isFreeItem: true,
              promotionId: b1f1Promotion.promotionId,

              itemId: freeItem.uniqueId,
              choices: [],
              remarks: '',
              fireOrder: false,
              cartItemDate: Date.now(),

              image: freeItem.image,
              name: freeItem.name,
              itemName: freeItem.name,
              itemSku: freeItem.sku,
              price: 0,
              quantity: b1f1Promotion.getAmount,
              addOns: [],

              deliveredAt: null,
              cookedAt: null,
              isChecked: false,

              orderType: orderType, // might relocate in other places in future

              prepareTime: freeItem.prepareTime * b1f1Promotion.getAmount,

              cartItemPointsDeducted: 0,
              cartItemAmountDeducted: 0,
            });
          }
        }
      }
    }

    //////////////////////////////////////////////////////////

    console.log('tempOutletsTaxDict');
    console.log(tempOutletsTaxDict);
    console.log('tempCartOutletItemsDict');
    console.log(tempCartOutletItemsDict);
    console.log('tempCartOutletItemAddOnDict');
    console.log(tempCartOutletItemAddOnDict);
    console.log('tempCartOutletItemAddOnChoiceDict');
    console.log(tempCartOutletItemAddOnChoiceDict);
    console.log('tempCartItemsProcessed');
    console.log(tempCartItemsProcessed);

    //////////////////////////////////////////////////////////////////////

    CommonStore.update(s => {
      s.outletsTaxDict = tempOutletsTaxDict;
      s.cartOutletItemsDict = tempCartOutletItemsDict;
      s.cartOutletItemAddOnDict = tempCartOutletItemAddOnDict;
      s.cartOutletItemAddOnChoiceDict = tempCartOutletItemAddOnChoiceDict;
      s.cartItemsProcessed = tempCartItemsProcessed;
    });

    setTotalPrice(tempTotalPrice);

    if (tempOutletsTaxDict && currOutletId) {
      setTotalTax(tempTotalPrice * tempOutletsTaxDict[currOutletId].rate);
    }

    setTotalPrepareTime(tempTotalPrepareTime);

    setCartItemsTotalQuantity(cartItemsTotalQuantityTemp);

    /////////////////////////////////////////////////////////

    setPointsToRedeemDiscount(pointsToRedeemDiscountTemp);
    setPointsToRedeemPackageIdList(pointsToRedeemPackageIdListTemp);
    setPointsToRedeemAmountList(pointsToRedeemAmountListTemp);
    setPointsToRedeem(Math.ceil(pointsToRedeemTemp));

    if (Math.ceil(pointsToRedeemTemp) > 0) {
      setShowPointsToRedeemOptions(true);
    }
    else {
      setShowPointsToRedeemOptions(false);
    }

    /////////////////////////////////////////////////////////

    CommonStore.update(s => {
      s.isLoading = false;
    });
  };

  const setState = () => { };

  console.log('orderType Cart');
  console.log(orderType);

  navigation.setOptions({
    headerLeft: () => (
      <TouchableOpacity style={{
      }} onPress={() => {
        // props.navigation.goBack();
        linkTo(`${prefix}/outlet/menu`);
      }}>
        <View style={{
          marginLeft: 10,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}>
          <Ionicons
            name="chevron-back"
            size={26}
            color={Colors.fieldtTxtColor}
            style={{
            }}
          />

          <Text
            style={{
              color: Colors.fieldtTxtColor,
              fontSize: 16,
              textAlign: 'center',
              fontFamily: 'NunitoSans-Regular',
              lineHeight: 22,
              marginTop: -1,
            }}>
            Back
          </Text>
        </View>
      </TouchableOpacity>
    ),
    headerRight: () => (
      <TouchableOpacity onPress={() => {
        // props.navigation.navigate('Profile')
      }} style={{
        opacity: 0,
      }}>
        <View style={{ marginRight: 15 }}>
          <Ionicons name="menu" size={30} color={Colors.primaryColor} />
        </View>
      </TouchableOpacity>
    ),
    headerTitle: () => (
      <View style={{
        justifyContent: 'center',
        alignItems: 'center',
        bottom: -1,
      }}>
        <Text
          style={{
            fontSize: 20,
            lineHeight: 25,
            textAlign: 'center',
            fontFamily: 'NunitoSans-Bold',
            color: Colors.mainTxtColor,
          }}>
          My Cart
        </Text>
      </View>
    ),
  });

  const MenuItem = (param) => {
    ApiClient.GET(API.getItemAddOnChoice + param).then((result) => {
      setState({ menuItemDetails: result });
    });
  }

  useEffect(() => {
    // console.log("CART OUTLET ID", Cart.getOutletId())
    // getCartItem();
    // Cart.setRefreshCartPage(getCartItem.bind(this));
    // console.log("CART OUTLET", outletData)
    // ApiClient.GET(API.merchantMenu + outletData.id).then(
    //   (result) => {
    //     console.log("777");
    //     console.log(result);
    //     if (result != undefined) {
    //       if (result.length > 0) {
    //         setState({
    //           category: result[0].category,
    //           menu: result[0].items,
    //         });
    //       }
    //       setState({ outletMenu: result });
    //     }
    //     else {
    //       setState({
    //         category: result[0].category,
    //         menu: result[0].items,
    //       });
    //       setState({ outletMenu: [] });
    //     }
    //   }
    // );

    getPopular()
    // setInterval(() => {
    //   setState({ deliveryQuotation: Cart.getDeliveryQuotation() == null ? null : Cart.getDeliveryQuotation() })
    // }, 2500);

    // set delivery/pickup button status
    if (Cart.getOrderType() === 1) {
      setState({ clicked: 1, clicked1: 0, shouldShow: true })
    }
    if (Cart.getOrderType() === 2) {
      setState({ clicked1: 1, clicked: 0, shouldShow: false })
    }
  }, []);

  // function here
  const sum = (key) => {
    // return reduce((a, b) => a + (b[key] || 0), 0);
  }

  const getCartItem = () => {
    setState({ cartItem: Cart.getCartItem() }, () => { calculateFinalTotal() });
    console.log("888", Cart.getCartItem());
  }

  const checkDuplicate = (item, i, j) => {
    for (const r of item) {
      if (r[0] == i && r[1] == j) {
        return true
      }
    }
    return false
  }

  const getPopular = () => {
    var newList = []
    var randomList = []
    // ApiClient.GET(API.activeMenu + outletData.id).then((result) => {
    //   var maxItem = 0
    //   for (const item of result) {
    //     maxItem = maxItem + item.items.length
    //   }
    //   var maxIteration = 5
    //   if (maxItem < 5) {
    //     maxIteration = maxIteration
    //   }
    //   var k;
    //   for (k = 0; k < maxIteration; k++) {
    //     var i = Math.floor(Math.random() * result.length)
    //     var j = Math.floor(Math.random() * result[i].items.length)

    //     while (checkDuplicate(randomList, i, j)) {
    //       i = Math.floor(Math.random() * result.length)
    //       j = Math.floor(Math.random() * result[i].items.length)
    //     }
    //     newList.push(result[i].items[j])
    //     randomList.push([i, j])
    //     setState({ popular: newList })
    //   }
    // });

  }

  const goToPopular = (item) => {
    // console.log(outletData, "outletData")
    // props.navigation.navigate('MenuItemDetails', {
    //   refresh: refresh.bind(this), 
    //   menuItem: item,
    //   outletData: outletData, 
    //   // test: test
    // })
  }

  const renderPopularItem = ({ item, index }) => {
    return (
      <View style={{ width: 180, height: 80, borderWidth: StyleSheet.hairlineWidth, marginRight: 10, borderRadius: 10, justifyContent: "space-around" }}>
        <Text numberOfLines={2} style={{ fontSize: 16, fontWeight: "400", marginLeft: 10, }}>{item.name}</Text>
        <View style={{ flexDirection: "row", marginLeft: 10, marginTop: 10, }}>
          <Text style={{ fontSize: 16, color: "#9c9c9c", }}>{item.price}</Text>
          <TouchableOpacity style={{ marginLeft: 70 }} onPress={() => { goToPopular(item) }}>
            <Close name="pluscircle" size={22} color={Colors.primaryColor} />
          </TouchableOpacity>
        </View>
      </View>
    )
  };

  const refresh = () => {
    setState({ refresh: true });
  }

  const onChangeQty = (e, id) => {
    // const cartItem = cartItem;
    const cartItem = Cart.getCartItem();
    const item = cartItem.find((obj) => obj.itemId === id);
    item.quantity = item.quantity - e;
    if (item.quantity == 0) {
      Cart.deleteCartItem(id);
    } else {
      setState({
        cartItem,
      });
      Cart.updateCartItem(Cart.getCartItem().find((obj) => obj.itemId === id), item);
    }
    console.log("CART", Cart.getCartItem().find((obj) => obj.itemId === id))
  }

  const optional = (id) => {
    const cartItem = cartItem;
    console.log(cartItem);
    const item = cartItem.find((obj) => obj.itemId === id);
    if (item.fireOrder == 0) {
      item.fireOrder = 1;
    } else if (item.fireOrder == 1) {
      item.fireOrder = 0;
    }
    setState({
      cartItem,
    });
  }

  const extend = (id, day) => {
    const body = {
      parkingId: id,
      dayExtend: day,
      paymentMethod: ''
    }
    console.log("PARKING BODY", body)
    // ApiClient.POST(API.extendParking, body).then((result) => {
    //   console.log(result);
    //   if (result != null) {
    //     Alert.alert(
    //       'Success',
    //       'You have successful extended',
    //       [{ text: 'OK', onPress: () => { props.navigation.navigate('ConfirmOrder', { outletData: outletData }); } }],
    //       { cancelable: false },
    //     );
    //     User.getRefreshCurrentAction();
    //     Cart.clearCart();
    //     getCartItem();
    //   }
    // })
  }

  const newPurchase = (outletId, itemId, qty) => {
    const body = {
      userId: User.getUserId(),
      outletId: outletId,
      itemId: itemId,
      quantity: qty,
      waiterId: 1,
    }
    // ApiClient.POST(API.createUserParking, body).then((result) => {
    //   console.log(result);
    //   if (result != null) {
    //     Alert.alert(
    //       'Success',
    //       'You have successful Parked',
    //       [{ text: 'OK', onPress: () => { props.navigation.navigate('ConfirmOrder', { outletData: outletData }); } }],
    //       { cancelable: false },
    //     );
    //     User.getRefreshCurrentAction();
    //     Cart.clearCart();
    //     getCartItem();
    //   }
    // })
  }

  const clearCartItems = async () => {
    await AsyncStorage.removeItem(`${firebaseUid}.cartItems`);
    await AsyncStorage.removeItem(`${firebaseUid}.cartOutletId`);

    CommonStore.update(s => {
      s.cartItems = [];
      s.cartItemsProcessed = [];

      s.cartOutletId = null;
    });
  };

  const placeUserOrder = async (paymentDetails) => {
    // var orderBodyType = '';
    // switch (Cart.getOrderType()) {
    //   case 0:
    //     orderBodyType = 'Dine In';
    //     break;
    //   case 1:
    //     orderBodyType = 'Take Away';
    //     break;
    //   case 2:
    //     orderBodyType = 'Pick Up';
    //     break;
    // }

    CommonStore.update(s => {
      s.isLoading = true;

      s.isPlacingOrder = true;
    });

    // const outletSnapshot = await firebase.firestore().collection(Collections.Outlet)
    //   .where('uniqueId', '==', cartOutletItemsDict[cartItems[0].itemId].outletId)
    //   .limit(1)
    //   .get();

    var merchant = {};
    var outlet = selectedOutlet;

    const merchantSnapshot = await firebase.firestore().collection(Collections.Merchant)
      .where('uniqueId', '==', outlet.merchantId)
      .limit(1)
      .get();

    if (!merchantSnapshot.empty) {
      merchant = merchantSnapshot.docs[0].data();
    }

    // if (!outletSnapshot.empty) {
    //   outlet = outletSnapshot.docs[0].data();

    //   const merchantSnapshot = await firebase.firestore().collection(Collections.Merchant)
    //     .where('uniqueId', '==', outlet.merchantId)
    //     .limit(1)
    //     .get();

    //   if (!merchantSnapshot.empty) {
    //     merchant = merchantSnapshot.docs[0].data();
    //   }
    // }

    const totalWeightKg = Math.ceil(cartItemsTotalQuantity * 0.2); // for now we assume each item weighted 200 gram

    const orderDateTemp = Date.now();

    var body = {
      // cartItems: cartItems,
      cartItems: cartItemsProcessed,

      // tableId: Cart.getTableNumber(),
      // outletId: Cart.getOutletId(),
      // type: Cart.getOrderType() == 0 ? "Dine In" : "Take Away",
      orderType: orderType,
      paymentMethod: 'Online Banking',
      // userGiftCardId: null,
      userVoucherId: null,
      userAddressId: selectedUserAddress ? selectedUserAddress.uniqueId : null,
      orderDate: orderDateTemp,
      // voucherType: "",
      // customTable: "",
      // sessionId: 0,
      // remarks: null,
      // collectionTime: rev_date === undefined ? new Date() : new Date(rev_date),
      totalPrice: totalPrice,

      outletId: cartOutletItemsDict[cartItems[0].itemId].outletId,

      merchantId: merchant.uniqueId,
      outletCover: outlet.cover,
      merchantLogo: merchant.logo,
      outletName: outlet.name,
      merchantName: merchant.name,

      tableId: selectedOutletTableId ? selectedOutletTableId : '',
      tablePax: selectedOutletTablePax ? selectedOutletTablePax : 0,
      tableCode: selectedOutletTableCode ? selectedOutletTableCode : '',
      tax: totalTax,
      taxId: outletsTaxDict[selectedOutlet.uniqueId].uniqueId,
      discount: totalDiscount,

      deliveryFee: orderType === ORDER_TYPE.DELIVERY ? deliveryQuotation.totalFee : 0,
      courierCode: orderType === ORDER_TYPE.DELIVERY ? deliveryQuotation.courierCode : '',

      waiterId: selectedOutletWaiterId ? selectedOutletWaiterId : '',
      waiterName: selectedOutletWaiterName ? selectedOutletWaiterName : '',

      totalPrepareTime: totalPrepareTime,
      estimatedPreparedDate: moment(orderDateTemp).add(totalPrepareTime, 'second').valueOf(),

      outletTaxId: outletsTaxDict[selectedOutlet.uniqueId].uniqueId,
      outletTaxNumber: outletsTaxDict[selectedOutlet.uniqueId].taxNumber,
      outletTaxName: outletsTaxDict[selectedOutlet.uniqueId].name,
      outletTaxRate: outletsTaxDict[selectedOutlet.uniqueId].rate,

      remarks: '',

      paymentDetails: paymentDetails ? paymentDetails : null,

      ///////////////////////////////////////

      // delivery usage, will not appear in UserOrder collections, used for other apis

      deliveryCurrency: orderType === ORDER_TYPE.DELIVERY ? (deliveryQuotation.totalFeeCurrency ? deliveryQuotation.totalFeeCurrency : 'MYR') : '',

      outletLat: selectedOutlet ? selectedOutlet.lat : null,
      outletLng: selectedOutlet ? selectedOutlet.lng : null,
      outletAddress: selectedOutlet.address,

      outletPhone: selectedOutlet.phone,
      // outletName: selectedOutlet.name,

      userLat: selectedUserAddress ? selectedUserAddress.lat : null,
      userLng: selectedUserAddress ? selectedUserAddress.lng : null,
      userAddress: selectedUserAddress ? selectedUserAddress.address : '',

      userName: userName ? userName : userInfoName,
      userPhone: userNumber ? userNumber : userInfoPhone,
      userRemarks: selectedUserAddress ? selectedUserAddress.note : '',

      scheduleAt: moment().add(totalPrepareTime, 'second').add(15, 'minute').utc().toISOString(),

      ///////////////////////////////////////

      // razer usage

      orderIdLocal: orderIdLocal,

      orderIdServer: orderIdServer,

      ///////////////////////////////////////

      // mr speedy usage

      totalWeightKg: totalWeightKg,
      outletRequiredStartDatetime: moment().add(totalPrepareTime, 'second').add(5, 'minute').utc().toISOString(),
      outletRequiredFinishDatetime: moment().add(totalPrepareTime, 'second').add(10, 'minute').utc().toISOString(),
      userRequiredStartDatetime: moment().add(totalPrepareTime, 'second').add(15, 'minute').utc().toISOString(),
      userRequiredFinishDatetime: moment().add(totalPrepareTime, 'second').add(30, 'minute').utc().toISOString(),

      ///////////////////////////////////////

      // points usage

      pointsToRedeem: pointsToRedeem,
      pointsToRedeemPackageIdList: pointsToRedeemPackageIdList,
      pointsToRedeemAmountList: pointsToRedeemAmountList,
      pointsToRedeemDiscount: pointsToRedeemDiscount,
      usePointsToRedeem: usePointsToRedeem,

      ///////////////////////////////////////
    };

    if (selectedVoucherId && selectedOutlet && selectedOutlet.uniqueId &&
      merchantVouchersDict[selectedVoucherId] && merchantVouchersDict[selectedVoucherId].merchantId === selectedOutlet.merchantId) {
      body.userVoucherId = selectedVoucherId;
      body.userVoucherCode = selectedVoucher.code;
    }

    ApiClient.POST(API.createUserOrder, body, {
      timeout: 10000,
    }).then(async (result) => {
      console.log("createUserOrder");
      console.log(result)

      if (result && result.uniqueId) {
        // if (result.success == true) {
        // if (type == 1) {
        //   orderDelivery(result);
        // }
        // else if (type == 2) {
        //   orderPickUp(result);
        // }

        if (selectedOutletTableId.length > 0) {
          await deleteUserCart();
        }

        await clearCartItems();

        CommonStore.update(s => {
          s.alertObj = {
            title: 'Successful',
            message: 'Your order have been placed.',
          }
        });

        Alert.alert(
          "Successful",
          "Your order have been placed.",
          [
            {
              text: "OK",
              onPress: () => {
                //if navFrom Takeaway then jump to Home
                // if (navFrom == "TAKEAWAY") {
                //   props.navigation.jumpTo('Home')
                // }
                // else {
                //   props.navigation.popToTop()
                // }

                // navigation.jumpTo('Home')

                ///////////////////////////////////////

                // props.navigation.navigate("ConfirmOrder", {
                //   orderResult: result,
                //   outletData: selectedOutlet,
                // });

                CommonStore.update(s => {
                  s.selectedUserOrder = result;
                });

                props.navigation.reset({
                  index: 0,
                  routes: [{
                    name: 'OrderHistoryDetail',
                    params: {
                      orderId: result.uniqueId,
                    }
                  }],
                });

                // props.navigation.navigate("OrderHistoryDetail", {
                //   orderId: result.uniqueId,
                // });
              },
            },
          ],
          { cancelable: false }
        );

        // User.getRefreshCurrentAction();

        // Cart.clearCart();

        // getCartItem();

        CommonStore.update(s => {
          s.isLoading = false;

          s.isPlacingOrder = false;
        });

        linkTo(`${prefix}/outlet/menu`);
      }
      else {
        Alert.alert(
          "Error",
          "Failed to place the order",
          [
            {
              text: "OK",
              onPress: () => {
                //if navFrom Takeaway then jump to Home
                // if (navFrom == "TAKEAWAY") {
                //   props.navigation.jumpTo('Home')
                // }
                // else {
                //   props.navigation.popToTop()
                // }

                // navigation.jumpTo('Home')
                // navigation.navigate('Home');

                navigation.dispatch(
                  CommonActions.reset({
                    index: 0,
                    routes: [{ name: "Home" }]
                  }));
              },
            },
          ],
          { cancelable: false }
        );

        CommonStore.update(s => {
          s.isLoading = false;

          s.isPlacingOrder = false;
        });
      }
    });
  }

  const placeOrder = () => {
    var orderBodyType = '';
    switch (Cart.getOrderType()) {
      case 0:
        orderBodyType = 'Dine In';
        break;
      case 1:
        orderBodyType = 'Take Away';
        break;
      case 2:
        orderBodyType = 'Pick Up';
        break;
    }

    var body = {
      items: Cart.getCartItem(),
      tableId: Cart.getTableNumber(),
      outletId: Cart.getOutletId(),
      // type: Cart.getOrderType() == 0 ? "Dine In" : "Take Away",
      type: orderBodyType,
      paymentMethod: "Online Banking",
      voucherId: "",
      voucherType: "",
      customTable: "",
      sessionId: 0,
      remarks: null,
      collectionTime: rev_date === undefined ? new Date() : new Date(rev_date),
    };
    console.log("PLACE ORDER BODY", body);

    // ApiClient.POST(API.createOrder, body).then((result) => {
    //   console.log("placeOrder", result);
    //   if (result.success == true) {
    //     if (type == 1) {
    //       orderDelivery(result);
    //     }
    //     else if (type == 2) {
    //       orderPickUp(result);
    //     }

    //     Alert.alert(
    //       "Successful",
    //       "Your order have been placed.",
    //       [
    //         {
    //           text: "OK",
    //           onPress: () => {
    //             //if navFrom Takeaway then jump to Home
    //             // if (navFrom == "TAKEAWAY") {
    //             //   // props.navigation.jumpTo('Home')
    //             //   navigation.navigate('Home');
    //             // }
    //             // else {
    //             //   // props.navigation.popToTop()
    //             //   navigation.navigate('Home');
    //             // }

    //             navigation.dispatch(
    //               CommonActions.reset({
    //                 index: 0,
    //                 routes: [{ name: "Home" }]
    //               }));
    //           },
    //         },
    //       ],
    //       { cancelable: false }
    //     );
    //     User.getRefreshCurrentAction();
    //     Cart.clearCart();
    //     getCartItem();
    //   }
    // });
  }

  const orderDelivery = (result) => {
    var deliverBody = {
      orderId: result.id,
      userId: User.getUserId(),
      addressId: Cart.getDeliveryAddress().id,
    }

    // ApiClient.POST(API.createOrderDelivery, deliverBody).then((result) => {
    //   console.log("order delivery", result)
    //   var body = {
    //     "serviceType": "MOTORCYCLE",
    //     "specialRequests": [],
    //     "stops": [
    //       {
    //         // Location information for pick-up point
    //         "location": {
    //           // "lat": outletData.latlng.split(',')[0],
    //           // "lng": outletData.latlng.split(',')[1]
    //           "lat": selectedOutlet.lat,
    //           "lng": selectedOutlet.lng,
    //         },
    //         "addresses": {
    //           "ms_MY": {
    //             "displayString": selectedOutlet.address,
    //             "country": "MY_KUL"
    //           }
    //         }
    //       },
    //       {
    //         // Location information for drop-off point (#1)
    //         "location": {
    //           "lat": selectedUserAddress.lat,
    //           "lng": selectedUserAddress.lng,
    //         },
    //         "addresses": {
    //           "ms_MY": {
    //             "displayString": selectedUserAddress.address,
    //             "country": "MY_KUL"
    //           }
    //         }
    //       }
    //     ],
    //     // Pick-up point copntact details
    //     "requesterContact": {
    //       "name": "Chris Wong", //get outlet person in charge?
    //       "phone": "0376886555" //or get waiter?
    //     },
    //     "deliveries": [
    //       {
    //         // Contact information at the drop-off point (#1)
    //         "toStop": 1,
    //         "toContact": {
    //           "name": userName,
    //           "phone": userNumber,
    //         },
    //         "remarks": selectedUserAddress.note,
    //       }
    //     ]
    //   };
    //   if (UTCRevDate != undefined) {
    //     const scheduleAt = UTCRevDate
    //     console.log("scheduleAt", scheduleAt)
    //     body["scheduleAt"] = scheduleAt
    //     console.log("SCHEDULE BODY", body)
    //     getScheduleQuotation(body, result)
    //   }
    //   else {
    //     console.log("NOW BODY ", body)
    //     placeDelivery(Cart.getDeliveryQuotation(), body, result)
    //   }
    // })
  }

  const orderPickUp = (result) => {
    var pickUpBody = {
      orderId: result.id,
      userId: User.getUserId(),
      // addressId: Cart.getDeliveryAddress().id,
    }
    ApiClient.POST(API.createOrderPickUp, pickUpBody).then((result) => {
      console.log("order pickup", result)
    })
  }

  const getScheduleQuotation = (body, order) => {
    ApiClient.POST(API.lalamoveQuotation, body).then((result) => {
      console.log("quotation result", result)
      placeDelivery(result, body, order)
    })
  }

  const placeDelivery = (quotation, body, order) => {
    console.log("Placing delivery", quotation)
    body["quotedTotalFee"] = {
      amount: quotation.totalFee,
      currency: quotation.totalFeeCurrency
    }
    console.log("LALA ORDER BODY", body)
    ApiClient.POST(API.lalamovePlaceOrder, body).then((result) => {
      console.log("lalamvoe place order", result)
      if (result) {
        const lalaOrderId = result.orderRef
        ApiClient.GET(API.lalamoveGetOrderStatus + result.orderRef).then((result) => {
          console.log("lalamove order status", result)

          var updateBody = {
            orderId: order.orderId,
            lalaOrderId: lalaOrderId,
            orderStatus: result.status
          }
          console.log("UPDATE BODY", updateBody)
          ApiClient.PATCH(API.updateOrderDelivery, updateBody).then((result) => {
            console.log("update order delivery", result)
          })
        })
      }
    })
  }

  // api keys should saved in server, but this is client sdk....
  // const molPayment = (amount, orderId) => {
  //   var paymentSuccess = false
  //   var paymentDetails = {
  //     // Optional, REQUIRED when use online Sandbox environment and account credentials.
  //     'mp_dev_mode': true,

  //     // Mandatory String. Values obtained from Razer Merchant Services.
  //     'mp_username': 'api_SB_mykoodoo',
  //     'mp_password': 'WaaU1IeZ*&(%%',
  //     'mp_merchant_ID': 'SB_mykoodoo',
  //     'mp_app_name': 'mykoodoo',
  //     'mp_verification_key': '0bd0efac623106b4c19bc28b8e9a0d8d',

  //     // Mandatory String. Payment values.
  //     'mp_amount': amount, // Minimum 1.01
  //     'mp_order_ID': orderId,
  //     'mp_currency': 'MYR',
  //     'mp_country': 'MY',

  //     // Optional String.
  //     'mp_channel': 'multi', // Use 'multi' for all available channels option. For individual channel seletion, please refer to https://github.com/RazerMS/rms-mobile-xdk-examples/blob/master/channel_list.tsv.
  //     'mp_bill_description': outletData.name + ' purchase RM' + parseFloat(amount).toFixed(2),
  //     'mp_bill_name': User.getUserData().name,
  //     'mp_bill_email': User.getUserData().email,
  //     'mp_bill_mobile': User.getUserData().number,
  //   }
  //   molpay.startMolpay(paymentDetails, (data) => {
  //     //callback after payment success
  //     console.log(data);
  //     if (data) {
  //       console.log("payment success")
  //       placeOrder()
  //     }
  //   });
  // }

  const startMolPay = channel => {
    // need get readable order id from api first

    const body = {
      outletId: selectedOutlet.uniqueId,
    };

    ApiClient.POST(API.getNextOrderId, body).then(async (result) => {
      if (result && result.status === 'success') {
        var orderIdServerTemp = result.orderId;

        setOrderIdServer(orderIdServerTemp);

        var amountToPay = Math.max(totalPrice + totalTax +
          (orderType === ORDER_TYPE.DELIVERY ? (deliveryQuotation.totalFee ? deliveryQuotation.totalFee : 0) : 0) -
          totalDiscount - (usePointsToRedeem ? pointsToRedeemDiscount : 0),
          0);

        // Testing

        amountToPay = amountToPay > 5 ? 5 : amountToPay;

        const subdomain = await AsyncStorage.getItem('latestSubdomain');
        // const metaInfo = ` | ${subdomain ? `${subdomain}.` : ''}mykoodoo.com`;
        const metaInfo = `|${subdomain ? subdomain : 'mykoodoo'}`;

        console.log('subdomain before');
        console.log(subdomain);

        // var paymentDetails = {
        //   // Optional, REQUIRED when use online Sandbox environment and account credentials.
        //   'mp_dev_mode': true,

        //   // Mandatory String. Values obtained from Razer Merchant Services.
        //   'mp_username': 'api_SB_mykoodoo',
        //   'mp_password': 'WaaU1IeZ*&(%%',
        //   'mp_merchant_ID': 'SB_mykoodoo',
        //   'mp_app_name': 'mykoodoo',
        //   'mp_verification_key': '0bd0efac623106b4c19bc28b8e9a0d8d',
        //   // 'mp_username': 'api_mykoodoo_Dev',
        //   // 'mp_password': 'E81E65f5&^',
        //   // 'mp_merchant_ID': 'mykoodoo_Dev',
        //   // 'mp_app_name': 'mykoodoo',
        //   // 'mp_verification_key': '0bd0efac623106b4c19bc28b8e9a0d8d',

        //   // Mandatory String. Payment values.
        //   'mp_amount': amountToPay, // Minimum 1.01
        //   'mp_order_ID': `#${orderType === ORDER_TYPE.DINEIN ? '' : 'T'}${orderIdServerTemp}${metaInfo}`,
        //   'mp_currency': 'MYR',
        //   'mp_country': 'MY',

        //   // Optional String.
        //   'mp_channel': 'multi', // Use 'multi' for all available channels option. For individual channel seletion, please refer to https://github.com/RazerMS/rms-mobile-xdk-examples/blob/master/channel_list.tsv.
        //   'mp_bill_description': selectedOutlet.name + ' purchase RM' + parseFloat(amountToPay).toFixed(2),
        //   'mp_bill_name': userName,
        //   'mp_bill_email': userEmail,
        //   'mp_bill_mobile': userNumber,

        //   'mp_sandbox_mode': true,
        //   'mp_tcctype': 'AUTH',
        // }

        var paymentDetails = {
          // Optional, REQUIRED when use online Sandbox environment and account credentials.
          'mp_dev_mode': true,

          // Mandatory String. Values obtained from Razer Merchant Services.
          // 'mp_username': 'api_SB_mykoodoo',
          // 'mp_password': 'WaaU1IeZ*&(%%',
          // 'mp_merchant_ID': 'SB_mykoodoo',
          // 'mp_app_name': 'mykoodoo',
          // 'mp_verification_key': '0bd0efac623106b4c19bc28b8e9a0d8d',
          mpsmerchantid: 'SB_mykoodoo',

          // Mandatory String. Payment values.
          // 'mp_amount': amountToPay, // Minimum 1.01
          // 'mp_order_ID': `#${orderType === ORDER_TYPE.DINEIN ? '' : 'T'}${orderIdServerTemp}`,
          // 'mp_currency': 'MYR',
          // 'mp_country': 'MY',
          mpsamount: amountToPay,
          mpsorderid: `#${orderType === ORDER_TYPE.DINEIN ? '' : 'T'}${orderIdServerTemp}${metaInfo}`,
          mpscurrency: 'MYR',
          mpscountry: 'MY',

          // Optional String.
          // 'mp_channel': 'multi', // Use 'multi' for all available channels option. For individual channel seletion, please refer to https://github.com/RazerMS/rms-mobile-xdk-examples/blob/master/channel_list.tsv.
          // 'mp_bill_description': selectedOutlet.name + ' purchase RM' + parseFloat(amountToPay).toFixed(2),
          // mpsbill_name: userName ? userName : userInfoName,
          // mpsbill_email: userEmail ? userEmail : '',
          mpsbill_mobile: userNumber ? userNumber : userInfoPhone,
          mpsbill_desc: selectedOutlet.name + ' purchase RM' + parseFloat(amountToPay).toFixed(2),
          mpsbill_name: userName ? userName : userInfoName,

          // 'mp_sandbox_mode': true,
          // 'mp_tcctype': 'AUTH',          

          // mpschannel: "fpx",
          mpschannel: channel.value,
        }

        // molpay.startMolpay(paymentDetails, (data) => {
        // $('#myPay').MOLPaySeamless(paymentDetails, (data) => {

        window.initMolpay(paymentDetails, (data) => {
        });

        window.startMolpay(paymentDetails, (data) => {
          // callback after payment success

          console.log("razer result v2");
          console.log(data);

          // const result = JSON.parse(data);
          const result = data;
          console.log(result);

          // if (result.error_code || result.Error) {
          //   console.log('razer error');

          //   Alert.alert(
          //     "Error",
          //     "Failed to process your payment",
          //     [
          //       {
          //         text: "OK",
          //         onPress: () => {
          //           // navigation.jumpTo('Home')
          //           // navigation.navigate('Home');
          //           navigation.dispatch(
          //             CommonActions.reset({
          //               index: 0,
          //               routes: [{ name: "Home" }]
          //             }));
          //         },
          //       },
          //     ],
          //     { cancelable: false }
          //   );

          //   CommonStore.update(s => {
          //     s.isLoading = false;
          //   });
          // }
          // else {
          //   console.log("payment success v2");

          //   placeUserOrder(result);
          // }

          // placeUserOrder(result);
        });
      }
      else {
        Alert.alert('Error', 'Sorry, an error occurred, please try again.');
      }
    });
  }

  const calculateFinalTotal = () => {
    console.log("cartItem", cartItem)
    const totalFloat = parseFloat(
      cartItem
        .reduce(
          (accumulator, current) =>
            accumulator + current.price * current.quantity,
          0,
        )
    )

    const totalTax = parseFloat(
      (cartItem
        .reduce(
          (accumulator, current) =>
            accumulator + current.price * current.quantity,
          0,
        )
        .toFixed(2) /
        parseFloat(100)) *
      parseFloat(Cart.getTax()),
    )

    //+ '001' //.toFixed() bug, occour only if last significant digit is 5 and if fixing to only 1 less digit, i.e. 1.75.toFixed(1) => 1.7 instead of 1.8
    var taxStr = totalTax.toString().split('.')
    console.log(taxStr)
    if (taxStr.length > 1) {
      taxStr[1] = taxStr[1] + '001'
    }
    const taxFloat = parseFloat(taxStr[0] + '.' + taxStr[1])

    setState({ totalFloat: parseFloat(totalFloat).toFixed(2), taxFloat: parseFloat(taxFloat).toFixed(2) })
  }

  const generateScheduleTime = () => {
    const now = new Date()
    var m = (((now.getMinutes() + 7.5) / 15 | 0) * 15) % 60;
    var h = ((((now.getMinutes() / 105) + .5) | 0) + now.getHours()) % 24;
    const nearestNow = new Date(now.getFullYear(), now.getMonth(), now.getDate(), h, m)
    var startTime = moment(nearestNow, 'YYYY-MM-DD hh:mm')
    var endTime = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, now.getHours(), now.getMinutes()), 'YYYY-MM-DD hh:mm a');

    var result = [];
    var current = moment(startTime);

    var tempstr = current.format('llll').split(',')
    var daystr = tempstr[0]
    var datestr = tempstr[1].split(' ')
    var yeartimestr = tempstr[2].split(' ')
    var currentNearestTime = daystr + ' ' + datestr[2] + ' ' + datestr[1] + ',' + yeartimestr[2] + ',' + yeartimestr[1] + ',' + yeartimestr[3]
    result.push(currentNearestTime);


    current.add(15, 'minutes');
    while (current <= endTime) {
      var tempstr = current.format('llll').split(',')
      console.log("tempstr", tempstr)
      var daystr = tempstr[0]
      var datestr = tempstr[1].split(' ')
      var yeartimestr = tempstr[2].split(' ')
      var finalstr = daystr + ' ' + datestr[2] + ' ' + datestr[1] + ',' + yeartimestr[2] + ',' + yeartimestr[1] + ',' + yeartimestr[3]
      result.push(finalstr);

      current.add(15, 'minutes');
    }
    console.log("schedule result", result)
    setState({ schedulteTimeList: result, schedulteTimeSelected: result[0], currentNearestTime: currentNearestTime })
  }

  const renderSchedule = (item) => (
    <View style={{ flexDirection: 'row', padding: 20, justifyContent: 'space-between', alignContent: 'center' }}>
      <View style={{ justifyContent: 'flex-start', marginHorizontal: 10 }}>
        <Text>
          {/* {currentNearestTime == item.toString() ? 'TODAY' : item.toString().split(',')[0]} */}
        </Text>
      </View>
      <View style={{ justifyContent: 'flex-end', marginHorizontal: 10 }}>
        <Text>
          {/* {currentNearestTime == item.toString() ? 'ASAP' : item.toString().split(',')[1]} */}
        </Text>
      </View>
    </View>
  )

  const proceedToMenuItemDetailsForUpdating = async (item) => {
    const outletItemSnapshot = await firebase.firestore().collection(Collections.OutletItem)
      .where('uniqueId', '==', item.itemId)
      .limit(1)
      .get();

    if (!outletItemSnapshot.empty) {
      const outletItem = outletItemSnapshot.docs[0].data();

      CommonStore.update(s => {
        s.selectedOutletItem = outletItem;

        s.onUpdatingCartItem = item;
      });

      // props.navigation.navigate("MenuItemDetails", {
      //   refresh: refresh.bind(this),
      //   menuItem: outletItem,
      //   cartItem: item,
      //   outletData: selectedOutlet,
      // });

      linkTo(`${prefix}/outlet/menu/item`);
    }
  };

  const removeFromCartItems = async (item) => {
    var updateCartItemIndex = 0;

    for (var i = 0; i < cartItems.length; i++) {
      if (cartItems[i].itemId === item.itemId &&
        cartItems[i].cartItemDate === item.cartItemDate) {
        updateCartItemIndex = i;
      }
    }

    const newCartItems = [
      ...cartItems.slice(0, updateCartItemIndex),
      ...cartItems.slice(updateCartItemIndex + 1),
    ];

    if (newCartItems.length > 0) {
      await AsyncStorage.setItem(`${firebaseUid}.cartItems`, JSON.stringify(newCartItems));
      await AsyncStorage.setItem(`${firebaseUid}.cartOutletId`, selectedOutlet.uniqueId);
    }
    else {
      await AsyncStorage.removeItem(`${firebaseUid}.cartItems`);
      await AsyncStorage.removeItem(`${firebaseUid}.cartOutletId`);
    }

    CommonStore.update(s => {
      s.cartItems = newCartItems;

      if (newCartItems.length <= 0) {
        s.cartOutletId = null;
      }
    });
  };

  const onPressMpsChannel = channel => {
    CommonStore.update(s => {
      s.isLoading = true;
    });

    startMolPay(channel);
  };

  const renderMpsChannelList = ({ item }) => {
    return (
      <TouchableOpacity style={{
        flexDirection: 'row',
        alignItems: 'center',
        width: '80%',
        alignSelf: 'center',

        // marginHorizontal: 24,
        marginVertical: 10,
        paddingVertical: 12,

        backgroundColor: Colors.primaryColor,
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,

        justifyContent: 'center',
        borderRadius: 8,
      }} onPress={() => onPressMpsChannel(item)}>
        <Text style={{
          fontSize: 14,
          textAlign: 'center',
          color: 'white',
          width: '100%',
          alignSelf: 'center',
          // backgroundColor: 'red'
        }}
        // numberOfLines={1}
        >
          {item.label}
        </Text>
      </TouchableOpacity>
    )
  };

  const renderItem = (params) => {
    // var item = {
    //   ...params.item,
    // };

    // if (cartOutletItemsDict[item.itemId]) {
    //   item.image = cartOutletItemsDict[item.itemId].image;
    //   item.name = cartOutletItemsDict[item.itemId].name;
    //   item.itemName = cartOutletItemsDict[item.itemId].name;

    // }

    const item = params.item;

    console.log('item');
    console.log(item);

    var itemNameFontSize = 19;

    if (Dimensions.get('window').width <= 360) {
      itemNameFontSize = 14;
      //console.log(Dimensions.get('window').width)
    }

    const itemNameTextScale = {
      fontSize: itemNameFontSize,
    };

    var priceFontSize = 14;

    if (Dimensions.get('window').width <= 360) {
      priceFontSize = 10;
      //console.log(Dimensions.get('window').width)
    }

    const priceTextScale = {
      fontSize: priceFontSize,
    };

    var addOnFontSize = 14;

    if (Dimensions.get('window').width <= 360) {
      addOnFontSize = 11;
      //console.log(Dimensions.get('window').width)
    }

    const addOnTextScale = {
      fontSize: addOnFontSize,
    };

    var overrideCategoryPrice = undefined;
    if (selectedOutletItemCategoriesDict[item.categoryId] && overrideCategoryPriceNameDict[selectedOutletItemCategoriesDict[item.categoryId].name] !== undefined) {
      overrideCategoryPrice = overrideCategoryPriceNameDict[selectedOutletItemCategoriesDict[item.categoryId].name];
    }

    return (
      <>
        <View
          style={{
            flexDirection: "row",
            paddingVertical: 10,
            borderColor: Colors.descriptionColor,
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
            display: 'flex',
            // borderBottomWidth: StyleSheet.hairlineWidth,
            marginBottom: 5,
          }}
        >
          <View
            style={[{
              marginRight: 15,
              backgroundColor: Colors.secondaryColor,
              borderRadius: 5,
              alignSelf: "flex-start",
              // flex: 1,            
            }, item.image ? {
              // display: 'flex',
              // alignItems: 'center',
              // justifyContent: 'center',
            } : {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: 5,
              paddingBottom: 5,
            }]}
          >
            {item.image
              ?
              <AsyncImage
                source={{ uri: item.image }}
                style={{
                  // width: 70, 
                  // height: 70,
                  width: isMobile() ? Dimensions.get('window').width * 0.22 : Dimensions.get('window').width * 0.05,
                  height: isMobile() ? Dimensions.get('window').width * 0.22 : Dimensions.get('window').width * 0.05,
                  borderRadius: 5,
                }}
              />
              :
              <Ionicons name="fast-food-outline" size={45} />
            }
          </View>

          <View style={{
            // backgroundColor: 'red',
            width: isMobile() ? '40%' : Dimensions.get('window').width * 0.78,
          }}>
            <View style={{
              display: 'flex',
              // backgroundColor: 'red',
            }}>
              <Text style={[itemNameTextScale, {
                // fontWeight: "700",
                //fontSize: 19,
                fontFamily: "NunitoSans-Bold",
                color: Colors.mainTxtColor,
              }]} numberOfLines={3}>
                {item.name}
              </Text>

              {/* {item.options ? item.options.map((item, index) => {
              return (
                (item.quantity > 0 ? <Text style={{ color: Colors.descriptionColor, marginTop: 5, fontWeight: '400', fontSize: 11, fontFamily: "NunitoSans-Regular" }}>
                  {" "}
                + {item.quantity} ({item.itemName})
              </Text> : null)
              );
            }) : null} */}

              {item.addOns && item.addOns.length > 0 &&
                item.addOns.map((addOn, index) => {
                  const addOnChoices = addOn.choiceNames.join(', ');

                  return (
                    <Text
                      key={index}
                      style={[addOnTextScale, {
                        color: Colors.descriptionColor,
                        marginTop: 3,
                        // fontWeight: "700",
                        //fontSize: 14,
                        marginBottom: 3,
                        fontFamily: "NunitoSans-Regular"
                      }]}
                    >
                      {/* Size: {item.size == "small" ? "Small" : item.size == "medium" ? "Medium" : item.size == "big" ? "Big" : null} */}
                      {addOn.quantities ?
                        <>
                          {`+ ${addOn.quantities.reduce((accum, value) => accum + value, 0)} (${addOnChoices})`}
                        </>
                        :
                        <>
                          {`${addOn.name} (${addOnChoices})`}
                        </>
                      }
                    </Text>
                  );
                })
              }
            </View>

            {/* <Text style={{ 
            fontWeight: "700", 
            fontSize: 19, 
            fontFamily: 
            "NunitoSans-Regular" 
          }}>
            {test1 == 1 ? item.name : test2 == 1 ? item.name : ('x' + item.quantity + " " + item.name)}
          </Text> */}

            {/* {test1 == 1 ?
            <Text style={{ fontSize: 14, fontFamily: "NunitoSans-Regular", color: Colors.descriptionColor }}> ({item.quantity} days extension)
          </Text>
            : test2 == 1 ? <Text style={{ fontSize: 14, fontFamily: "NunitoSans-Regular", color: Colors.descriptionColor }}> x{item.quantity} mugs
            </Text> : null} */}

            {item.remarks ? <Text
              style={{
                color: Colors.descriptionColor, marginTop: 0, fontWeight: '400', fontSize: 11, fontFamily: "NunitoSans-Regular"
              }}
            >
              Remark: {item.remarks}
            </Text> : null}

            {/* {type == 0 ? <TouchableOpacity
              onPress={() => {
                optional(item.itemId);
              }}
            >
              <Text
                style={{
                  color: Colors.primaryColor,
                  marginTop: 3,
                  fontWeight: "700",
                  fontSize: 14,
                  marginBottom: 3,
                  fontFamily: "NunitoSans-Regular"
                }}
              >
                {item.fireOrder == 0 ? "Serve now" : item.fireOrder == 1 ? "Serve later" : null}
              </Text>
            </TouchableOpacity> : null} */}
          </View>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              justifyContent: "center",

              width: isMobile() ? '20%' : '4%',
              // backgroundColor: 'blue',
            }}
          >
            <Text
              style={[priceTextScale, {
                // fontWeight: "400",
                // textAlign: "center",
                color: Colors.descriptionColor,
                //fontSize: 14,
                fontFamily: "NunitoSans-Regular",
              }]}
            >
              {/* RM{(parseFloat(item.price) * item.quantity).toFixed(2)} */}
              RM{
                (overrideItemPriceSkuDict[item.sku] !== undefined ||
                  overrideCategoryPrice !== undefined)
                  ?
                  parseFloat(overrideItemPriceSkuDict[item.sku] || overrideCategoryPrice).toFixed(2)
                  :
                  (parseFloat(item.price)).toFixed(2)
              }
            </Text>

            {/* <TouchableOpacity
            onPress={() => {
              if (item.quantity <= 1) {
                Cart.deleteCartItem(item.itemId, item);
                getCartItem();
              }
              else {
                setState({
                  visible: true,
                  editingItemId: item.itemId,
                  qty: item.quantity,
                  value: item.quantity,
                });
              }
            }}
          >
            <Entypo name="cross" size={28} color="red" />
          </TouchableOpacity> */}
          </View>

          <View style={{
            width: '10%',
            marginLeft: 15,
            alignItems: 'center',
            // marginRight: 5,
          }}>
            {
              !item.isFreeItem
                ?
                <>
                  <TouchableOpacity
                    onPress={() => {
                      console.log("ITEM@@@@@@@@@@@@@@@@@@@@@@@@@@@@", item)

                      proceedToMenuItemDetailsForUpdating(item);

                      // props.navigation.navigate("MenuItemDetailsUpdate", {
                      //   refresh: refresh.bind(this),
                      //   menuItem: item.menuItem,
                      //   cartItem: item,
                      //   outletData: outletData,
                      // });
                    }}
                  // style={{ width: 40, alignItems: 'center' }}
                  >
                    <View ><Icons name="edit" size={25} color={Colors.primaryColor} /></View>
                  </TouchableOpacity>

                  <TouchableOpacity
                    onPress={() => {
                      removeFromCartItems(item);
                    }}
                    style={{
                      // backgroundColor: 'green',
                      marginTop: 10,
                      right: 1,
                      // width: 40, 
                      // alignItems: 'center'
                    }}
                  >
                    <FontAwesome name="trash-o" size={25} color={Colors.tabRed} />
                  </TouchableOpacity>
                </>
                :
                <Text style={{
                  fontFamily: 'NunitoSans-Regular',
                  fontSize: 14,
                  color: Colors.descriptionColor,
                }}>
                  {'Free Item'}
                </Text>
            }
          </View>
        </View>

        <View style={{
          height: 1.5,
          left: '-2%',
          width: '104%',
          backgroundColor: '#C2C1C0',
          opacity: 0.2,
          marginBottom: 4,

          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 1,
          },
          shadowOpacity: 0.22,
          shadowRadius: 2.22,
          elevation: 3,
        }}>
        </View>
      </>
    )
  };

  const changeClick = () => { //delivery
    if (clicked == 1) {
      //setState({ clicked: 0 })
    }
    else {
      // setState({ clicked: 1, clicked1: 0, shouldShow: true })
      setClicked(1);
      setClicked1(0);
      // setShouldShow(true);

      Cart.setOrderType(1);

      CommonStore.update(s => {
        s.orderType = ORDER_TYPE.DELIVERY;
      });
    }
  }

  const changeClick1 = () => { //pickup
    if (clicked1 == 1) {
      //setState({ clicked1: 0 })
    }
    else {
      // setState({ clicked1: 1, clicked: 0, shouldShow: false })
      setClicked(0);
      setClicked1(1);
      // setShouldShow(false);

      Cart.setOrderType(2);

      CommonStore.update(s => {
        s.orderType = ORDER_TYPE.PICKUP;
      });
    }
  }

  const changeOrderType = () => {
    if (orderType === ORDER_TYPE.DELIVERY) {
      Cart.setOrderType(2);

      CommonStore.update(s => {
        s.orderType = ORDER_TYPE.PICKUP;
      });
    }
    else if (orderType === ORDER_TYPE.PICKUP) {
      Cart.setOrderType(1);

      CommonStore.update(s => {
        s.orderType = ORDER_TYPE.DELIVERY;
      });
    }
  }

  const deleteUserCart = async () => {
    const body = {
      userCartId: userCart.uniqueId,
    };

    ApiClient.POST(API.deleteUserCart, body).then((result) => {
      if (result && result.status === 'success') {
        console.log('ok');
      }
    });
  };

  const proceedOrderAfterUserInfo = async () => {
    if (!userInfoName) {
      CommonStore.update(s => {
        s.alertObj = {
          title: 'Error',
          message: 'Name cannot be empty.',
        };
      });
      setShowUserInfoModal(false);
      return;
    }

    if (!userInfoPhone) {
      CommonStore.update(s => {
        s.alertObj = {
          title: 'Error',
          message: 'Phone number cannot be empty.',
        };
      });
      setShowUserInfoModal(false);
      return;
    }

    if (userInfoPhone.length === 10 || userInfoPhone.length === 11) {
      // ok

      if (selectedPaymentOptions.value === USER_ORDER_PAYMENT_OPTIONS.ONLINE) {
        // startMolPay();
        setShowMpsChannelModal(true);
      }
      else if (selectedPaymentOptions.value === USER_ORDER_PAYMENT_OPTIONS.OFFLINE) {
        CommonStore.update(s => {
          s.isLoading = true;
        });

        placeUserOrder(null);
      }
      else {
        Alert.alert('Error', 'Invalid payment options');
      }
    }
    else {
      CommonStore.update(s => {
        s.alertObj = {
          title: 'Error',
          message: 'Invalid phone number format.',
        };
      });
      setShowUserInfoModal(false);
      return;
    }
  };

  // function end

  return (
    <>
      {
        isLoading && paymentDetails !== null
          ?
          <View style={{
            // backgroundColor: 'red',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',

            marginBottom: '25%',
          }}>
            <ActivityIndicator color={Colors.primaryColor} size={"large"} />
          </View>
          :
          <>
            {
              !isPlacingOrder
                ?
                cartItemsProcessed.length === 0 ? (
                  <View style={styles.container}>
                    <View style={{ flex: 1 }}>
                      <View style={{ flex: 1 }}>
                        {isLoading ?
                          <View style={{
                            // backgroundColor: 'red',
                            width: '100%',
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',

                            marginBottom: '25%',
                          }}>
                            <ActivityIndicator color={Colors.primaryColor} size={"large"} />
                          </View>
                          :
                          <View style={{
                            // backgroundColor: 'red',
                            width: '100%',
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                            <Text
                              style={{
                                color: Colors.descriptionColor,
                                textAlign: "center",
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: 16,
                                marginBottom: '30%',
                              }}
                            >
                              No items in your cart now.
                            </Text>
                          </View>
                        }
                      </View>

                      {/* <TouchableOpacity
                  onPress={() => {
                    props.navigation.navigate("Home");
                  }}
                >
                  <View
                    style={{
                      backgroundColor: Colors.primaryColor,
                      padding: 20,
                      paddingVertical: 16,
                      borderRadius: 10,
                      alignItems: 'center',

                      marginHorizontal: 48,
                      marginTop: 24,
                      marginBottom: 24,
                    }}>
                    <Text style={{
                      color: '#ffffff',
                      fontSize: 21,
                      fontFamily: 'NunitoSans-Regular',
                    }}>Explore outlet now</Text>
                  </View>
                </TouchableOpacity> */}
                    </View>
                  </View>
                ) : (

                  <View style={styles.container}>
                    {/*<ActionSheet
              ref={(o) => (ActionSheet = o)}
              title={'Select Your Payment Method'}
              options={['Online payment', 'Credit/Debit Card', 'cancel', 'Cash on delivery']}
              cancelButtonIndex={2}
              destructiveButtonIndex={2}
              onPress={(index) => {
                console.log(index);
                if (index != 2) {
                  setState({
                    paymentMethod:
                      index == 0 ? 'Online payment' : 'Cash on delivery',
                  });
                }
              }}
            />*/}
                    <Modal
                      style={{ flex: 1 }}
                      visible={showUserInfoModal}
                      transparent={true}>
                      <View style={styles.modalContainer}>
                        <View style={{
                          width: isMobile() ? Dimensions.get('window').width * 0.8 : Dimensions.get('window').width * 0.3,
                          height: isMobile() ? Dimensions.get('screen').height * 0.4 : Dimensions.get('screen').height * 0.35,
                          backgroundColor: Colors.whiteColor,
                          borderRadius: 8,
                          padding: isMobile() ? Dimensions.get('window').width * 0.07 : Dimensions.get('window').width * 0.02,
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}>
                          <TouchableOpacity
                            style={styles.closeButton}
                            onPress={() => {
                              setShowUserInfoModal(false);
                            }}>
                            <AntDesign name="closecircle" size={25} color={Colors.fieldtTxtColor} />
                            {/* <SimpleLineIcons name="close" size={25}
                                    color={Colors.fieldtTxtColor}
                                /> */}
                          </TouchableOpacity>

                          <View style={styles.modalTitle, {
                            // backgroundColor: 'red'
                          }}>
                            <Text style={styles.modalTitleText}>User Info Required</Text>
                          </View>

                          <View style={[styles.modalBody, {
                            width: "100%",
                            alignItems: 'center',
                            // alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            // backgroundColor: 'blue'
                          }]}>
                            <View style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              width: '100%',
                              // backgroundColor: 'green',
                            }}>
                              <Text style={[styles.modalBodyText, { fontSize: 16, fontFamily: 'NunitoSans-Bold' }]}>Name</Text>

                              <TextInput
                                style={[styles.textInput, {
                                  // marginHorizontal: 24,
                                  width: '75%',
                                }]}
                                multiline={false}
                                clearButtonMode="while-editing"
                                placeholder="Enter your name here"
                                onChangeText={(text) => {
                                  // setUserInfoName(text)

                                  UserStore.update(s => {
                                    s.userInfoName = text;
                                  });
                                }}
                                value={userInfoName}
                              />
                            </View>

                            <View style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              width: '100%',
                              // backgroundColor: 'green',
                            }}>
                              <Text style={[styles.modalBodyText, { fontSize: 16, fontFamily: 'NunitoSans-Bold' }]}>Phone</Text>

                              <TextInput
                                style={[styles.textInput, {
                                  // marginHorizontal: 24,
                                  width: '75%',
                                }]}
                                multiline={false}
                                clearButtonMode="while-editing"
                                placeholder="Enter your phone here"
                                onChangeText={(text) => {
                                  // setUserInfoPhone(text) 

                                  UserStore.update(s => {
                                    s.userInfoPhone = text;
                                  });
                                }}
                                value={userInfoPhone}
                                keyboardType={'phone-pad'}
                              />
                            </View>
                          </View>

                          <View style={{
                            alignItems: 'center',
                            flexDirection: 'row',
                            // justifyContent: 'space-between',
                            justifyContent: 'center',
                            width: '100%',
                          }}>
                            {/* <TouchableOpacity
                style={styles.modalSaveButton}
                onPress={() => {
                  setInputTablePax(selectedOutletTable.capacity);
                  setUpdateTableModal(true);
                }}>
                <Text style={[styles.modalDescText, { color: Colors.primaryColor }]}>Edit</Text>
              </TouchableOpacity> */}

                            <TouchableOpacity
                              style={styles.modalSaveButton}
                              onPress={() => { proceedOrderAfterUserInfo() }}>
                              <Text style={[styles.modalDescText, { color: Colors.primaryColor }]}>Proceed</Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                    </Modal>

                    <ScrollView
                      style={{ flex: 1 }}
                      contentContainerStyle={{
                        paddingBottom: 60,
                      }}
                      showsVerticalScrollIndicator={false}
                      refreshControl={
                        <RefreshControl
                          refreshing={refreshing}
                          onRefresh={() => {
                            getCartItem();
                            getPopular()
                          }}
                        />
                      }>

                      <button id='myPay' style={{
                        display: 'none',
                      }} type="button" data-toggle="molpayseamless">Pay by RazerPay</button>

                      {/* <View style={{ flexDirection: "row", marginBottom: 10, marginTop: 5 }}>
                <TouchableOpacity style={{
                  width: "30%",
                  height: 30,
                  backgroundColor: orderType === ORDER_TYPE.DELIVERY ? Colors.primaryColor : Colors.whiteColor,
                  borderRadius: 40, justifyContent: "center", alignItems: "center", borderWidth: 1, borderColor: Colors.descriptionColor
                }}
                  onPress={() => { changeClick() }}
                >
                  <Text style={{ color: orderType === ORDER_TYPE.DELIVERY ? Colors.whiteColor : "#adadad", fontFamily: "NunitoSans-Regular" }}>Delivery</Text>
                </TouchableOpacity>
                <TouchableOpacity style={{
                  width: "30%",
                  height: 30,
                  backgroundColor: orderType === ORDER_TYPE.PICKUP ? Colors.primaryColor : Colors.whiteColor,
                  borderRadius: 40, justifyContent: "center", alignItems: "center", borderWidth: 1, borderColor: Colors.descriptionColor, marginLeft: 8
                }}
                  onPress={() => { changeClick1() }}
                >
                  <Text style={{ color: orderType === ORDER_TYPE.PICKUP ? Colors.whiteColor : "#adadad", fontFamily: "NunitoSans-Regular" }}>Pick-up</Text>
                </TouchableOpacity>
              </View> */}

                      {/* {orderType !== ORDER_TYPE.DINEIN &&
                  <View style={{
                    flexDirection: "row",
                    marginBottom: 15,
                    marginTop: 5,
                    paddingHorizontal: 24,
                  }}>
                    <TouchableOpacity style={{
                      // width: "30%",
                      // height: 30,
                      width: 86,
                      backgroundColor: orderType === ORDER_TYPE.DELIVERY ? Colors.primaryColor : Colors.whiteColor,
                      borderRadius: 40, justifyContent: "center", alignItems: "center", borderWidth: 1,
                      borderColor: orderType === ORDER_TYPE.DELIVERY ? Colors.descriptionColor : "#adadad",
                      paddingVertical: 6,
                    }}
                      onPress={() => { changeOrderType() }}
                    >
                      <Text style={{ color: orderType === ORDER_TYPE.DELIVERY ? Colors.whiteColor : "#adadad", fontFamily: "NunitoSans-SemiBold", fontSize: 13 }}>Delivery</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={{
                      // width: "30%",
                      // height: 30,
                      width: 86,
                      backgroundColor: orderType === ORDER_TYPE.PICKUP ? Colors.primaryColor : Colors.whiteColor,
                      borderRadius: 40, justifyContent: "center", alignItems: "center", borderWidth: 1,
                      borderColor: orderType === ORDER_TYPE.PICKUP ? Colors.descriptionColor : "#adadad",
                      marginLeft: 8,
                      paddingVertical: 6,
                    }}
                      onPress={() => { changeOrderType() }}
                    >
                      <Text style={{ color: orderType === ORDER_TYPE.PICKUP ? Colors.whiteColor : "#adadad", fontFamily: "NunitoSans-SemiBold", fontSize: 13 }}>Takeaway</Text>
                    </TouchableOpacity>
                  </View>
                } */}

                      <View style={{
                        width: "100%",
                        // height: 60,
                        paddingVertical: 16,
                        backgroundColor: "#ddebe5",
                        justifyContent: "center",
                        paddingHorizontal: 28,
                        // marginTop: 5,

                        shadowColor: '#000',
                        shadowOffset: {
                          width: 0,
                          height: 1,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 2.22,
                        elevation: 1,
                      }}>
                        <Text style={{
                          color: Colors.primaryColor,
                          marginLeft: 4,
                          fontSize: 17,
                          fontFamily: 'NunitoSans-SemiBold',
                        }}>Order Summary</Text>
                      </View>

                      <View style={{
                        flexDirection: 'row',
                        marginTop: 15,
                      }}>
                        <FlatList
                          style={{ marginBottom: 10 }}
                          data={cartItemsProcessed}
                          extraData={cartItemsProcessed}
                          renderItem={renderItem}
                          keyExtractor={(item, index) => String(index)}
                          contentContainerStyle={{
                            paddingHorizontal: 30,
                            paddingBottom: 5,
                          }}
                        />
                      </View>
                      {/* <Modal
                style={{ flex: 1 }}
                visible={visible}
                transparent={true}
                animationType="slide">
                <View
                  style={{
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <View style={styles.confirmBox}>
                    <TouchableOpacity
                      onPress={() => {
                        setState({ visible: false });
                        setState({ visible1: false });
                      }}>
                      <View
                        style={{
                          alignSelf: 'flex-end',
                          padding: 16,
                        }}>
                        <Close name="closecircle" size={28} />
                      </View>
                    </TouchableOpacity>
                    <View>
                      <Text
                        style={{
                          textAlign: 'center',
                          fontWeight: 'bold',
                          fontSize: 16,
                          marginBottom: 5,
                        }}>
                        Do you want to delete all?
                    </Text>
                    </View>
                    <View
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        alignContent: 'center',
                        marginBottom: '6%',
                        height: '50%',
                      }}>
                      <View
                        style={{
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}>
                        <TouchableOpacity
                          onPress={() => {
                            Cart.deleteCartItem(editingItemId);
                            getCartItem();
                            setState({ visible1: false });
                            setState({ visible: false });
                          }}
                          style={{
                            backgroundColor: Colors.fieldtBgColor,
                            width: '30%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignContent: 'center',
                            borderRadius: 5,
                            height: '75%',
                          }}>
                          <Text style={{ fontSize: 15, color: Colors.primaryColor }}>
                            Yes
                        </Text>
                        </TouchableOpacity>
                        <View style={{ marginLeft: '3%', marginRight: '3%' }}></View>
                        <TouchableOpacity
                          onPress={() => {
                            setState({ visible1: true });
                          }}
                          style={{
                            backgroundColor: Colors.fieldtBgColor,
                            width: '30%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignContent: 'center',
                            borderRadius: 5,
                            height: '75%',
                          }}>
                          <Text style={{ fontSize: 15, color: Colors.primaryColor }}>
                            No
                        </Text>
                        </TouchableOpacity>
                        
                      </View>
                    </View>
                  </View>
                </View>
              </Modal> */}
                      <Modal
                        style={{ flex: 1 }}
                        visible={visible}
                        transparent={true}
                        animationType="slide">
                        <View
                          style={{
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          <View style={styles.confirmBox}>
                            <TouchableOpacity
                              onPress={() => {
                                setState({ visible1: false });
                                setState({ visible: false });
                              }}>
                              <View
                                style={{
                                  alignSelf: 'flex-end',
                                  padding: 13,
                                }}>
                                <Close name="closecircle" size={28} />
                              </View>
                            </TouchableOpacity>
                            <View>
                              <Text
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  fontSize: 16,
                                  marginBottom: 10,
                                }}
                              >
                                Delete
                              </Text>
                            </View>
                            <View
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                alignContent: "center",
                                marginBottom: "10%",
                                height: "50%",
                              }}
                            >
                              <View
                                style={{
                                  alignItems: "center",
                                  marginBottom: "2%",
                                }}
                              >
                                {/* <NumericInput
                            value={value}
                            onChange={(value) => setState({ value })}
                            minValue={1}
                            maxValue={qty}
                            totalWidth={200}
                            totalHeight={40}
                            iconSize={25}
                            step={1}
                            valueType="real"
                            rounded
                            textColor={Colors.primaryColor}
                            iconStyle={{ color: "white" }}
                            rightButtonBackgroundColor={Colors.primaryColor}
                            leftButtonBackgroundColor={"grey"}
                          /> */}
                              </View>
                              <View
                                style={{
                                  alignItems: "center",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <TouchableOpacity
                                  onPress={() => {
                                    onChangeQty(
                                      value,
                                      editingItemId
                                    );
                                    getCartItem();
                                    setState({ visible1: false });
                                    setState({ visible: false });
                                    setState({ value: "" });
                                  }}
                                  style={{
                                    backgroundColor: Colors.primaryColor,
                                    width: "30%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignContent: "center",
                                    borderRadius: 5,
                                    height: "75%",
                                    marginTop: 10,
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 15,
                                      color: Colors.whiteColor,
                                    }}
                                  >
                                    Update
                                  </Text>
                                </TouchableOpacity>

                              </View>
                            </View>
                          </View>
                        </View>
                      </Modal>

                      <Modal
                        style={{ flex: 1 }}
                        visible={showMpsChannelModal}
                        transparent={true}
                        animationType="fade"
                      >
                        <View
                          style={{
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          <View style={[styles.confirmBox, {
                            width: isMobile() ? '80%' : '40%',
                            height: isMobile() ? '80%' : '70%',
                            borderRadius: 10,
                          }]}>
                            <TouchableOpacity
                              onPress={() => {
                                setShowMpsChannelModal(false);
                              }}>
                              <View
                                style={{
                                  alignSelf: 'flex-end',
                                  padding: 13,
                                }}>
                                <Close name="closecircle" size={28} />
                              </View>
                            </TouchableOpacity>
                            <View>
                              <Text
                                style={{
                                  textAlign: "center",
                                  fontSize: 16,
                                  marginBottom: 10,
                                  fontFamily: 'NunitoSans-Bold'
                                }}
                              >
                                Choose your payment method
                              </Text>
                            </View>
                            <View
                              style={{
                                marginTop: 15,
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                alignContent: "center",
                                // marginBottom: "10%",
                                height: "75%",
                              }}
                            >
                              {
                                !isLoading
                                  ?
                                  <FlatList
                                    data={MPS_CHANNEL_LIST}
                                    renderItem={renderMpsChannelList}
                                    keyExtractor={(item, index) => String(index)}
                                    contentContainerStyle={{
                                      paddingHorizontal: 24,
                                    }}
                                  />
                                  :
                                  <ActivityIndicator color={Colors.primaryColor} size={"large"} />
                              }

                            </View>
                          </View>
                        </View>
                      </Modal>

                      {/* <Text style={{
                fontWeight: "bold",
                fontSize: 20,
                marginTop: 10,
                marginBottom: 10,
    
                paddingHorizontal: 24
              }}>Other popular items</Text>
    
              <FlatList
                horizontal={true}
                data={popular}
                extraData={popular}
                renderItem={renderPopularItem}
                keyExtractor={(item, index) => String(index)}
                contentContainerStyle={{
                  paddingHorizontal: 24,
                }}
              /> */}

                      {/* <View style={{ height: 20 }}></View> */}

                      <View style={{
                        // backgroundColor: 'red',
                        flexDirection: 'row',
                        width: isMobile() ? '80%' : '94%',
                        marginLeft: 40,
                        marginTop: 0,

                        paddingHorizontal: 0,
                      }}>
                        <View style={{ flex: 1 }}>
                          <Text style={styles.description}>Subtotal</Text>
                          <Text style={styles.description}>Discount</Text>
                          <Text style={styles.description}>
                            Tax ({Cart.getTax()}%)
                          </Text>
                          {orderType == ORDER_TYPE.DELIVERY ? <View>
                            <Text style={styles.description}>{`Delivery Fees (${selectedSender.label})`}</Text>
                            <Text style={styles.smallDescription}>
                              (This fees is quoted by 3rd party logistic and it might be different as it depends on the exact distance)
                            </Text>
                          </View> : null}
                          <Text style={styles.description}></Text>
                        </View>

                        <View style={{
                          width: '25%',
                        }}>
                          <View style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}>
                            <Text style={styles.price}>
                              RM
                            </Text>

                            <Text style={styles.price}>
                              {totalPrice.toFixed(2)}
                            </Text>
                          </View>

                          <View style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}>
                            <Text style={styles.price}>
                              RM
                            </Text>

                            <Text style={styles.price}>
                              {`${(totalDiscount +
                                (usePointsToRedeem ? pointsToRedeemDiscount : 0)
                              ).toFixed(2)}`}
                            </Text>
                          </View>

                          <View style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}>
                            <Text style={styles.price}>
                              RM
                            </Text>

                            <Text style={styles.price}>
                              {totalTax.toFixed(2)}
                            </Text>
                          </View>

                          {/* <Text style={styles.price}>
                            {`RM ${(totalDiscount +
                              (usePointsToRedeem ? pointsToRedeemDiscount : 0)
                            ).toFixed(2)}`}
                          </Text>
                          <Text style={styles.price}>
                            RM{' '}
                            {totalTax.toFixed(2)}
                          </Text>
                          {orderType == ORDER_TYPE.DELIVERY ? <Text style={styles.price}>RM {deliveryQuotation.totalFee == null ? "0.00" : deliveryQuotation.totalFee.toFixed(2)}</Text> : null} */}
                          {/* <Text style={styles.price}></Text>
                  <Text style={styles.price}></Text> */}
                        </View>
                      </View>

                      <View style={{
                        flexDirection: 'row',
                        width: isMobile() ? '80%' : '94%',
                        marginLeft: 40,

                        paddingHorizontal: 0,
                        marginTop: 0,
                        marginBottom: 12,
                      }}>
                        <View style={{ flex: 1 }}>
                          <Text style={styles.total}>TOTAL</Text>
                        </View>
                        <View style={{
                          width: '25%',
                        }}>

                          <View style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}>
                            <Text style={[styles.totalPrice, {
                              fontSize: 13,
                            }]}>
                              RM
                            </Text>

                            <Text style={[styles.totalPrice, {
                              fontSize: 13,
                            }]}>
                              {(
                                // parseFloat(totalFloat) +
                                // parseFloat(taxFloat) +
                                // parseFloat(deliveryQuotation == null ? 0.00 : parseFloat(deliveryQuotation.totalFee).toFixed(2))
                                Math.max(totalPrice + totalTax +
                                  (orderType === ORDER_TYPE.DELIVERY ? (deliveryQuotation.totalFee ? deliveryQuotation.totalFee : 0) : 0) -
                                  totalDiscount - (usePointsToRedeem ? pointsToRedeemDiscount : 0),
                                  0)
                              ).toFixed(2)}
                            </Text>
                          </View>

                          {/* <Text style={styles.totalPrice}>
                            RM{' '}
                            {(
                              // parseFloat(totalFloat) +
                              // parseFloat(taxFloat) +
                              // parseFloat(deliveryQuotation == null ? 0.00 : parseFloat(deliveryQuotation.totalFee).toFixed(2))
                              Math.max(totalPrice + totalTax +
                                (orderType === ORDER_TYPE.DELIVERY ? (deliveryQuotation.totalFee ? deliveryQuotation.totalFee : 0) : 0) -
                                totalDiscount - (usePointsToRedeem ? pointsToRedeemDiscount : 0),
                                0)
                            ).toFixed(2)}
                          </Text> */}
                        </View>
                      </View>

                      {/* <TouchableOpacity onPress={() => { }} style={[styles.textInput1, {
                  marginHorizontal: 36,
                  width: '80%',
                  alignSelf: 'center',

                }]}
                  onPress={() => {
                    setRouteFrom(1);
                    props.navigation.navigate("Voucher", { pageFrom: 'CartScreen' });

                    props.navigation.navigate("Voucher", {
                      screen: 'Voucher',
                      params: {
                        pageFrom: 'CartScreen',
                      }
                    });
                  }}
                >
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                    <Text
                      style={{
                        paddingVertical: 10,
                        fontSize: 15,
                        fontFamily: 'NunitoSans-Bold',
                      }}>
                      Apply Vouchers
                    </Text>


                    <View style={{
                      marginLeft: '30%', 
                      justifyContent: 'center'
                    }}>
                      <Entypo name="chevron-thin-down" size={22} />
                    </View>

                  </View>

                  
                </TouchableOpacity> */}
                      {/* <DropDownPicker style={[styles.textInput1, { marginHorizontal: 36, width: '80%', alignSelf: 'center', }]}
                    //disabled={}
                    // dropDownStyle={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, width: 200, paddingVertical: 0, marginHorizontal: 10 }}
                    items={[{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }]}
                    arrowSize={22}
                    arrowColor={'black'}
                    arrowStyle={{ paddingVertical: 0 }}
                    itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                    placeholderStyle={{ marginLeft: 10, fontSize: 15, fontFamily: 'NunitoSans-Bold', }}
                    placeholder={"Apply Voucher"}
                    searchable={true}
                    // containerStyle={{ height: 30 }}
                    //   style={styles.textInput}
                    //   itemStyle={{
                    //     justifyContent: 'flex-start',
                    //   }}
                   
                    //onChangeItem={(item) => {
                    //  console.log(item);
                    //}}
                    //defaultValue={}
                  /> */}

                      {/* voucher */}
                      <View style={{
                        width: isMobile() ? '80%' : '94%',
                        alignSelf: 'center',
                        marginTop: 20,
                        display: 'none',
                      }}>
                        <Text style={[styles.payment, {
                          // paddingHorizontal: 24,
                        }]}>Select your voucher</Text>

                        <TouchableOpacity
                          // disabled={voucherList.length <= 1}
                          onPress={() => {
                            setShowVoucher(!showVoucher);
                          }}
                          style={[styles.textInput, {
                            // marginHorizontal: 24,
                          }]}>
                          <View style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            // backgroundColor: 'red',
                            height: '100%',
                          }}>
                            <View style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}>
                              <Image
                                source={selectedVoucher ? selectedVoucher.img : ''}
                                style={{
                                  marginRight: 10,
                                  width: 32,
                                  height: 32,
                                  borderRadius: 8,
                                  top: 1,
                                }}
                              />

                              <Text style={{
                                // paddingVertical: 14
                                fontSize: 15,
                                fontFamily: 'NunitoSans-Regular',
                                color: Colors.fieldtTxtColor,
                              }}>
                                {/* {'Credit/Debit Card'} */}
                                {selectedVoucher && selectedVoucher.label ? selectedVoucher.label : ''}
                              </Text>
                            </View>

                            <View style={{
                              // marginLeft: '40%',
                              justifyContent: 'center',
                              // opacity: voucherList.length > 1 ? 100 : 0,
                            }}>
                              <Entypo
                                name={showVoucher ? "chevron-thin-up" : "chevron-thin-down"}
                                size={22}
                                color={Colors.primaryColor}
                              />
                            </View>
                          </View>
                        </TouchableOpacity>

                        <View style={{
                          display: showVoucher ? 'flex' : 'none',
                          marginTop: -20,
                          paddingTop: 10,
                        }}>
                          {
                            voucherList.map((item, i) => {

                              return (
                                <>
                                  <TouchableOpacity style={{
                                    // marginHorizontal: 24,
                                    height: 50,
                                    paddingHorizontal: 20,
                                    backgroundColor: Colors.fieldtBgColor,
                                    borderRadius: 5,
                                    // marginTop: 10,
                                    // marginBottom: 10,  

                                    marginTop: -5,
                                  }} onPress={() => {
                                    setSelectedVoucher(item);

                                    UserStore.update(s => {
                                      s.selectedVoucherId = item.value;
                                    });

                                    setShowVoucher(false);
                                  }}>
                                    <View style={{
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      // backgroundColor: 'red',
                                      height: '100%',
                                    }}>
                                      <View style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                      }}>
                                        {

                                        }
                                        <Image
                                          source={item.img}
                                          style={{
                                            marginRight: 10,
                                            width: 32,
                                            height: 32,
                                            borderRadius: 8,
                                            top: 1,
                                          }}
                                        />

                                        <Text style={{
                                          // paddingVertical: 14
                                          fontSize: 15,
                                          fontFamily: 'NunitoSans-Regular',
                                          color: Colors.fieldtTxtColor,
                                        }}>
                                          {item.label}
                                        </Text>
                                      </View>

                                      <View style={{
                                        // marginLeft: '40%',
                                        justifyContent: 'center',
                                        opacity: 0,
                                      }}>
                                        <Entypo
                                          name="chevron-thin-down"
                                          size={22}
                                          color={Colors.primaryColor}
                                        />
                                      </View>
                                    </View>
                                  </TouchableOpacity>
                                </>
                              );
                            })
                          }
                        </View>

                      </View>

                      {/* promotion */}
                      <View style={{
                        width: isMobile() ? '80%' : '94%',
                        alignSelf: 'center',
                        marginTop: 20,
                        display: 'none',
                      }}>
                        <Text style={[styles.payment, {
                          // paddingHorizontal: 24,
                        }]}>Enter promotions code</Text>
                        <TextInput
                          style={[styles.textInput, {
                            // marginHorizontal: 24,
                          }]}
                          multiline={true}
                          clearButtonMode="while-editing"
                          placeholder="Enter promotions code"
                          onChangeText={(text) => { setPromoCode(text) }}
                          value={promoCode}
                        />
                      </View>

                      {selectedVoucherId && merchantVouchersDict[selectedVoucherId] &&
                        merchantVouchersDict[selectedVoucherId] && selectedOutlet.merchantId &&
                        <View style={{
                          width: "100%",
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginTop: 20,
                          justifyContent: 'space-around',
                        }}>
                          <View style={[styles.pic, {
                            // backgroundColor: 'red',
                            borderColor: '#E9CE8B',
                            borderWidth: 1,

                            marginLeft: 10,

                            shadowColor: '#000',
                            shadowOffset: {
                              width: 0,
                              height: 1,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 2.22,
                            elevation: 2,
                          }]}>
                            {merchantVouchersDict[selectedVoucherId].customLogo ?
                              <Image
                                source={{ uri: merchantVouchersDict[selectedVoucherId].customLogo }}
                                style={{ width: 90, height: Platform.OS == 'ios' ? 90 : 90, borderRadius: 10 }}
                              />
                              : null}
                            {merchantVouchersDict[selectedVoucherId].merchantLogo ?
                              <Image
                                source={{ uri: merchantVouchersDict[selectedVoucherId].merchantLogo }}
                                style={{ width: 90, height: Platform.OS == 'ios' ? 90 : 90, borderRadius: 10 }}
                              />
                              : null}
                            {!(merchantVouchersDict[selectedVoucherId].customLogo || merchantVouchersDict[selectedVoucherId].merchantLogo) ?
                              <Image
                                source={require('../asset/image/extend.png')}
                                style={{ width: 90, height: Platform.OS == 'ios' ? 90 : 90, borderRadius: 10 }}
                              />
                              : null}
                          </View>

                          <Text style={[styles.text, {
                            width: '50%',
                            fontFamily: 'NunitoSans-Regular',
                          }]}>{merchantVouchersDict[selectedVoucherId].description}</Text>
                        </View>
                      }

                      {/* payment method */}
                      <View style={{
                        width: isMobile() ? '80%' : '94%',
                        ...!isMobile() && {
                          // marginRight: '60%',
                        },
                        // backgroundColor: 'red',
                        alignSelf: 'center',
                        marginTop: 20,
                      }}>
                        <Text style={[styles.payment, {
                          // paddingHorizontal: 24,
                        }]}>Select your payment method</Text>

                        <TouchableOpacity
                          disabled={paymentOptionsList.length <= 1}
                          onPress={() => {
                            setShowPaymentOptions(!showPaymentOptions);
                          }}
                          style={[styles.textInput, {
                            // marginHorizontal: 24,
                          }]}>
                          <View style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            // backgroundColor: 'red',
                            height: '100%',
                          }}>
                            <Text style={{
                              // paddingVertical: 14
                              fontSize: 15,
                              fontFamily: 'NunitoSans-Regular',
                              color: Colors.fieldtTxtColor,
                            }}>
                              {/* {'Credit/Debit Card'} */}
                              {selectedPaymentOptions && selectedPaymentOptions.label ? selectedPaymentOptions.label : ''}
                            </Text>
                            <View style={{
                              // marginLeft: '40%',
                              justifyContent: 'center',
                              opacity: paymentOptionsList.length > 1 ? 100 : 0,
                            }}>
                              <Entypo
                                name={showPaymentOptions ? "chevron-thin-up" : "chevron-thin-down"}
                                size={22}
                                color={Colors.primaryColor}
                              />
                            </View>
                          </View>
                        </TouchableOpacity>

                        <View style={{
                          display: showPaymentOptions ? 'flex' : 'none',
                          marginTop: -20,
                          paddingTop: 10,
                        }}>
                          {
                            paymentOptionsList.map((item, i) => {
                              return (
                                <TouchableOpacity style={{
                                  // marginHorizontal: 24,
                                  height: 50,
                                  paddingHorizontal: 20,
                                  backgroundColor: Colors.fieldtBgColor,
                                  borderRadius: 5,
                                  // marginTop: 10,
                                  // marginBottom: 10,  

                                  marginTop: -5,
                                }} onPress={() => {
                                  setSelectedPaymentOptions(item);

                                  setShowPaymentOptions(false);
                                }}>
                                  <View style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    // backgroundColor: 'red',
                                    height: '100%',
                                  }}>
                                    <Text style={{
                                      // paddingVertical: 14
                                      fontSize: 15,
                                      fontFamily: 'NunitoSans-Regular',
                                      color: Colors.fieldtTxtColor,
                                    }}>
                                      {item.label}
                                    </Text>
                                    <View style={{
                                      // marginLeft: '40%',
                                      justifyContent: 'center',
                                      opacity: 0,
                                    }}>
                                      <Entypo
                                        name="chevron-thin-down"
                                        size={22}
                                        color={Colors.primaryColor}
                                      />
                                    </View>
                                  </View>
                                </TouchableOpacity>
                              );
                            })
                          }
                        </View>
                      </View>

                      {Cart.getPayment() &&
                        <View style={[styles.card, {
                          backgroundColor: 'transparent',
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginTop: 10,
                          justifyContent: 'flex-start',
                        }]}>
                          <View style={{
                            marginRight: Platform.OS == 'ios' ? 120 : 25,
                            left: -40,
                          }}>
                            <View style={[styles.pic, {
                              // width: 0,
                              // height: 0,
                              backgroundColor: 'transparent',
                              marginTop: 0,
                              marginRight: 30,
                            }]}>
                              <Image
                                resizeMode={'contain'}
                                source={{ uri: Cart.getPayment().image }}
                                style={{ width: 200, height: 70, borderRadius: 10 }}
                              />
                            </View>
                          </View>
                          <View style={{
                            // width: '60%',
                          }}>
                            <Text style={styles.title}>{Cart.getPayment().cardType}</Text>
                            <Text style={styles.text}>{Cart.getPayment().cardNumber.replace(/./g, '*')}</Text>
                          </View>
                        </View>
                      }

                      {
                        showPointsToRedeemOptions
                          ?
                          <View style={{
                            width: '80%',
                            alignSelf: 'center',
                            marginTop: 10,
                            marginBottom: 10,
                            paddingLeft: 2,
                          }}>
                            <View
                              style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}>
                              <TouchableOpacity
                                underlayColor="transparent"
                                onPress={() => {
                                  setUsePointsToRedeem(!usePointsToRedeem);
                                }}>
                                <View
                                  style={[
                                    styles.checkBox,
                                    {
                                      backgroundColor: usePointsToRedeem
                                        ? Colors.primaryColor
                                        : null,
                                    },
                                  ]}>
                                  <AntDesign name="check" size={16} color="#ffffff" />
                                </View>
                              </TouchableOpacity>

                              <Text
                                style={{
                                  color: Colors.descriptionColor,
                                  marginLeft: 12,
                                  fontSize: 14,
                                  fontFamily: 'NunitoSans-Regular',
                                }}>
                                {`Use ${pointsToRedeem} points to redeem (RM${pointsToRedeemDiscount.toFixed(2)} off)`}
                              </Text>
                            </View>

                            {/* <Text style={[styles.payment, {
                        // paddingHorizontal: 24,
                      }]}>{`Points to redeem (${userPointsBalance} points available)`}</Text>

                      <TextInput
                        underlineColorAndroid={Colors.fieldtBgColor}
                        style={styles.textInput}
                        placeholder="0"
                        onSubmitEditing={({ text }) => {
                          // setState({ remark: text });

                          var point = text.length > 0 ? parseInt(text) : 0;
                          point = Math.max(0, point);
                          point = Math.min(userPointsBalance, point);

                          setPointsToRedeem(point);
                        }}
                        onChangeText={(text) => {
                          // setState({ remark: text });

                          var point = text.length > 0 ? parseInt(text) : 0;
                          point = Math.max(0, point);
                          point = Math.min(userPointsBalance, point);

                          setPointsToRedeem(point);
                        }}
                        defaultValue={pointsToRedeem.toString()}
                        keyboardType={"number-pad"}
                      /> */}
                          </View>
                          :
                          <></>
                      }

                      {orderType === ORDER_TYPE.DELIVERY &&
                        <>
                          {true ?
                            <View style={{
                              width: "100%",
                              // height: 60, 
                              paddingVertical: 16,
                              backgroundColor: "#ddebe5",
                              justifyContent: "center",
                              paddingHorizontal: 28,
                              marginTop: 20,

                              shadowColor: '#000',
                              shadowOffset: {
                                width: 0,
                                height: 1,
                              },
                              shadowOpacity: 0.22,
                              shadowRadius: 2.22,
                              elevation: 1,
                            }}>
                              <Text style={{
                                color: Colors.primaryColor,
                                marginLeft: 4,
                                fontSize: 17,
                                fontFamily: 'NunitoSans-SemiBold',
                              }}>Deliver To</Text>
                            </View> : null}

                          {/* /////////////////// Courier type start //////////////////// */}

                          <View style={{
                            width: '80%',
                            alignSelf: 'center',
                            marginTop: 20,
                          }}>
                            <Text style={[styles.payment, {
                              // paddingHorizontal: 24,
                            }]}>Select your sender</Text>

                            <TouchableOpacity
                              disabled={senderList.length <= 1}
                              onPress={() => {
                                setShowSender(!showSender);
                              }}
                              style={[styles.textInput, {
                                // marginHorizontal: 24,
                              }]}>
                              <View style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                // backgroundColor: 'red',
                                height: '100%',
                              }}>
                                <View style={{
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}>
                                  <Image
                                    source={selectedSender ? selectedSender.img : ''}
                                    style={{
                                      marginRight: 10,
                                      width: 32,
                                      height: 32,
                                      borderRadius: 8,
                                      top: 1,
                                    }}
                                  />

                                  <Text style={{
                                    // paddingVertical: 14
                                    fontSize: 15,
                                    fontFamily: 'NunitoSans-Regular',
                                    color: Colors.fieldtTxtColor,
                                  }}>
                                    {/* {'Credit/Debit Card'} */}
                                    {selectedSender && selectedSender.label ? selectedSender.label : ''}
                                  </Text>
                                </View>

                                <View style={{
                                  // marginLeft: '40%',
                                  justifyContent: 'center',
                                  opacity: senderList.length > 1 ? 100 : 0,
                                }}>
                                  <Entypo
                                    name={showSender ? "chevron-thin-up" : "chevron-thin-down"}
                                    size={22}
                                    color={Colors.primaryColor}
                                  />
                                </View>
                              </View>
                            </TouchableOpacity>

                            <View style={{
                              display: showSender ? 'flex' : 'none',
                              marginTop: -20,
                              paddingTop: 10,
                            }}>
                              {
                                senderList.map((item, i) => {
                                  return (
                                    <TouchableOpacity style={{
                                      // marginHorizontal: 24,
                                      height: 50,
                                      paddingHorizontal: 20,
                                      backgroundColor: Colors.fieldtBgColor,
                                      borderRadius: 5,
                                      // marginTop: 10,
                                      // marginBottom: 10,  

                                      marginTop: -5,
                                    }} onPress={() => {
                                      setSelectedSender(item);

                                      setShowSender(false);
                                    }}>
                                      <View style={{
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        // backgroundColor: 'red',
                                        height: '100%',
                                      }}>
                                        <View style={{
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                        }}>
                                          <Image
                                            source={item.img}
                                            style={{
                                              marginRight: 10,
                                              width: 32,
                                              height: 32,
                                              borderRadius: 8,
                                              top: 1,
                                            }}
                                          />

                                          <Text style={{
                                            // paddingVertical: 14
                                            fontSize: 15,
                                            fontFamily: 'NunitoSans-Regular',
                                            color: Colors.fieldtTxtColor,
                                          }}>
                                            {item.label}
                                          </Text>
                                        </View>

                                        <View style={{
                                          // marginLeft: '40%',
                                          justifyContent: 'center',
                                          opacity: 0,
                                        }}>
                                          <Entypo
                                            name="chevron-thin-down"
                                            size={22}
                                            color={Colors.primaryColor}
                                          />
                                        </View>
                                      </View>
                                    </TouchableOpacity>
                                  );
                                })
                              }
                            </View>
                          </View>

                          {/* /////////////////// Courier type end //////////////////// */}

                          {true ? <View style={{
                            // height: 100,
                            justifyContent: "center",
                            // borderBottomWidth: StyleSheet.hairlineWidth,
                            paddingHorizontal: 16,
                            paddingVertical: 25,
                          }}>
                            <View style={{ flexDirection: "row", marginLeft: 10, display: 'flex', alignItems: 'center' }}>
                              <View style={{ width: "15%" }}>
                                <Ionicons name="location-sharp" size={34} color={"red"} />
                              </View>
                              <View style={{ width: "60%" }}>
                                <Text style={{
                                  fontSize: 14,
                                  color: Colors.mainTxtColor,
                                  fontFamily: 'NunitoSans-SemiBold',
                                }}>
                                  {/* {Cart.getDeliveryAddress() != null || Cart.getDeliveryAddress() != undefined ? Cart.getDeliveryAddress().address : "Select an address"} */}
                                  {selectedUserAddress && selectedUserAddress.uniqueId ? selectedUserAddress.address : 'Select an address'}
                                </Text>
                              </View>
                              <View style={{ width: "10%" }}></View>

                              <TouchableOpacity onPress={() => {
                                setRouteFrom(1);
                                props.navigation.navigate("Address", { testing: 1 });
                              }
                              } style={{
                                marginLeft: 15,
                              }}>
                                <Icons name="edit" size={24} color={Colors.primaryColor} />
                              </TouchableOpacity>
                            </View>
                          </View> : null}

                          <View style={{
                            height: 1.5,
                            left: '5%',
                            width: '90%',
                            backgroundColor: '#C2C1C0',
                            opacity: 0.2,
                            marginBottom: 4,

                            shadowColor: '#000',
                            shadowOffset: {
                              width: 0,
                              height: 1,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 2.22,
                            elevation: 3,
                          }}>
                          </View>

                          {true ? <View style={{
                            // height: 100,
                            justifyContent: "center",
                            paddingHorizontal: 16,
                            paddingVertical: 25,
                          }}>
                            <View style={{
                              flexDirection: "row",
                              marginLeft: 16,
                              alignItems: 'center',
                            }}>
                              <TouchableOpacity style={{
                                width: 22,
                                height: 22,
                                backgroundColor:
                                  Colors.whiteColor,
                                // marginTop: 10, 
                                borderRadius: 20, borderWidth: 1, justifyContent: "center", borderColor: Colors.primaryColor
                              }}
                                onPress={() => { }}>
                                <View style={{ width: '70%', height: '70%', backgroundColor: Colors.primaryColor, borderRadius: 20, alignSelf: "center" }}>

                                </View>
                              </TouchableOpacity>
                              <View style={{ width: "5%" }}></View>
                              <View style={{ width: "15%" }}>
                                {/* <MaterialIcons name="delivery-dining" size={40} color={Colors.primaryColor} /> */}
                                <Image
                                  source={require('../asset/image/delivery.png')}
                                  style={{ width: 34, height: 34, resizeMode: "contain" }}></Image>
                              </View>
                              <View style={{ width: "55%" }}>
                                <View>
                                  <Text style={{
                                    color: Colors.primaryColor,
                                    // fontWeight: '700', 
                                    fontFamily: 'NunitoSans-Bold',
                                    fontSize: 16,
                                  }}>Delivery</Text>
                                  <Text style={{
                                    color: "#9c9c9c",
                                    fontSize: 14,
                                    colors: Colors.descriptionColor,
                                  }}>{`Deliver now (${30 + (+(totalPrepareTime / 60).toFixed(0))}mins)`}</Text>
                                </View>
                              </View>
                              <View style={{
                                width: "20%",
                              }}>
                                {/* remove first */}
                                {/* <TouchableOpacity onPress={() => { setState({ showDateTimePicker: true }), generateScheduleTime() }}>
                          <Text style={{
                            color: Colors.primaryColor,
                            // fontWeight: "700",
                            // marginTop: 10
                            fontFamily: 'NunitoSans-Bold',
                            fontSize: 14,
                            textAlign: 'left',
                          }}>{rev_date === undefined ? "Schedule" : rev_date.split(',')[0] + ' ' + rev_date.split(',')[1]}</Text>
                        </TouchableOpacity> */}
                              </View>
                              {/* <DateTimePickerModal
                    minimumDate={new Date()}
                    selectMultiple={false}
                    isVisible={showDateTimePicker}
                    mode={pickerMode}
                    display={pickerMode == "time" ? "spinner" : "default"} //for iOS to use minuteInterval
                    maximumDate={new Date(Date.now() + (4320 * 60 * 1000))}
                    onConfirm={(text) => {
                      if (pickerMode == 'time') {
                        setState({ rev_time: new Date(text).getHours() + ":" + new Date(text).getMinutes() + ":00" })
                      } else {
                        var date_ob = new Date(text);
                        let date = ("0" + date_ob.getDate()).slice(-2);
                        let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
                        let year = date_ob.getFullYear();
                        setState({ rev_date: year + "-" + month + "-" + date, rev_time: new Date(text).getHours() + ":" + new Date(text).getMinutes() + ":00" })
                      }
                      setState({ showDateTimePicker: false })
                    }}
                    onCancel={() => {
                      setState({ showDateTimePicker: false })
                    }}
                  /> */}
                              <Modal
                                style={{ flex: 1 }}
                                visible={showDateTimePicker}
                                transparent={true}
                                animationType="slide">
                                <View
                                  style={{
                                    backgroundColor: 'rgba(0,0,0,0.5)',
                                    flex: 1,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}>
                                  <View style={styles.scheduleBox}>
                                    <TouchableOpacity
                                      onPress={() => {
                                        setState({ showDateTimePicker: false });
                                      }}>
                                      <View
                                        style={{
                                          alignSelf: 'flex-end',
                                          padding: 16,
                                        }}>
                                        <Close name="closecircle" size={28} />
                                      </View>
                                    </TouchableOpacity>
                                    <View>
                                      <Text
                                        style={{
                                          //textAlign: "center",
                                          //fontWeight: "bold",
                                          fontSize: 11,
                                          marginBottom: 5,
                                          marginLeft: '20%',
                                          color: Colors.fieldtTxtColor
                                        }}
                                      >
                                        Select delivery time
                                      </Text>
                                      <Text
                                        style={{
                                          //textAlign: "center",
                                          fontWeight: "bold",
                                          fontSize: 16,
                                          marginBottom: 5,
                                          marginLeft: '20%',
                                          color: Colors.primaryColor
                                        }}
                                      >
                                        {schedulteTimeSelected.split(',')[0]} {schedulteTimeSelected == 'TODAY,ASAP' ? ' ' : ', '} {schedulteTimeSelected.split(',')[1]}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "100%",
                                        alignContent: "center",
                                        marginBottom: "6%",
                                        height: "50%",
                                      }}
                                    >
                                      <View
                                        style={{
                                          alignItems: "center",
                                          marginBottom: "2%",
                                          marginTop: "35%"
                                        }}
                                      >
                                        {/* <SpinPicker
                                    data={schedulteTimeList}
                                    value={schedulteTimeList[0]}
                                    onValueChange={selectedItem => setState({ schedulteTimeSelected: selectedItem })}
                                    keyExtractor={number => number.toString()}
                                    //showArrows
                                    //onInputValueChanged={console.log("hit")}
                                    renderItem={renderSchedule} /> */}
                                      </View>
                                      <View
                                        style={{
                                          alignItems: "center",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <TouchableOpacity
                                          onPress={() => {
                                            console.log("schedulteTimeSelected", schedulteTimeSelected)
                                            setState(
                                              { rev_date: schedulteTimeSelected, showDateTimePicker: false },
                                              () => {
                                                console.log("rev_date: schedulteTimeSelected", rev_date)
                                                var splitDate = rev_date.split(',')
                                                var year = splitDate[2]
                                                var time = splitDate[1]
                                                var splitDay = splitDate[0].split(' ')
                                                var month = splitDay[2]
                                                var date = splitDay[1]
                                                const UTCRevDate = new Date(month + ' ' + date + ' ' + year + ' ' + time + ' ' + splitDate[3])
                                                console.log("UTCRevDate", UTCRevDate)
                                                setState({ UTCRevDate: UTCRevDate })
                                              })

                                          }}
                                          style={{
                                            backgroundColor: Colors.primaryColor,
                                            width: "80%",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            alignContent: "center",
                                            borderRadius: 10,
                                            height: "70%",
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 24,
                                              color: Colors.whiteColor,
                                            }}
                                          >
                                            SCHEDULE
                                          </Text>
                                        </TouchableOpacity>

                                      </View>
                                    </View>
                                  </View>
                                </View>
                              </Modal>
                            </View>
                          </View> : null}
                        </>
                      }

                      {/* <View style={{ height: 20 }}></View> */}
                    </ScrollView>

                    <TouchableOpacity
                      disabled={isLoading}
                      style={{
                        backgroundColor: 'white',
                        shadowColor: '#000',
                        shadowOffset: {
                          width: 0,
                          height: 1,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 2.22,
                        elevation: 9,

                        // bottom: -10,
                        bottom: 45,
                      }}
                      onPress={() => {
                        // if (test1 == 1) {
                        //   extend(Cart.getParkingId(), cartItem[0].quantity)
                        // }
                        // else {
                        //   if (type == 1 && (Cart.getDeliveryAddress() == null || Cart.getDeliveryAddress() == undefined)) {
                        //     Alert.alert(
                        //       'Error',
                        //       'Delivery address not selected.',
                        //       [{ text: 'OK', onPress: () => { } }],
                        //       { cancelable: false },
                        //     );
                        //   } else if (Cart.getOrderType() != 0) {
                        //     var amount = (
                        //       parseFloat(totalFloat) +
                        //       parseFloat(taxFloat)
                        //     ).toFixed(2)
                        //     var amount = 5.00 //sandbox only allow maximum RM5
                        //     // change to uuidv4 
                        //     var orderId = User.getUserId() + ':T' + Date.now().toString()
                        //     console.log("ORDER ID", orderId)
                        //     //remember to change to this in production
                        //     //molPayment(amount, 'orderId')
                        //     molPayment(5, orderId)
                        //     //placeOrder();
                        //   }
                        //   else {
                        //     placeOrder();
                        //   }
                        // }

                        var isValidProceed = false;

                        if (orderType === ORDER_TYPE.DELIVERY) {
                          if (deliveryQuotation && deliveryQuotation.courierCode) {
                            isValidProceed = true;
                          }
                          else {
                            Alert.alert(
                              'Info',
                              'Sorry, we unable to deliver to this address, please try another one.',
                            );
                          }
                        }
                        else {
                          isValidProceed = true;
                        }

                        if (isValidProceed) {
                          if (orderType === ORDER_TYPE.DINEIN) {
                            if (selectedPaymentOptions.value === USER_ORDER_PAYMENT_OPTIONS.ONLINE) {
                              // startMolPay();
                              setShowMpsChannelModal(true);
                            }
                            else if (selectedPaymentOptions.value === USER_ORDER_PAYMENT_OPTIONS.OFFLINE) {
                              CommonStore.update(s => {
                                s.isLoading = true;
                              });

                              placeUserOrder(null);
                            }
                            else {
                              Alert.alert('Error', 'Invalid payment options');
                            }
                          }
                          else if (orderType === ORDER_TYPE.PICKUP) {
                            setShowUserInfoModal(true);
                          }
                        }
                      }}>
                      <View
                        style={{
                          backgroundColor: Colors.primaryColor,
                          padding: 20,
                          paddingVertical: 16,
                          borderRadius: 10,
                          alignItems: 'center',

                          marginHorizontal: 48,
                          marginTop: 20,
                          marginBottom: 24,

                          shadowColor: '#000',
                          shadowOffset: {
                            width: 0,
                            height: 1,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 2.22,
                          elevation: 3,

                          ...!isMobile() && {
                            width: '94%',
                          },
                        }}>
                        <Text style={{
                          color: '#ffffff',
                          fontSize: 21,
                          fontFamily: 'NunitoSans-Regular',
                        }}>PLACE ORDER</Text>
                      </View>
                    </TouchableOpacity>
                  </View>
                )
                :
                <View style={{
                  // backgroundColor: 'red',
                  width: isMobile() ? '100%' : Dimensions.get('window').width,
                  height: isMobile() ? '100%' : Dimensions.get('window').height,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <View style={{
                    marginBottom: '15%',
                  }}>
                    <ActivityIndicator color={Colors.primaryColor} size={"large"} />

                    <Text
                      style={{
                        color: Colors.descriptionColor,
                        textAlign: "center",
                        marginTop: 20,
                        fontFamily: 'NunitoSans-Bold',
                        fontSize: 16,
                      }}
                    >
                      Processing your order now...
                    </Text>
                  </View>
                </View>
            }
          </>
      }
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
    backgroundColor: "#ffffff",
    padding: 16,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
  headerLogo: {
    width: 112,
    height: 25,
  },
  description: {
    paddingVertical: 5,
    fontSize: 13,
    color: Colors.descriptionColor,
    fontFamily: "NunitoSans-Regular",
    // fontWeight: '400',
    marginBottom: 2,
  },
  smallDescription: {
    marginTop: -5,
    paddingVertical: 5,
    fontSize: 10,
    color: "#9c9c9c",
    fontFamily: "NunitoSans-Regular", fontWeight: '400',
    width: '90%',
  },
  payment: {
    // color: Colors.descriptionColor,
    color: Colors.mainTxtColor,
    paddingVertical: 5,
    fontSize: 14,
    // marginTop: 20,
    fontFamily: 'NunitoSans-SemiBold',
    marginBottom: -5,
  },
  total: {
    // paddingVertical: 5,
    fontSize: 16,
    // fontWeight: "700",
    // marginTop: 5,
    fontFamily: "NunitoSans-Bold"
  },
  price: {
    paddingVertical: 5,
    fontSize: 13,
    alignSelf: "flex-end",
    fontFamily: "NunitoSans-Regular",
    color: Colors.descriptionColor,
    // fontWeight: "400",
    marginBottom: 2,
  },
  textInput: {
    height: 50,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    marginTop: 10,
    marginBottom: 10,

    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 2,
  },
  textInput1: {
    height: 50,
    paddingHorizontal: 20,
    backgroundColor: '#E9CE8B',
    borderRadius: 4,
    marginTop: 20,
    justifyContent: "center",

    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 2,
  },
  totalPrice: {
    color: Colors.primaryColor,
    // paddingVertical: 5,
    fontSize: 16,
    // fontWeight: "700",
    fontFamily: 'NunitoSans-Bold',
    // marginTop: 5,
  },
  confirmBox: {
    width: "60%",
    height: "33%",
    borderRadius: 30,
    backgroundColor: Colors.whiteColor,
  },
  scheduleBox: {
    width: "80%",
    height: "65%",
    borderRadius: 30,
    backgroundColor: Colors.whiteColor,
  },
  textFieldInput: {
    height: "100%",
    width: "100%",
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
  },
  pic: {
    backgroundColor: Colors.secondaryColor,
    width: 92, // 90
    height: 92, // 90
    borderRadius: 10,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  card: {
    flex: 1,
    minWidth: Styles.width - 32,
    minHeight: 50,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 12,
    paddingHorizontal: Platform.OS == 'ios' ? 0 : 20,
    paddingVertical: 15,
    flexDirection: 'row',
    marginBottom: 20,
    alignItems: "center"
  },
  text: {
    fontSize: 15,
    fontFamily: "NunitoSans-Bold",
  },
  text1: {
    fontSize: 13,
    fontFamily: "NunitoSans-Bold",
    marginTop: '2%',
  },
  text2: {
    fontSize: 15,
    fontWeight: '700',
  },
  title: {
    color: Colors.blackColor,
    fontSize: 20,
    fontFamily: "NunitoSans-Bold"
  },

  button: {
    backgroundColor: Colors.fieldtBgColor,
    padding: 10,
    borderRadius: 10,
    marginVertical: 20, borderWidth: 1,
    borderColor: Colors.fieldtTxtColor,
    marginBottom: 0
  },

  checkBox: {
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: Colors.descriptionColor,
    width: 26,
    height: 26,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },

  modalContainer: {
    flex: 1,
    backgroundColor: Colors.modalBgColor,
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalView: {
    height: Dimensions.get('window').width * 1,
    width: Dimensions.get('window').width * 0.8,
    backgroundColor: Colors.whiteColor,
    borderRadius: Dimensions.get('window').width * 0.07,
    padding: Dimensions.get('window').width * 0.07,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  closeButton: {
    position: 'absolute',
    right: isMobile() ? Dimensions.get('window').width * 0.04 : Dimensions.get('window').width * 0.01,
    top: isMobile() ? Dimensions.get('window').width * 0.04 : Dimensions.get('window').width * 0.01,
  },
  modalTitle: {
    alignItems: 'center',
  },
  modalBody: {
    flex: 0.8,
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalTitleText: {
    fontFamily: 'NunitoSans-Bold',
    // marginBottom: 10,
    fontSize: 20,
  },
  modalDescText: {
    fontFamily: 'NunitoSans-SemiBold',
    fontSize: 16,
    color: Colors.fieldtTxtColor
  },
  modalBodyText: {
    // flex: 1,
    fontFamily: 'NunitoSans-SemiBold',
    fontSize: 16,
    width: "20%"

  },
  modalSaveButton: {

    width: isMobile() ? Dimensions.get('window').width * 0.3 : Dimensions.get('window').width * 0.1,
    backgroundColor: Colors.fieldtBgColor,
    height: 45,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,

    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,
  },
});
export default CartScreen;
